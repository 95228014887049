import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useForm } from "@kaydhiman/react-hook-useform";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";

import InputBox from "../../../../Utils/forms/InputBox";
import CustomButton from "../../../../Utils/forms/CustomButton";
import { AppContext } from "../../../../context/AppContext";
import { http } from "../../../../../services/http";
import BreadCrumb from "../../../../../form-widgets/BreadCrumb";

function AddSubject() {
  const [schoolList, setSchoolList] = useState([]);
  const { setSpinner } = useContext(AppContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id, subId } = useParams();

  const { values, errors, bindField, isValid, setInitialValues } = useForm({
    validations: {
      name: {
        required: true,
      },
    },
  });

  //function to add or update Sbuject

  const addSubject = (e) => {
    e.preventDefault();
    setSpinner(true);
    http(
      {
        method: subId ? "PUT" : "POST",
        url: `class/${id}/subject/${subId ? subId : " "}`,
        isSecure: true,
        body: {
          ...values,
        },
      },
      (res) => {
        setSpinner(false);
        // navigate({pathname:`/admin/classes/${id}`, state:BreadCrumb})
        navigate(-1);
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  //get subject listing

  //api for get single feee details

  const getSingleSubject = useCallback(() => {
    http(
      {
        method: "GET",
        url: `class/${id}/subject/${subId}`,
        isSecure: true,
      },
      (res) => {
        setInitialValues({ ...res.data, name: res.data.name });
      }
    );
    // eslint-disable-next-line
  }, [setInitialValues, id]);



  // school lisiting
  useEffect(() => {
    http(
      {
        method: "GET",
        url: "school",
        isSecure: true,
      },
      (res) => {
        setSchoolList(res.data);
      }
    );
  }, []);



  // const sortTeacherData = () => {
  //   setSchoolList((prevData) => ({
  //     ...prevData,
  //     data: prevData.data?.sort((item1, item2) => {
  //       if (item1.teacher.user.name < item2.teacher.user.name) {
  //         return isAscending ? -1 : 1;
  //       }
  //       if (item1.teacher.user.name > item2.teacher.user.name) {
  //         return isAscending ? 1 : -1;
  //       }
  //       // names must be equal
  //       return 0;
  //     }),
  //   }));
  //   setIsAscending((prev) => !prev);
  // };


  useEffect(() => {
    subId &&  getSingleSubject();
    // sortTeacherData();
  }, [getSingleSubject,subId]);

  return (
    <div className=" user_details ass-sub px-0">
      <div className="sup_history update_user">
        <div className="scale_data_header">
          <BreadCrumb
            links={[
              {
                label: `${
                  pathname.includes("/edit") ? "Edit Subject" : "Add Subject"
                }`,
              },
            ]}
          />
        </div>
        <div className="data_add_form admin-form">
          <form onSubmit={addSubject}>
            <div className="row">
              <div className="mb-4 col-lg-3">
                <InputBox
                  label="Subject Name"
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter subject name"
                  classname="form-control"
                  {...bindField("name")}
                  error={errors.name}
                />
              </div>
              <div className="mb-4 col-lg-3 col-xl-3 ">
                <label htmlFor="sName" className="form-label">
                  Assign to School
                </label>
                {/* school dropdown section start */}

                <div className="dropdown-box">
                  <DropdownButton
                    className={`dropdown-btn`}
                    title={
                      schoolList?.filter(
                        (item) => item.id === values.school_id
                      )[0]?.name || <span>Select School</span>
                    }
                  >
                    {schoolList?.map((elem) => {
                      return (
                        <div
                          key={elem?.id}
                          className="dropdown_projects"
                          onClick={() =>
                            setInitialValues((prevvalues) => ({
                              ...prevvalues,
                              school_id: elem.id,
                              school_name:elem.name
                            }))
                          }
                        >
                          <Dropdown.Item
                            className="dropdown-status"
                            title={elem?.name}
                          >
                            <span className={
                                    elem?.name === values?.school_name ?
                                    "class_active" : ""
                                  }>
                              {elem?.name}
                            </span>
                          </Dropdown.Item>
                        </div>
                      );
                    })}
                  </DropdownButton>
                </div>
                {/* school dropdown section end */}
              </div>
            </div>

            <div className="addon_btn row">
              <div className="col-md-3">
                <CustomButton
                  type="submit"
                  text="Submit"
                  classname="sub-btn"
                  disabled={!isValid()}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default AddSubject;
