import React, { useState, useEffect, useContext } from "react";

import { Link } from "react-router-dom";

import { useForm } from "@kaydhiman/react-hook-useform";
import { DropdownButton, Dropdown } from "react-bootstrap";

import { Modal, Button } from "react-bootstrap";

import binImg from "../../../../assets/images/trash.png";
import penImg from "../../../../assets/images/edit_action.png";
import { AppContext } from "../../../context/AppContext";

import { http } from "../../../../services/http";
import { toast } from "react-toastify";
import PagePagination from "../../../Utils/pagination/PagePagination";

function GeneralExam() {
  const [generalExamList, setGeneralExamList] = useState();
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    id: "",
  });
  const [page, setPage] = useState();
  const [selectClass, setSelectClass] = useState({});

  const [schoolList, setSchoolList] = useState({});
  const { setSpinner, size } = useContext(AppContext);

  const { setInitialValues, values } = useForm({
    validations: {},
    initialValues: { class_data: [], sub_data: [], exam_data: [] },
  });

  const handleGeneralExamList = () => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: size,
      class_id: values.classId ? values.classId : "",
      school_id: values.schoolId ? values.schoolId : "",
    };
    http(
      {
        method: "GET",
        url: `generalExam?${new URLSearchParams(params)}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setGeneralExamList(res.data);
      }
    );
  };
  // useCallback(
  //   ({ page, perPage }) => {
  //     setSpinner(true);
  //     http(
  //       {
  //         method: "GET",
  //         url: `generalExam?page=${page ? page : ""}&size=${
  //           perPage ? perPage : 10
  //         }&class_id=${values.classId ? values.classId : ""}&subject_id=${
  //           values.subjectId ? values.subjectId : ""
  //         }`,
  //         isSecure: true,
  //       },
  //       (res) => {
  //         setSpinner(false);
  //         setGeneralExamList(res.data);
  //       }
  //     );
  //   },
  //   [values.classId, values.subjectId, setSpinner]
  // );

  // Subject dropdaown handle change function start
  // const subject_dataPush = (e) => {
  //   const { checked, value } = e.target;
  //   if (checked) {
  //     setInitialValues((prevState) => {
  //       return {
  //         ...prevState,
  //         sub_data: [...prevState.sub_data, value],
  //       };
  //     });
  //   } else {
  //     setInitialValues((prevState) => {
  //       return {
  //         ...prevState,
  //         sub_data: prevState.sub_data.filter((item) => item !== value),
  //       };
  //     });
  //   }
  // };
  // Subject dropdaown handle change function end

  //  Exam catagory dropdown section start
  // const exam_dataPush = (e) => {
  //   const { checked, value } = e.target;
  //   if (checked) {
  //     setInitialValues((prevState) => {
  //       return {
  //         ...prevState,
  //         exam_data: [...prevState.exam_data, value],
  //       };
  //     });
  //   } else {
  //     setInitialValues((prevState) => {
  //       return {
  //         ...prevState,
  //         exam_data: prevState.exam_data.filter((item) => item !== value),
  //       };
  //     });
  //   }
  // };
  //  Exam catagory dropdown section end

  // School list item delete function start
  const generalExamDelete = (id) => {
    http(
      {
        method: "DELETE",
        url: `generalExam/${id}`,
        isSecure: true,
      },
      (res) => {
        setGeneralExamList((prevData) => {
          return {
            ...prevData,
            data: prevData.data.filter((items) => items.id !== id),
          };
        });
        setIsModalVisible({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  // School list item dalete function end

  useEffect(() => {
    handleGeneralExamList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, values.classId, values.schoolId]);

  useEffect(() => {
    http(
      {
        method: "GET",
        url: `class`,
        isSecure: true,
      },
      (res) => {
        setSelectClass(res);
      }
    );
  }, []);

  useEffect(() => {
    http(
      {
        method: "GET",
        url: "school",
        isSecure: true,
      },
      (res) => {
        setSchoolList(res);
      }
    );
  }, [setSchoolList]);

  return (
    <>
      <div className="exams_dash gen-exams px-0">
        <div className="sup_history">
          <div className="scale_data_header">
            <div className="scale_data_heading">
              <h2 className="class_head">General Exams</h2>
              <div className="btns_header">
                <ul>
                  <li>
                    {/* class dropdown section start */}
                    <div className="dropdown-box">
                      <DropdownButton
                        className={`dropdown-btn`}
                        title={
                          values?.classNm ? (
                            values?.classNm
                          ) : (
                            <span>Select class</span>
                          )
                        }
                      >
                        {selectClass?.data?.map((elem) => {
                          return (
                            <div
                              key={elem?.id}
                              className="dropdown_projects"
                              onClick={() =>
                                setInitialValues({
                                  ...values,
                                  classId: elem.id,
                                  classNm: elem?.name,
                                  schoolNm: "",
                                  schoolId: "",
                                })
                              }
                            >
                              <Dropdown.Item
                                className="dropdown-status"
                                title={elem?.name}
                              >
                                <span
                                  className={
                                    elem?.name === values?.classNm
                                      ? "class_active"
                                      : ""
                                  }
                                  // onClick={() => setInitialValues({ priority: elem.h_class })}
                                >
                                  {elem?.name}
                                </span>
                              </Dropdown.Item>
                            </div>
                          );
                        })}
                      </DropdownButton>
                    </div>
                    {/* class dropdown section end */}
                  </li>
                  <li>
                    {/* subject dropdown section start */}
                    <div className="dropdown-box">
                      <DropdownButton
                        className={`dropdown-btn`}
                        title={
                          values?.schoolNm ? (
                            values?.schoolNm
                          ) : (
                            <span>Select school</span>
                          )
                        }
                        disabled={!values.classId}
                      >
                        {schoolList?.data?.map((elem) => {
                          return (
                            <div
                              key={elem?.id}
                              className="dropdown_projects"
                              onClick={() =>
                                setInitialValues({
                                  ...values,
                                  schoolNm: elem?.name,
                                  schoolId: elem.id,
                                })
                              }
                            >
                              <Dropdown.Item
                                className="dropdown-status"
                                title={elem?.name}
                              >
                                <span
                                  className={
                                    elem?.name === values?.schoolNm
                                      ? "class_active"
                                      : ""
                                  }
                                  // onClick={() => setInitialValues({ priority: elem.h_class })}
                                >
                                  {elem?.name}
                                </span>
                              </Dropdown.Item>
                            </div>
                          );
                        })}
                      </DropdownButton>
                    </div>
                    {/* subject dropdown section end */}
                  </li>
                  <li></li>
                </ul>
              </div>
            </div>
            <div className="search-box sing-btn">
              <div className="data_add">
                <Link to="add" className="btn sub-btn btn-primary">
                  Add Exam
                </Link>
              </div>
            </div>
          </div>
          <div className="kina_tables table-responsive">
            <table className="dash_tables">
              <thead>
                <tr>
                  <th className="SnumData">S.No.</th>
                  <th>Exam Name</th>

                  <th>Questions </th>
                  <th className="pricingTh">Action</th>
                </tr>
              </thead>
              <tbody>
                {generalExamList?.data?.length ? (
                  generalExamList?.data?.map((items, index) => {
                    return (
                      <tr key={index}>
                        <td>{generalExamList.from + index}</td>
                        <td>
                          <Link to={`general-exams-details/${items.id}`}>
                            {items?.name}
                          </Link>
                        </td>

                        <td>
                          <ul>
                            <li>
                              Questions (
                              {items.questions_count
                                ? items.questions_count
                                : ""}
                              )
                            </li>
                          </ul>
                        </td>
                        <td>
                          <ul>
                            <li>
                              <Link to={`exam/edit/${items.id}`}>
                                <img src={penImg} alt="PDF Icon" />
                              </Link>
                            </li>
                            <li>
                              <img
                                src={binImg}
                                alt="Show Icon "
                                onClick={() =>
                                  setIsModalVisible({
                                    isShow: true,
                                    id: items.id,
                                  })
                                }
                              />
                            </li>
                          </ul>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="8" className="error01">
                      Data Not Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Delete confirm modal section start */}
      <Modal
        show={isModalVisible.isShow}
        onHide={() => setIsModalVisible(false)}
        className="delete-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Do you want to delete?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setIsModalVisible({ isShow: false })}
          >
            No
          </Button>
          <Button
            variant="primary"
            onClick={() => generalExamDelete(isModalVisible.id)}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Delete confirm modal section end */}
      {generalExamList?.total > 0 &&
        generalExamList?.total > generalExamList?.per_page && (
          <PagePagination
            className="pagination-bar"
            currentPage={generalExamList?.current_page}
            totalCount={
              generalExamList?.last_page !== undefined
                ? generalExamList?.total
                : generalExamList?.length
            }
            pageSize={size}
            onPageChange={(page) => setPage(page)}
          />
        )}
    </>
  );
}
export default GeneralExam;
