import React, { useContext, useState, useEffect } from "react";

import { Link } from "react-router-dom";
import ToggleBtn from "../../../Utils/forms/ToggleBtn";
import { useForm } from "@kaydhiman/react-hook-useform";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { AppContext } from "../../../context/AppContext";
import PagePagination from "../../../Utils/pagination/PagePagination";
import { http } from "../../../../services/http";
function Subscription() {
  const [getSubscriptionList, setSubscriptionList] = useState({});
  const [getSchoolList, setSchoolList] = useState({});
  const [getClassList, setClassList] = useState({});
  const [pointer, setPointer] = useState("none");
  const [page, setPage] = useState();
  const { setInitialValues, values } = useForm({
    validations: {},
    // initialValues: { class_data: [] },
  });
  const { setSpinner, size } = useContext(AppContext);
  const handleSubscriptionList = () => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: size,
      class_id: values.classId ? values.classId : "",
      school_id: values.schoolId ? values.schoolId : "",
    };
    http(
      {
        method: "GET",
        url: `subscription?${new URLSearchParams(params)}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setSubscriptionList(res.data);
      }
    );
  };
  useEffect(() => {
    handleSubscriptionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, values.classId, values.schoolId]);
  useEffect(() => {
    http(
      {
        method: "GET",
        url: `school`,
        isSecure: true,
      },
      (res) => {
        setSchoolList(res);
      }
    );
  }, []);
  useEffect(() => {
    http(
      {
        method: "GET",
        url: `class`,
        isSecure: true,
      },
      (res) => {
        setClassList(res);
      }
    );
  }, []);
  return (
    <div className="subscription_dash ">
      <div className="sup_history">
        <div className="scale_data_header">
          <h2 className="class_head">Subscriptions</h2>
          <div className="btns_header">
            {/* school dropdown section start */}
            <div className="dropdown-box">
              <DropdownButton
                className={`dropdown-btn`}
                title={
                  values?.school_data ? (
                    values?.school_data
                  ) : (
                    <span>Select School</span>
                  )
                }
              >
                <div
                  className="dropdown_projects"
                  onClick={() => {
                    setInitialValues((prev) => ({
                      ...prev,
                      school_data: "All",
                      schoolId: "",
                      class_data: "",
                      classId: "",
                    }));
                    setPointer("auto");
                    setPage(1);
                  }}
                >
                  <Dropdown.Item title="All">
                    <span
                      className={
                        values?.school_data === "All" ? "class_active" : ""
                      }
                    >
                      All
                    </span>
                  </Dropdown.Item>
                </div>
                {getSchoolList?.data?.map((elem) => {
                  return (
                    <div
                      key={elem?.id}
                      className="dropdown_projects"
                      onClick={() => {
                        setInitialValues((prev) => ({
                          ...prev,
                          school_data: elem.name,
                          schoolId: elem.id,
                          class_data: "",
                          classId: "",
                        }));
                        setPointer("auto");
                        setPage(1);
                      }}
                    >
                      <Dropdown.Item
                        className="dropdown-status"
                        title={elem?.school}
                      >
                        <span
                          className={
                            elem?.name === values?.school_data
                              ? "class_active"
                              : ""
                          }
                          // onClick={() => setInitialValues({ school_data: elem.school })}
                        >
                          {elem?.name}
                        </span>
                      </Dropdown.Item>
                    </div>
                  );
                })}
              </DropdownButton>
            </div>
            {/* school dropdown section end */}

            {/* class dropdown section start */}
            <div className="dropdown-box" style={{ pointerEvents: pointer }}>
              <DropdownButton
                className={`dropdown-btn`}
                title={
                  values?.class_data ? (
                    values?.class_data
                  ) : (
                    <span>Select Class</span>
                  )
                }
              >
                {getClassList?.data?.map((elem) => {
                  return (
                    <div
                      key={elem?.id}
                      className="dropdown_projects"
                      onClick={() => {
                        setInitialValues((prev) => ({
                          ...prev,
                          class_data: elem.name,
                          classId: elem.id,
                        }));
                      }}
                    >
                      <Dropdown.Item
                        className="dropdown-status"
                        title={elem?.class}
                      >
                        <span
                          className={
                            elem?.name === values?.class_data
                              ? "class_active"
                              : ""
                          }
                        >
                          {elem?.name}
                        </span>
                      </Dropdown.Item>
                    </div>
                  );
                })}
              </DropdownButton>
            </div>
            {/* class dropdown section end */}
          </div>
        </div>
        <div className="kina_tables table-responsive">
          <table className="dash_tables">
            <thead>
              <tr>
                <th className="SnumData">S.No.</th>
                <th>Students Name</th>
                <th>Amount</th>
                <th>Dates</th>
                <th className="pricingTh">Active / Inactive</th>
              </tr>
            </thead>
            <tbody>
              {getSubscriptionList?.data?.length ? (
                getSubscriptionList?.data?.map((item, index) => {
                  const date = new Date(item.subscription.payment.created_at);
                  const getDate = date.getDate();
                  const getMonth = date.getMonth();
                  const getYear = date.getFullYear();
                  const monthNames = [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sept",
                    "Oct",
                    "Nov",
                    "Dec",
                  ];
                  const myDateString =
                    ("0" + getDate).slice(-2) +
                    " " +
                    monthNames[getMonth] +
                    " " +
                    getYear;
                  // const { sno, student_name, payment_date, price } =
                  //   historyData;
                  return (
                    <tr>
                      <td key={item.id}>{getSubscriptionList.from + index}</td>
                      <td>
                        <Link to="substud-details" state={item}>
                          {item.name}
                        </Link>
                      </td>
                      <td>${item.subscription.payment.amount}</td>
                      <td>{myDateString}</td>
                      <td>
                        <ToggleBtn
                          checked={item.subscription.payment.status === "paid"}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="8" className="error01">
                    Data Not Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {getSubscriptionList?.total > 0 &&
        getSubscriptionList?.total > getSubscriptionList?.per_page && (
          <PagePagination
            className="pagination-bar"
            currentPage={getSubscriptionList?.current_page}
            totalCount={
              getSubscriptionList?.last_page !== undefined
                ? getSubscriptionList?.total
                : getSubscriptionList?.length
            }
            pageSize={size}
            onPageChange={(page) => setPage(page)}
          />
        )}
    </div>
  );
}
export default Subscription;
