import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import UserNSchoolDetails from "../../../Utils/widgets/UserNSchoolDetails";
import { http } from "../../../../services/http";
import { AppContext } from "../../../context/AppContext";
import PrefImg from "../../../../assets/images/prev_ico.png";
import PagePagination from "../../../Utils/pagination/PagePagination";
import moment from "moment/moment";

function StudentDetails() {
  const [page, setPage] = useState();
  const [stdDetail, setStdDetail] = useState();
  const [parchasedBookList, setParchasedBookList] = useState();
  const stdParams = useParams();
  const { setSpinner, size } = useContext(AppContext);
  const handleSalesHistoryList = () => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: size,
      user_id: stdParams.stdId,
    };
    http(
      {
        method: "GET",
        url: `book/sales-history?${new URLSearchParams(params)}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setParchasedBookList(res.data);
      }
    );
  };
  useEffect(() => {
    handleSalesHistoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  useEffect(() => {
    http(
      {
        method: "GET",
        url: `student/${stdParams.stdId}`,
        isSecure: true,
      },
      (res) => {
        setStdDetail(res);
      }
    );
  }, [stdParams.stdId]);

  return (
    <div className="user_details stud_graph ">
      <div className="sup_history">
        <div className="scale_data_header">
          <h2 className="class_head">
            <Link to="/admin/students" className="prev_btn">
              <span className="backBtn">
                <img className="backPg" src={PrefImg} alt="Back button" />
              </span>
            </Link>
            Students Details
          </h2>
        </div>

        <UserNSchoolDetails
          img={process.env.REACT_APP_MEDIA_URL + stdDetail?.data?.file}
          name={stdDetail?.data?.user?.name}
          classname={stdDetail?.data?.class?.name}
          email={stdDetail?.data?.user?.email}
          phoneNo={stdDetail?.data?.user?.phone_number}
          rollNo={stdDetail?.data?.roll_number}
        />

        <div className="purcg_table">
          <div className="scale_data_header">
            <h2 className="class_head">Purchased Books</h2>
          </div>

          <div className="kina_tables table-responsive">
            <table className="dash_tables">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Book</th>
                  <th>Subject</th>
                  <th>Date and Time of Purchase</th>
                  <th className="pricingTh">Price</th>
                </tr>
              </thead>
              <tbody>
                {parchasedBookList?.data?.length ? (
                  parchasedBookList?.data?.map((item, index) => {
                    const subjectName = item.book.subject.map(
                      (item) => item.name
                    );
                    const DateOfPurchase = moment
                      .utc(item.created_at)
                      .format("DD MMM YYYY");
                    const TimeOfPurchase = moment
                      .utc(item.created_at)
                      .format("hh:mm A");
                    return (
                      <tr key={item.id}>
                        <td>{parchasedBookList.from + index}</td>
                        <td>{item.book.name}</td>
                        <td>{subjectName[0]}</td>
                        <td>{`${DateOfPurchase}, ${TimeOfPurchase}`}</td>
                        <td>${item.book.price}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5">
                      <h2 className="text-center">Data Not Found</h2>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {parchasedBookList?.total > 0 &&
            parchasedBookList?.total > parchasedBookList?.per_page && (
              <PagePagination
                className="pagination-bar"
                currentPage={parchasedBookList?.current_page}
                totalCount={
                  parchasedBookList?.last_page !== undefined
                    ? parchasedBookList?.total
                    : parchasedBookList?.length
                }
                pageSize={size}
                onPageChange={(page) => setPage(page)}
              />
            )}
        </div>

        <div className="mt-4">
          <div className="scale_data_header">
            <h2 className="class_head">Video History</h2>
          </div>
          {/* Digital Electronic section start */}
          <div className="main_slider_box">
            {/* <Slider
              data={books}
              type="video"
              options={{
                margin: 30,
                responsiveClass: true,
                nav: true,
                dots: false,
                autoplay: false,
                navText: ["", ""],
                smartSpeed: 1000,
                responsive: {
                  0: {
                    items: 1,
                  },
                  400: {
                    items: 1,
                  },
                  600: {
                    items: 2,
                  },
                  700: {
                    items: 3,
                  },
                  1000: {
                    items: 4,
                  },
                },
              }}
            /> */}
            <h3>No Data Available</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
export default StudentDetails;
