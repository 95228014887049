import SearchIcon from "../../../assets/images/search-bar.png";
import InputField from "./InputField";
function SearchForm({ onChange, value, placeholder }) {
  // const [Search, setSearch] = useState("");

  return (
    <div className="input-group flex-nowrap">
      <InputField
        id="searchBar"
        type="text"
        className="form-control searchBar"
        placeholder={placeholder ? placeholder : "Search..."}
        onChange={onChange}
        value={value}
        name="search"
      />
      <span className="input-group-text search-icon" id="addon-wrapping">
        <img src={SearchIcon} alt="SearchIcon" />
      </span>
    </div>
  );
}
export default SearchForm;
