import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useForm } from "@kaydhiman/react-hook-useform";
import { toast } from "react-toastify";

import BreadCrumb from "../../../../../form-widgets/BreadCrumb";
import { http } from "../../../../../services/http";
import { AppContext } from "../../../../context/AppContext";
import CustomButton from "../../../../Utils/forms/CustomButton";
import InputBox from "../../../../Utils/forms/InputBox";

import PDFicon from "../../../../../assets/images/pdf-file.png";
// import editIcon from "../../../../../assets/images/white-edit.svg";
import CrossIcon from "../../../../../assets/images/red_cross.svg";
import editIcon from "../../../../../assets/images/white-edit.svg";

function EditChapter() {
  const [doc, setDoc] = useState({ file: "", url: "" });
 

  const { id, subId, chapterId } = useParams();

  const { setSpinner } = useContext(AppContext);

  const { pathname, state } = useLocation();

  const navigate = useNavigate();
  //validation of form
  const { values, errors, bindField, isValid, setInitialValues } = useForm({
    validations: {
      name: {
        required: true,
      },
    },
  });

  // upload pdf handle section start
  const uploadPdfFile = (e) => {
    setDoc({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };
  // Upload pdf handle section end

  // IMAGE UPLOAD API
  const uploadImgApi = async (e) => {
    e.preventDefault();
    if (chapterId && typeof doc.file === "string") {
      addChapter(doc.file);
      return;
    } else {
      const formData = new FormData();
      formData.append("type", "chapter");
      formData.append("file", doc.file);
      await http(
        {
          method: "POST",
          url: `upload-image`,
          isSecure: true,
          contentType: "multipart/form-data",
          body: formData,
        },
        (res) => {
          addChapter(res.content.imageName);
          return;
        },
        (res) => {
          setSpinner(false);

          toast.error(res.message, {
            autoClose: 3000,
          });
          return;
        }
      );
    }
  };


  //function to add or update class

  const addChapter = (file) => {
    setSpinner(true);
    http(
      {
        method: chapterId ? "PUT" : "POST",
        url: `class/${id}/subject/${subId}/chapter/${
          chapterId ? chapterId : " "
        }`,
        isSecure: true,
        body: {
          name: values.name,
          files: [file],
        },
      },
      (res) => {
        setSpinner(false);
        navigate(state[state.length - 1].url, {state: state.splice(0,2)})
        // navigate(`/admin/classes/${id}/subject/${subId}/chapter`);
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        setSpinner(false);
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  

  //navigate to edit chapter

  const editNavigate = () => {
    navigate(`/admin/classes/${id}/subject/${subId}/chapter/edit/${chapterId}`, {
      state: state
    });
    
  };

  // Single Chapter Fetch
  const getSingleChapter = useCallback(() => {
    setSpinner(true);
    http(
      {
        method: "GET",
        url: `class/${id}/subject/${subId}/chapter/${chapterId}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        
        setInitialValues({
          name: res.data.name,
        });
        setDoc({ file: res.data.files[0].file, url: res.data.files[0].file });
      }
    );
    // eslint-disable-next-line
  }, [setInitialValues, id]);

  useEffect(() => {
    chapterId && getSingleChapter();
  }, [getSingleChapter, chapterId]);

  return (
    <div className=" user_details chap_details_dash px-0">
      <div className="sup_history update_user">
        <div className="scale_data_header align-items-center justify-content-between d-flex">
          <BreadCrumb
            links={[
              ...(chapterId ? (state || []) : ""),
              {
                label: `${
                  pathname.includes("/add") || pathname.includes("/edit")
                    ? `${chapterId ? "Edit" : "Add"} Chapter`
                    : "Chapter Details"
                }`,
                style: { color: "black" },
              },
            ]}
          />

          <span
          onClick={() => {
            navigate(`add`, {
              state: state.splice(0,3),
            });
          }}
           to={`add`} className="addTestBtn">
            Add Test
          </span>
        </div>

        <div className="data_add_form admin-form">
          <form onSubmit={uploadImgApi}>
            <div className="row">
              <div className="col-lg-3">
                <div className="UpdateIcon align-items-end">
                  <InputBox
                    type="text"
                    id="name"
                    defaultValue="Digital Electronics"
                    classname="form-control"
                    label="Chapter Name"
                    {...bindField("name")}
                    error={errors.name}
                    disabled={pathname.includes(`/chapter/${chapterId}`)}
                  />
                  {pathname.includes("/add") || pathname.includes("/edit") ? (
                    ""
                  ) : (
                    <span
                      className="active01 small-btn edit-btn form-group cursor"
                      onClick={editNavigate}
                    >
                      <img src={editIcon} alt="Edit" />
                    </span>
                  )}
                </div>
              </div>
              <div className="mb-4 ">
                <label htmlFor="file" className="form-label">
                  Upload file
                </label>
                <div className="item-content file_up">
                  <div className="pdf-upload">
                    <input
                      type="file"
                      className="btn sub-btn  upload_btn"
                      accept=".pdf"
                      onChange={uploadPdfFile}
                      disabled={pathname.includes(`/chapter/${chapterId}`)}
                    />

                    {doc.url ? (
                      <div className="file_icon">
                        <img
                          className="file"
                          src={PDFicon}
                          alt="Upload book as pdf"
                        />
                        {pathname.includes(`/chapter/${chapterId}`) ? (
                          ""
                        ) : (
                          <img
                            className="cut_pdf"
                            src={CrossIcon}
                            alt="Remove selected file"
                            onClick={() => setDoc({})}
                          />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="addon_btn row">
                <div className="col-xl-3 col-lg-6 ">
                  <CustomButton
                    text="Save"
                    type="submit"
                    classname="sub-btn"
                    disabled={
                      !isValid() ||
                      pathname.includes(`/chapter/${chapterId}`) ||
                      !doc.file
                    }
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default EditChapter;
