import React, { useEffect, useState, useContext, useRef } from "react";

import { Link, useNavigate } from "react-router-dom";

import { useForm } from "@kaydhiman/react-hook-useform";
import { http } from "../../../../../services/http";
import { AppContext } from "../../../../context/AppContext";
import { toast } from "react-toastify";

import PDFicon from "../../../../../assets/images/pdf-file.png";
import PrefImg from "../../../../../assets/images/prev_ico.png";
import CrossIcon from "../../../../../assets/images/red_cross.svg";
import UploadImg from "../../../../../assets/images/upload_image.png";
import TypeAheadContainer from "../../../../Utils/widgets/TypeAheadContainer";

function BooksForm() {
  const [classList, setClassList] = useState([]);
  const classesSelect = useRef(null);
  const [subjectList, setSubjectList] = useState([]);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const { setSpinner } = useContext(AppContext);
  const { setInitialValues, values, bindField, errors } = useForm({
    validations: {
      bookName: {},
      Price: {},
    },
    initialValues: { class_data: [], sub_data: [], exam_data: [] },
  });

  const [creatIamge, setCreateImage] = useState("");
  const [showPdf, setShowPdf] = useState("");

  const imageChange = (e) => {
    setCreateImage({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };
  // Image handle function end

  // pdf Upload handle section start
  const uploadPdfFile = (e) => {
    setShowPdf({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  // pdf Upload handle section end

  const handleChangeCheckbox = () => {
    setChecked(!checked);
  };

  const handleImgUpload = async (fileType) => {
    setSpinner(true);
    const formData = new FormData();
    formData.append("type", fileType);
    formData.append(
      "file",
      fileType === "photo" ? creatIamge.file : showPdf.file
    );

    let response;

    await http(
      {
        method: "POST",
        url: `upload-image`,
        isSecure: true,
        contentType: "multipart/form-data",
        body: formData,
      },
      (res) => {
        setSpinner(false);
        response = res;
      },
      (res) => {
        setSpinner(false);
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
    return response;
  };

  const handleUploadImg = async (e) => {
    e.preventDefault();
    if (!creatIamge.file || !showPdf.file) {
      toast.error("please enter all fields", {
        autoClose: 3000,
      });
      return;
    }
    const [image, pdf] = await Promise.all([
      handleImgUpload("photo"),
      handleImgUpload("book"),
    ]);

    handleAddBook(image.content?.imageName, pdf.content?.imageName);
  };

  const handleAddBook = (img, pdf) => {
    setSpinner(true);
    http(
      {
        method: "POST",
        url: `book`,
        isSecure: true,
        body: {
          ...(values.class?.length > 0
            ? { class: values.class.map((item) => item.id) }
            : {}),
          ...(values.subject?.length > 0
            ? { subject: values.subject.map((item) => item.id) }
            : {}),
          name: values.bookName,
          price: values.Price,
          is_paid: checked ? 1 : 0,
          cover_image: img,
          file: pdf,
        },
      },
      (res) => {
        setSpinner(false);
        toast.success(res.message, {
          autoClose: 3000,
        });
        navigate(-1);
      },
      (res) => {
        setSpinner(false);
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  useEffect(() => {
    http(
      {
        method: "GET",
        url: `class`,
        isSecure: true,
      },
      (res) => {
        setClassList(res.data);
      }
    );
  }, [setClassList]);

  useEffect(() => {
    values.class &&
      http(
        {
          method: "POST",
          url: `class/subjects?page=1&size=10`,
          isSecure: true,
          body: {
            class_id: values.class.map((item) => item.id),
          },
        },
        (res) => {
          setSubjectList(res.data?.data);
        }
      );
  }, [classList, values.class]);


  return (
    <div className="update_user">
      <div className="col-md-12">
        <div className="sup_history">
          <div className="scale_data_header">
            <h2 className="class_head">
              <Link to="/admin/books" className="prev_btn">
                <img src={PrefImg} alt="Back button" />
              </Link>
              Add Book
            </h2>
          </div>
          <div className="data_add_form admin-form">
            <form onSubmit={handleUploadImg}>
              <div className="row">
                <div className="col-lg-6 col-xxl-3">
                  <div className="form-group">
                    <label htmlFor="sName" className="form-label">
                      Class
                    </label>
                    <div className="dropdown-box">
                      <TypeAheadContainer
                        ref={classesSelect}
                        id="bookClass"
                        labelKey={(item) => item.name}
                        name="class"
                        placeholder="Select Classes"
                        options={classList}
                        selected={values.class || []}
                        onChange={(value) => {
                          setInitialValues((prev) => ({
                            ...prev,
                            class: value,
                          }));
                        }}
                        error={errors.class}
                      />
                    </div>
                  </div>
                  {/* class dropdown section end */}
                </div>
                <div className="col-lg-6 col-xxl-3">
                  <div className="form-group">
                    <label htmlFor="sName" className="form-label">
                      Subject
                    </label>
                    {/* subject dropdown section start */}

                    <div className="dropdown-box">
                      <TypeAheadContainer
                        id="subject"
                        labelKey={(item) => `${item?.name} (${item.class?.name}) (${item.school?.name})`}
                        name="subject"
                        placeholder="Select Subject"
                        options={subjectList || []}
                        selected={values.subject || []}
                        onChange={(value) => {
                          setInitialValues((prev) => ({
                            ...prev,
                            subject: value,
                          }));
                        }}
                        error={errors.subject}
                      />
                    </div>
                  </div>
                  {/* subject dropdown section end */}
                </div>
              </div>
              <div className="row">
                <div className="mb-4 col-lg-6 col-xxl-3">
                  <div className="form-group">
                    <label htmlFor="BookName" className="form-label">
                      Book Name
                    </label>
                    <input
                      type="text"
                      className="form-control text-input"
                      id="sName"
                      placeholder="Enter book name"
                      autoComplete="off"
                      name="bookName"
                      {...bindField("bookName")}
                    />
                  </div>
                </div>
                <div className="mb-4 col-xxl-3 col-lg-6">
                  <label htmlFor="price" className="form-label">
                    Price
                  </label>
                  <div className="price-data">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        onChange={handleChangeCheckbox}
                        value={checked}
                      />
                      <label
                        htmlFor="flexSwitchCheckChecked"
                        className="form-label text-nowrap"
                      >
                        Free / Paid
                      </label>
                    </div>
                    <div className="input-group pricing_input">
                      <span className="input-group-text input_ico">$</span>
                      <input
                        type="text"
                        className="form-control mb-0  text-input"
                        aria-label="Amount (to the nearest dollar)"
                        placeholder="0"
                        disabled={checked === false}
                        {...bindField("Price")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="mb-4 col-lg-6 col-xxl-3">
                  <label htmlFor="cover" className="form-label">
                    Upload Cover Photo
                  </label>
                  <div className="item-content">
                    <div className="image-upload">
                      <label htmlFor="file_upload" className="form-label">
                        <div className="dplay-tbl-cell">
                          {!creatIamge.url ? (
                            <img
                              src={UploadImg}
                              className="uploaded-image"
                              alt="Uplodyour"
                            />
                          ) : (
                            <img
                              src={creatIamge.url}
                              className="uploaded-image"
                              alt="Uplodyour"
                            />
                          )}
                        </div>

                        <input
                          type="file"
                          accept=".png,.jpg"
                          id="file_upload"
                          className="image-input  text-input"
                          onChange={(e) => imageChange(e)}
                          value=""
                        />
                      </label>
                      {creatIamge && (
                        <img
                          className="video_cross"
                          title="Delete this video"
                          src={CrossIcon}
                          onClick={() => setCreateImage("")}
                          alt="CrossIcon"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-4 col-lg-6 col-xxl-3">
                  <label htmlFor="file" className="form-label">
                    Upload file
                  </label>
                  <div className="item-content file_up">
                    <div className="pdf-upload">
                      <input
                        type="file"
                        className="btn sub-btn  upload_btn"
                        accept=".pdf"
                        onChange={(e) => uploadPdfFile(e)}
                        onClick={(e) => (e.target.value = "")}
                      />
                      {showPdf.url ? (
                        <div className="file_icon">
                          <img
                            className="file"
                            src={PDFicon}
                            alt="Upload book as pdf"
                          />

                          <img
                            className="cut_pdf"
                            src={CrossIcon}
                            alt="Remove selected file"
                            onClick={() => setShowPdf({})}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="addon_btn row">
                <div className="col-xl-3 col-lg-6 ">
                  <button type="submit" className="btn sub-btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BooksForm;
