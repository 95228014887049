import React from "react";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import RadioForm from "./forms/RadioForm";
import moment from "moment/moment";
import { http } from "../../../../services/http";
import { useState } from "react";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { useEffect } from "react";
import { useCallback } from "react";
import DynamicPagination from "../../../Utils/widgets/DynamicPagination";

const data = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    { fill: "origin" },
    {
      label: "First dataset",
      data: [33, 10, 85, 41, 44, 65, 0, 53, 300, 41, 44, 65],
      fill: false,
      borderColor: "rgba(27, 167, 231,1)",
    },
  ],
};

const legend = {
  display: true,
  position: "bottom",
  labels: {
    fontColor: "#323130",
    fontSize: 14,
  },
};

const options = {
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          suggestedMin: 0,
          suggestedMax: 100,
        },
      },
    ],
  },
};

function SuperAdminDashboard() {
  const [schoolList, setSchoolList] = useState();
  const [getSaleHistoryList, setSaleHistoryList] = useState({});
  const { setSpinner } = useContext(AppContext);
  const [page, setPage] = useState();

  // sales history list
  const handleSalesHistoryList = useCallback(() => {
    setSpinner(true);
    http(
      {
        method: "GET",
        url: `book/sales-history?page=1&size=2`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setSaleHistoryList(res.data);
      }
    );
  }, [setSpinner]);
  // school list
  const handleSchoolList = useCallback(
    (page) => {
      setSpinner(true);
      http(
        {
          method: "GET",
          url: `school?page=${page ? page : 1}&size=10`,
          isSecure: true,
        },
        (res) => {
          setSpinner(false);
          setSchoolList(res.data);
        }
      );
    },
    [setSpinner]
  );

  useEffect(() => {
    handleSchoolList(page);
    handleSalesHistoryList();
  }, [handleSchoolList, page, handleSalesHistoryList]);

  return (
    <div className="admin-dasboard row">
      <div className="dash_body_head col-md-12">
        <h2 className="class_head">Dashboard</h2>
      </div>
      <div className="col-sm-12 col-xl-7 graph-col">
        <div className="scale_data">
          <div className="scale_data_header">
            <h2 className="class_head">Books Sale</h2>
            <RadioForm />
          </div>
          <div className="data_graph">
            <Line data={data} legend={legend} options={options} />
          </div>
        </div>
        <div className="sale_history_list">
          <div className="scale_data_header table_heading">
            <h2 className="class_head">Sales History</h2>
            <div className="view_all">
              <Link to="/admin/sales">View All</Link>
            </div>
          </div>
          <div className="kina_tables table-responsive">
            <table className="dash_tables" id="dash_dataTable">
              <thead>
                <tr>
                  <th className="SnumData">S.No.</th>
                  <th>User</th>
                  <th>Book</th>
                  <th>Date and Time of Purchase</th>
                  <th className="pricingTh">Price</th>
                </tr>
              </thead>
              <tbody>
                {getSaleHistoryList?.data?.length ? (
                  getSaleHistoryList?.data?.map((item, index) => {
                    const DateOfPurchase = moment
                      .utc(item.created_at)
                      .format("DD MMM YYYY");
                    const TimeOfPurchase = moment
                      .utc(item.created_at)
                      .format("hh:mm A");
                    return (
                      <tr key={item.id}>
                        <td>{getSaleHistoryList.from + index}</td>
                        <td>
                          <Link to="user-details" state={item}>
                            {item.user.name}
                          </Link>
                        </td>
                        <td>{item.book.name}</td>
                        <td>{`${DateOfPurchase}, ${TimeOfPurchase}`}</td>
                        <td>${item.book.price}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="10" className="w-100 text-center">
                      Data Not Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="col-sm-12 col-xl-5">
        <div className="school_history">
          <div className="scale_data_header table_heading">
            <h2 className="class_head">Schools</h2>
            <div className="view_all">
              <Link to="/admin/school">View All</Link>
            </div>
          </div>
          <div className="kina_tables table-responsive">
            <table className="dash_tables" id="dash_dataTable">
              <thead>
                <tr>
                  <th className="SnumData">S.No.</th>
                  <th className="schoolTh">School</th>
                  <th className="locatTh">Location</th>
                  <th className="phNum">Phone Number</th>
                </tr>
              </thead>
              <tbody>
                {schoolList?.data?.length ? (
                  schoolList?.data?.map((historyData, index) => {
                    return (
                      <tr key={index}>
                        <td>{schoolList.from + index}</td>
                        <td>
                          <Link to="school-details" state={historyData}>
                            {historyData.name ? historyData.name : "-"}
                          </Link>
                        </td>
                        <td>
                          {historyData.location ? historyData.location : "-"}
                        </td>
                        <td>
                          <span className="text-nowrap">
                            {historyData.phone_number
                              ? historyData.phone_number
                              : "-"}
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center w-100">
                      Data Not Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* {schoolList?.total > 0 &&
            schoolList?.total > schoolList?.per_page && (
              <DynamicPagination
                totalPages={schoolList?.last_page}
                currentPage={schoolList?.current_page}
                page={page}
                setPage={(page) => setPage(page)}
              />
            )} */}
        </div>
      </div>
    </div>
  );
}
export default SuperAdminDashboard;
