import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { useForm, patterns } from "@kaydhiman/react-hook-useform";
import UploadProfile from "../../../../Utils/widgets/UploadProfile";

import TypeAheadContainer from "../../../../Utils/widgets/TypeAheadContainer";
import InputBox from "../../../../Utils/forms/InputBox";
import CustomButton from "../../../../Utils/forms/CustomButton";
import { http } from "../../../../../services/http";
import { AppContext } from "../../../../context/AppContext";

import PrefImg from "../../../../../assets/images/prev_ico.png";

function AddTeacher() {
  const { setSpinner } = useContext(AppContext);
  const navigate = useNavigate();
  const { teacherId } = useParams();
  const [Selectsub, setSelectSub] = useState([]);
  const [subjectList, setSubjectList] = useState([]);

  const [profileImage, setProfileImage] = useState({ file: "", url: "" });

  const { values, errors, bindField, setInitialValues } = useForm({
    validations: {
      email: {
        pattern: {
          value: patterns.email,
          message: "Invalid email address.",
        },
        required: true,
      },
      phone_number: {},
      name: {
        required: true,
      },
      file: {
        required: true,
      },
      roll_number: {},
      subject: {},
    },
  });
  //subject list
  useEffect(() => {
    http(
      {
        method: "GET",
        url: `school/subjects`,
        isSecure: true,
      },
      (res) => {
        // Filtering list based on already selected subjects
        setSubjectList(
          res.data.filter(
            (element) =>
              !Selectsub.some((elem) => elem?.subject_id === element.id)
          )
        );
      }
    );
  }, [setSubjectList, Selectsub]);

  const handleImage = async (e) => {
    e.preventDefault();
    if (!profileImage.file) {
      addTeacher();
    } else {
      const formData = new FormData();
      formData.append("type", "photo");
      formData.append("file", profileImage.file);
      setSpinner(true);
      await http(
        {
          method: "POST",
          url: `upload-image`,
          isSecure: true,
          contentType: "multipart/form-data",
          body: formData,
        },
        (res) => {
          setSpinner(false);
          addTeacher(res.content.imageName);
        },
        (res) => {
          setSpinner(false);
          toast.error(res.message, {
            autoClose: 3000,
          });
        }
      );
    }
  };

  const handleUploadImg = (e) => {
    setProfileImage({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  // add or edit teacher  api

  const addTeacher = (img) => {
    setSpinner(true);
    http(
      {
        method: teacherId ? "PUT" : "POST",
        url: `teacher${teacherId ? "/" + teacherId : " "}`,
        isSecure: true,
        body: {
          ...(values.roll_number ? { roll_number: values.roll_number } : ""),
          ...(values.phone_number ? { phone_number: values.phone_number } : ""),
          subject: values?.subject?.map((item) => item.id),
          email: values?.email,
          name: values?.name,
          ...(img ? { file: img } : ""),
        },
      },
      (res) => {
        setSpinner(false);
        toast.success(res.message, {
          autoClose: 3000,
        });
        navigate("/school/teachers");
      },
      (res) => {
        setSpinner(false);
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  //get single teacher
  const getTeacher = useCallback(() => {
    http(
      {
        method: "GET",
        url: `teacher/${teacherId}`,
        isSecure: true,
      },
      (res) => {
        setSelectSub(res.data.teacher_subjects);
        setInitialValues({
          name: res.data.user.name,
          roll_number: res.data.roll_number,
          phone_number: res.data.user.phone_number,
          email: res.data.user.email,
          subject:
            res.data?.teacher_subjects?.length > 0
              ? res.data?.teacher_subjects.map((sub) => sub.subject)
              : [],
        });
        setProfileImage({
          url: process.env.REACT_APP_MEDIA_URL + res.data.file,
        });
      }
    );
  }, [setInitialValues, teacherId]);

  useEffect(() => {
    teacherId && getTeacher();
  }, [getTeacher, teacherId]);

  return (
    <div className="update_user add_teacher">
      <div className="sup_history">
        <div className="scale_data_header">
          <h2 className="class_head">
            <Link to="/school/teachers" className="prev_btn">
              <span className="backBtn">
                <img className="backPg" src={PrefImg} alt="Back button" />
              </span>
            </Link>
            {teacherId ? "Edit" : "Add"} Teacher
          </h2>
        </div>
        <div className="data_add_form admin-form">
          <form onSubmit={handleImage}>
            <div className="row">
              <div className="col-lg-3">
                <UploadProfile
                  picture={profileImage}
                  label="Upload Profile Photo"
                  required="true"
                  {...bindField("file")}
                  error={errors.file}
                  onChange={handleUploadImg}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-3">
                <InputBox
                  label="Teacher Name"
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter teacher name"
                  classname="form-control"
                  {...bindField("name")}
                  error={errors.name}
                  required="true"
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-1 col-lg-3">
                <div className="form-group">
                  <label htmlFor="className" className="form-label">
                    Subject <span className="required">*</span>
                  </label>
                  <div className="dropdown-box">
                    <TypeAheadContainer
                      id="subject"
                      labelKey={(item) => `${item?.name} (${item.class?.name})`}
                      name="subject"
                      placeholder="Select Subject"
                      options={subjectList}
                      selected={values.subject || []}
                      onChange={(value) => {
                        setInitialValues((prev) => ({
                          ...prev,
                          subject: value,
                        }));
                      }}
                      error={errors.class}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <InputBox
                  label="Roll No"
                  type="text"
                  id="roll_number"
                  name="roll_number"
                  placeholder="Enter roll no"
                  classname="form-control"
                  {...bindField("roll_number")}
                  error={errors.roll_number}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <InputBox
                  label="Phone No"
                  type="text"
                  id="phone_number"
                  name="phone_number"
                  placeholder="Enter phone no"
                  classname="form-control"
                  {...bindField("phone_number")}
                  error={errors.phone_number}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <InputBox
                  label="Email"
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter email"
                  classname="form-control"
                  {...bindField("email")}
                  error={errors.email}
                  required="true"
                />
              </div>
            </div>

            <div className="addon_btn row">
              <div className="col-md-3">
                <CustomButton
                  text="Submit"
                  type="submit"
                  classname="sub-btn"
                  disabled={
                    !values.name ||
                    !values.email ||
                    !values.subject ||
                    !profileImage?.url
                  }
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default AddTeacher;
