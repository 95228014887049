import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import SearchForm from "../../../Utils/forms/SearchFrorm";
import { ACTIVE, IN_ACTIVE } from "../../../../utilities/constants";
import { AppContext } from "../../../context/AppContext";
import { http } from "../../../../services/http";

import ToggleBtn from "../../../Utils/forms/ToggleBtn";
// import InputField from "../../../Utils/forms/InputField";
import debounce from "../../../../utilities/debounce";
import PagePagination from "../../../Utils/pagination/PagePagination";
import binImg from "../../../../assets/images/trash.png";
import penImg from "../../../../../src/assets/images/edit_action.png";

function Classes() {
  const [classList, setClassList] = useState({});
  const [page, setPage] = useState();
  // const [select, setSelect] = useState();
  const [getSearch, setSearch] = useState("");
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    id: "",
  });

  const navigate = useNavigate();

  const { setSpinner, size } = useContext(AppContext);

  const getClasses = () => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: size,
    };
    if (getSearch) {
      params.search = getSearch;
    }

    http(
      {
        method: "GET",
        url: `class?${new URLSearchParams(params)}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setClassList(res.data);
      }
    );
  };
  // useCallback(
  //   ({ page, size, search }) => {
  //     setSpinner(true);
  //     http(
  //       {
  //         method: "GET",
  //         url: `class?page=${page ? page : 1}&size=${size ? size : 2}&search=${
  //           search ? search : ""
  //         }`,
  //         isSecure: true,
  //       },
  //       (res) => {
  //         setSpinner(false);
  //         setClassList(res.data);
  //       }
  //     );
  //   },
  //   [setSpinner]
  // );

  // eslint-disable-next-line
  // const debouncedChangeHandler = useCallback(debounce(getClasses, 300), []);
  // eslint-disable-next-line
  const debouncedChangeHandlers = useCallback(
    debounce((event) => {
      setSearch(event?.target?.value);
      setPage(1);
    }, 500)
  );

  //status of class

  const statusOfClass = (id, isChecked) => {
    setSpinner(true);
    http(
      {
        method: "PATCH",
        url: `class/${id}/activeInactive`,
        isSecure: true,
        body: {
          action: `${isChecked ? ACTIVE : IN_ACTIVE}`,
        },
      },
      (res) => {
        setClassList((prevItem) => ({
          ...prevItem,
          data: prevItem.data.map((item) => {
            if (item.id === id) {
              item.is_active = isChecked ? 1 : 0;
            }
            return item;
          }),
        }));
        setSpinner(false);
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  //delete the class from api

  const deleteClass = (id) => {
    http(
      {
        method: "DELETE",
        url: `class/${id}`,
        isSecure: true,
      },
      (res) => {
        setClassList((prevItem) => {
          return {
            ...prevItem,
            data: prevItem?.data?.filter((item) => item.id !== id),
          };
        });
        setIsModalVisible({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  // School Listing API

  useEffect(() => {
    getClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSearch, page]);

  return (
    <div className="add_class class_dash school_history">
      <div className="sup_history">
        <div className="scale_data_header">
          <h2 className="class_head">Classes </h2>
          <div className="search-box">
            {/* <InputField
              type="text"
              className="form-control searchBar"
              placeholder="Search..."
              onChange={
                debouncedChangeHandler({
                  search: e.target.value,
                  page: 1,
                  select,
                })
              }
            /> */}
            <SearchForm
              name="search"
              // value={getSearch}
              onChange={debouncedChangeHandlers}
            />
            <div className="data_add">
              <Link to="add" className="btn sub-btn btn-primary">
                Add Class
              </Link>
            </div>
          </div>
        </div>
        <div className="kina_tables table-responsive">
          <table className="dash_tables">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Class Name</th>
                <th>Active / Inactive</th>
                <th className="pricingTh">Action</th>
              </tr>
            </thead>
            <tbody>
              {classList?.data?.length ? (
                classList?.data?.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{classList.from + index}</td>
                      <td className="plm-0">
                        <span
                          onClick={() => {
                            navigate(`${item.id}`, {
                              state: [
                                {
                                  label: item.name,
                                  url: window.location.pathname,
                                },
                              ],
                            });
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {item.name}
                        </span>
                      </td>
                      <td>
                        <ToggleBtn
                          onchange={(e) =>
                            statusOfClass(item.id, e.target.checked)
                          }
                          checked={item.is_active === 1}
                        />
                      </td>
                      <td className="p-0">
                        <div className="d-flex justify-content-center">
                          <Link
                            to={`edit/${item?.id}?name=${item.name}`}
                          >
                            <img src={penImg} alt="Edit" />
                          </Link>
                          <img
                            src={binImg}
                            alt="binImg"
                            className="custom_marg_left hand"
                            onClick={() => {
                              setIsModalVisible({
                                isShow: true,
                                id: item.id,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="8" className="error01">
                    Data Not Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {classList?.total > 0 && classList?.total > classList?.per_page && (
        <PagePagination
          className="pagination-bar"
          currentPage={classList?.current_page}
          totalCount={
            classList?.last_page !== undefined
              ? classList?.total
              : classList?.length
          }
          pageSize={size}
          onPageChange={(page) => setPage(page)}
        />
      )}
      {/* Delete confirm modal section start */}

      {isModalVisible.isShow ? (
        <Modal
          show={isModalVisible}
          onHide={() => setIsModalVisible(false)}
          className="delete-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Do you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsModalVisible(false)}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => deleteClass(isModalVisible.id)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}

      {/* Delete confirm modal section end */}
    </div>
  );
}
export default Classes;
