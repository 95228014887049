import React, { useState } from "react";

import { Modal, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import binImg from "../../../../../assets/images/trash.png";
import BlueEye from "../../../../../assets/images/blue-eye.svg";
import PrefImg from "../../../../../assets/images/prev_ico.png";
import CrossIcon from "../../../../../assets/images/red_cross.svg";
import UserDp from "../../../../../assets/images/student_detail.png";
import ReportCard from "../../../../../assets/images/report-card.svg";

function StudentReport() {
  const location = useLocation();
  const getMarks = location?.state;

  const [marks, setMarks] = useState(getMarks);

  const [showModal, setShowModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [showPdf, setShowPdf] = useState();

  const handleOk = () => {
    setIsModalVisible(false);
    setMarks(false);
  };

  // pdf Upload handle section start
  const uploadPdfFile = (e) => {
    const getPdf = URL.createObjectURL(e.target.files[0]);
    setShowPdf(getPdf);
  };
  // pdf Upload handle section end

  return (
    <div className="user_details student-details">
      <div className="col-md-12 ">
        <div className="sup_history">
          <div className="scale_data_header">
            <h2 className="class_head">
              <Link to="/reports" className="prev_btn">
                <span className="backBtn">
                  <img className="backPg" src={PrefImg} alt="Back button" />
                </span>
              </Link>
              Student Details
            </h2>
          </div>
          <div className="user_card">
            <div className="user_profile">
              <img src={UserDp} alt="User" />
            </div>
            <ul className="user_lists skool_list">
              <li>
                <div className="detail_sec">
                  <p className="user_name_th">Name</p>
                  <h3 className="user_name_td">
                    {location.state.student_name}
                  </h3>
                </div>
              </li>
              <li>
                <div className="detail_sec">
                  <p className="user_name_th">Class</p>
                  <h3 className="user_name_td">{location.state.h_class}</h3>
                </div>
              </li>
              <li>
                <div className="detail_sec">
                  <p className="user_name_th">Email Id</p>
                  <h3 className="user_name_td">{location.state.email}</h3>
                </div>
              </li>
              <li>
                <div className="detail_sec">
                  <p className="user_name_th">Phone Number</p>
                  <h3 className="user_name_td">{location.state.phone_no}</h3>
                </div>
              </li>
              <li>
                <div className="detail_sec">
                  <p className="user_name_th">Roll No</p>
                  <h3 className="user_name_td">{location.state.roll_no}</h3>
                </div>
              </li>
            </ul>
          </div>
          <div className="cards-data">
            <div className="row">
              <div className="col-md-12">
                <h2 className="class_head mb-4">Report Cards</h2>
              </div>

              {marks && (
                <div className="col-md-1 text-center ">
                  <div className="user_card ">
                    <div className="card-img">
                      <img src={ReportCard} alt="Report card" />
                    </div>
                    <div className="action-btns">
                      <ul>
                        <li>
                          <img
                            src={BlueEye}
                            onClick={() => setShowModal(true)}
                            alt="Show Icon "
                          />
                        </li>
                        <li>
                          <img
                            src={binImg}
                            alt="PDF Icon"
                            onClick={() => setIsModalVisible(true)}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="data_add_form admin-form">
            <form>
              <div className="row">
                <div className="mb-4">
                  <label htmlFor="file" className="form-label">
                    Upload New Report
                  </label>
                  <div className="item-content file_up">
                    <div className="pdf-upload">
                      <input
                        type="file"
                        className="btn sub-btn  upload_btn"
                        accept=".pdf"
                        onClick={(e) => (e.target.value = "")}
                        onChange={(e) => uploadPdfFile(e)}
                      />

                      {showPdf ? (
                        <div className="file_icon">
                          {/* <img
                          className="file"
                          src={PDFicon}
                          alt="Upload book as pdf"
                        /> */}
                          {/* <iframe src={showPdf} title="pdf_file" accept=".pdf" /> */}

                          <object
                            data={showPdf}
                            width="500"
                            height="500"
                            aria-label="showPdf"
                          ></object>
                          <img
                            className="cut_pdf video_cross"
                            src={CrossIcon}
                            alt="Remove selected file"
                            onClick={() => setShowPdf(null)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="addon_btn row">
                  <div className="col-xl-3 col-lg-6 ">
                    <Link
                      to="/reports"
                      type="submit"
                      className="btn sub-btn btn-primary"
                    >
                      Send Report
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Delete confirm modal section start */}
      <Modal
        show={isModalVisible}
        onHide={() => setIsModalVisible(false)}
        className="delete-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Do you want to delete?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModalVisible(false)}>
            No
          </Button>
          <Button variant="primary" onClick={() => handleOk()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Delete confirm modal section end */}

      {/*Report card Modal Section start */}

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{marks?.student_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="marks_table">
            <tr>
              <th>Subject</th>
              <th>Marks</th>
            </tr>

            <tr>
              <td>Digital Electronics </td>
              <td>{marks?.digitalelectronics}</td>
            </tr>
            <tr>
              <td>Applied Mathematic II </td>
              <td>{marks?.appliedmathematicII}</td>
            </tr>
            <tr>
              <td>Physics </td>
              <td>{marks?.physics}</td>
            </tr>
            <tr>
              <td>Computer Sciences </td>
              <td>{marks?.computersciences}</td>
            </tr>
            <tr>
              <td>Control Analysis </td>
              <td>{marks?.controlanalysis}</td>
            </tr>
            <tr>
              <td>English Communication </td>
              <td>{marks?.englishcommunication}</td>
            </tr>
            <tr>
              <td>Human Values </td>
              <td>{marks?.humanvalues}</td>
            </tr>
          </table>
        </Modal.Body>
      </Modal>

      {/* Report card Modal section end */}
    </div>
  );
}
export default StudentReport;
