import React, { useState, useCallback, useContext, useEffect } from "react";

import { Modal, Button, DropdownButton, Dropdown } from "react-bootstrap";
import { useForm } from "@kaydhiman/react-hook-useform";
import { http } from "../../../../services/http";
import { toast } from "react-toastify";
import PagePagination from "../../../Utils/pagination/PagePagination";
import { AppContext } from "../../../context/AppContext";
import TextArea from "../../../Utils/forms/TextArea";
import CustomButton from "../../../Utils/forms/CustomButton";
import binImg from "../../../../assets/images/trash.png";
import penImg from "../../../../assets/images/edit_action.png";
import homeImage from "../../../../assets/images/home_work.png";
import announceImage from "../../../../assets/images/announcement.png";
import messageImage from "../../../../assets/images/message.png";
import moment from "moment/moment";

function Notification() {
  const [notification, setNotification] = useState([]);
  const [page, setPage] = useState();
  const [notificationList, setNotificationList] = useState();
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    id: "",
  });
  const { setSpinner, size } = useContext(AppContext);
  const { values, errors, bindField, setInitialValues } = useForm({
    validations: {
      title: {
        required: true,
      },
      notification_category_id: {},
    },
    // initialValues: { class_data: [] },
  });

  //delete notifaiction
  const deleteNotification = (id) => {
    http(
      {
        method: "DELETE",
        url: `notification/${id}`,
        isSecure: true,
      },
      (res) => {
        setNotification((prevData) => {
          return {
            ...prevData,
            data: prevData.data?.filter((items) => items.id !== id),
          };
        });
        setIsModalVisible({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });

        if (notification.data.length - 1 === 0 && notification.last_page > 1) {
          getNotifications();
        }
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  //add notifiaction
  const addNotification = (e) => {
    e.preventDefault();
    setSpinner(true);
    http(
      {
        method: values.id ? "PUT" : "POST",
        url: `notification${values.id ? "/" + values.id : ""}`,
        isSecure: true,
        body: {
          title: values.title,
          notification_category_id: values.notificationId,
          sent_by: "schoolAdmin",
        },
      },
      (res) => {
        setSpinner(false);

        setNotification((prevData) => {
          let data;
          if (values.id) {
            data = prevData.data.map((notf) => {
              if (notf.id === values.id) {
                notf = res.data;
              }
              return notf;
            });
          } else {
            if (prevData.last_page === 1 && prevData.data.length < 7) {
              data = [...prevData.data, res.data];
            } else {
              data = prevData.data;
            }
          }
          return {
            ...prevData,
            data,
            ...(notification.data.length === 7 &&
              notification.last_page === 1 && {
                last_page: notification.last_page + 1,
                total: notification.total + 1,
              }),
          };
        });
        toast.success(res.message, {
          autoClose: 3000,
        });
        setInitialValues({});
      },
      (res) => {
        setSpinner(false);
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  //notifiacation listing
  const getNotifications = () => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: size,
    };

    http(
      {
        method: "GET",
        url: `notification?${new URLSearchParams(params)}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setNotification(res.data);
      }
    );
  };
  // useCallback(
  //   ({ page } = {}) => {
  //     setSpinner(true);
  //     http(
  //       {
  //         method: "GET",
  //         url: `notification?page=${page || 1}&size=10`,
  //         isSecure: true,
  //       },
  //       (res) => {
  //         setSpinner(false);
  //         setNotification(res.data);
  //       }
  //     );
  //   },
  //   [setSpinner]
  // );

  //get Single Notification
  const getSingleNotification = useCallback(
    (id) => {
      http(
        {
          method: "GET",
          url: `notification/${id}`,
          isSecure: true,
        },
        (res) => {
          setInitialValues({
            notificationNm: res.data.notification_category
              ? res.data.notification_category.name
              : "",
            notificationId: res.data.notification_category
              ? res.data.notification_category.id
              : "",
            title: res.data.title,
            id: res.data.id,
          });
        }
      );
    },
    [setInitialValues]
  );

  // get Notification Categories dropdown list
  useEffect(() => {
    http(
      {
        method: "GET",
        url: `notificationCategories`,
        isSecure: true,
      },
      (res) => {
        setNotificationList(res);
      }
    );
  }, []);

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div className="school_history notification_dash">
      <div className="sup_history row">
        <div className="col-xl-7 col-lg-12 col-md-12 notiTable">
          <div className="scale_data_heading">
            <h2 className="class_head">Notifications</h2>
          </div>
          <div className="kina_tables table-responsive">
            <table className="dash_tables w-100">
              <thead>
                <tr>
                  <th className="SnumData">S.No.</th>
                  <th className="notifyTh">Notifications</th>
                  <th className="catTh">Category</th>
                  <th className="dateTh">Date</th>
                  <th className="pricingTh">Action</th>
                </tr>
              </thead>
              <tbody>
                {notification?.data?.length ? (
                  notification?.data?.map((item, index) => {
                    const getDate = moment
                      .utc(item.created_at)
                      .format("DD MMM YYYY");
                    return (
                      <tr key={index}>
                        <td>{(notification.from || 1) + index}</td>
                        <td className="word_unset">{item.title}</td>
                        <td>
                          <div className="notification-icons">
                            <div className="d-flex">
                              {item.notification_category?.name ===
                                "home work" && (
                                <div className="d-flex align-items-center">
                                  <img
                                    src={homeImage}
                                    alt={item.notification_category?.name}
                                    className="notification_icon"
                                  />
                                  <span className="text-nowrap home_work ml-2">
                                    {item.notification_category?.name}
                                  </span>
                                </div>
                              )}
                              {item.notification_category?.name ===
                                "messages" && (
                                <div className="d-flex align-items-center">
                                  <img
                                    src={messageImage}
                                    alt={item.notification_category?.name}
                                    className="notification_icon"
                                  />
                                  <span className="text-nowrap message_text ml-2">
                                    {item.notification_category?.name}
                                  </span>
                                </div>
                              )}
                              {item.notification_category?.name ===
                                "announcement" && (
                                <div className="d-flex align-items-center">
                                  <img
                                    src={announceImage}
                                    alt={item.notification_category?.name}
                                    className="notification_icon"
                                  />
                                  <span className="text-nowrap announcText ml-2">
                                    {item.notification_category?.name}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="text-nowrap">{getDate}</td>
                        <td className="p-0">
                          <div className="d-flex justify-content-center">
                            <img
                              src={penImg}
                              alt="PDF Icon"
                              className="hand"
                              onClick={() => getSingleNotification(item.id)}
                            />
                            <img
                              src={binImg}
                              alt="Delete"
                              className="custom_marg_left hand"
                              onClick={() => {
                                setIsModalVisible({
                                  isShow: true,
                                  id: item.id,
                                });
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="8" className="error01">
                      Data Not Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {notification?.total > 0 &&
              notification?.total > notification?.per_page && (
                <PagePagination
                  className="pagination-bar"
                  currentPage={notification?.current_page}
                  totalCount={
                    notification?.last_page !== undefined
                      ? notification?.total
                      : notification?.length
                  }
                  pageSize={size}
                  onPageChange={(page) => setPage(page)}
                />
              )}
          </div>
        </div>

        <div className="col-xl-5 col-lg-12 col-md-12 notiForm">
          <div className="scale_data_heading">
            <h2 className="class_head">Add Notification</h2>
          </div>
          <div className="mt-5 row admin-form">
            <form onSubmit={addNotification}>
              <div className="mb-4 col-lg-7 ">
                {/* class dropdown section start */}
                <label htmlFor="class" className="form-label w-auto">
                  Catagory
                </label>
                <div className="dropdown-box">
                  <DropdownButton
                    className={`dropdown-btn`}
                    title={
                      values?.notificationNm ? (
                        values?.notificationNm
                      ) : (
                        <span>Select Notification Catagory</span>
                      )
                    }
                  >
                    {notificationList?.data?.map((elem) => {
                      return (
                        <div
                          key={elem?.id}
                          className="dropdown_projects"
                          onClick={() =>
                            setInitialValues({
                              ...values,
                              notificationId: elem.id,
                              notificationNm: elem.name,
                            })
                          }
                        >
                          <Dropdown.Item
                            className="dropdown-status"
                            title={elem?.name}
                          >
                            <span
                              className={
                                elem?.name === values?.notificationNm
                                  ? "class_active"
                                  : ""
                              }
                            >
                              {elem?.name}
                            </span>
                          </Dropdown.Item>
                        </div>
                      );
                    })}
                  </DropdownButton>
                </div>
              </div>
              <div className="mb-4 col-lg-12 ">
                <TextArea
                  label="Add Notification"
                  id="title"
                  name="title"
                  placeholder="Enter Notification"
                  {...bindField("title")}
                  error={errors.title}
                />
              </div>

              <CustomButton
                text={values.id ? "Edit" : "Add"}
                type="submit"
                classname="sub-btn"
              />
            </form>

            <div className="addon_btn row"></div>
          </div>
        </div>
      </div>
      {/* Delete confirm modal section start */}

      {isModalVisible.isShow && (
        <Modal
          show={isModalVisible}
          onHide={() => setIsModalVisible(false)}
          className="delete-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Do you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsModalVisible(false)}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => deleteNotification(isModalVisible.id)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Delete confirm modal section end */}
    </div>
  );
}
export default Notification;
