import React, { useCallback, useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { useForm } from "@kaydhiman/react-hook-useform";

import { DropdownButton, Dropdown } from "react-bootstrap";
import BreadCrumb from "../../../../../form-widgets/BreadCrumb";
import InputBox from "../../../../Utils/forms/InputBox";
import { http } from "../../../../../services/http";
import TextArea from "../../../../Utils/forms/TextArea";
import CustomButton from "../../../../Utils/forms/CustomButton";
import { AppContext } from "../../../../context/AppContext";

function AddExamForm() {
  const [catagoryType, setCatagoryType] = useState(1);

  const { id, subId, examId } = useParams();
  const [examCatgoryList, setExamCatgoryList] = useState([]);
  const { setSpinner } = useContext(AppContext);

  const navigate = useNavigate();

  const [examQuestionList, setExamQuestionList] = useState([]);
  const [answers, setAnswers] = useState([
    { id: 1, answer: "", is_correct: false },
  ]);

  // validations code
  const { values, errors, bindField, setInitialValues, checkErrors } = useForm({
    validations: {
      name: {
        required: true,
      },
      ...(!examId && {
        question: {
          required: true,
        },
      }),
      exam_category_id: {
        required: true,
      },
    },
  });

  const changeCatogeryHandler = (e) => {
    const { value } = e.target;

    setCatagoryType(+value);

    if (+value === 1) {
      setAnswers([{ id: 1, answer: "", is_correct: false }]);
    } else if (+value === 2) {
      setAnswers([
        { id: 1, answer: "Yes", is_correct: false },
        { id: 2, answer: "No", is_correct: false },
      ]);
    }
  };

  // add exam api
  const handleAddExam = (e) => {
    e.preventDefault();

    if (!checkErrors()) {
      return;
    }

    if (answers?.some((item) => item.answer === "") && !examId) {
      toast.success("Please enter all answers fields", {
        autoClose: 3000,
      });
      return;
    }
    if (!answers?.some((item) => item.is_correct) && !examId) {
      toast.success("Please select atleast one correct answer", {
        autoClose: 3000,
      });
      return;
    }

    http(
      {
        method: examId ? "PUT" : "POST",
        url: `class/${id}/subject/${subId}/exam/${examId ? examId : " "}`,
        isSecure: true,
        body: {
          ...values,
          ...(!examId && {
            answers,
            exam_question_category_id: catagoryType,
            ...(catagoryType === 1 ? { answer_count: answers.length } : {}),
          }),
          type:"other"
        },
      },
      (res) => {
        setSpinner(false);
        navigate(-1);
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  //handle change
  function handleChange(e) {
    if (+e.target.value > 4 || +e.target.value === 0) {
      toast.error("You Can Add Maximum 4 Or Minimum 1.");
      return;
    }

    if (+e.target.value < answers.length) {
      setAnswers((prevFields) => {
        return prevFields.slice(
          0,
          answers.length - (answers.length - +e.target.value)
        );
      });
    } else {
      let newItems = [];

      for (let i = 1; i <= +e.target.value - answers.length; i++) {
        newItems.push({
          id:
            i === 1
              ? answers.length > 0
                ? answers[answers.length - 1].id + 1
                : 1
              : newItems[newItems.length - 1].id + 1,
          value: null,
        });
      }

      setAnswers((prevFields) => [...prevFields, ...newItems]);
    }
  }

  //add answer count
  function handleAdd() {
    if (answers.length <= 3) {
      setAnswers((prevFields) => [
        ...prevFields,
        {
          id: answers.length > 0 ? answers[answers.length - 1].id + 1 : 1,
          answer: "",
          is_correct: false,
          validate: false,
        },
      ]);
    }
  }

  //remove answer count
  function handleRemove() {
    const values = [...answers];
    if (answers.length > 1) {
      values.pop();
      setAnswers(values);
    }
  }

  function fieldChange(id, value, type) {
    setAnswers((prevFields) =>
      prevFields.map((field) => {
        if (field.id === id) {
          switch (type) {
            case "answer":
              field.answer = value;
              break;
            case "isCorrect":
              field.is_correct = value;
              break;
            default:
              break;
          }
        }
        return field;
      })
    );
  }

  //get single exam listing
  const getSingleExam = useCallback(() => {
    setSpinner(true);
    http(
      {
        method: "GET",
        url: `class/${id}/subject/${subId}/exam/${examId}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setInitialValues({
          ...res.data,
        });
      }
    );
  }, [setSpinner, setInitialValues, examId, id, subId]);

  useEffect(() => {
    examId && getSingleExam();
  }, [getSingleExam, examId]);

  // get exam category list
  useEffect(() => {
    http(
      {
        method: "GET",
        url: "examCategories",
        isSecure: true,
      },
      (res) => {
        setExamCatgoryList(res.data);
      }
    );
    // eslint-disable-next-line
  }, []);

  // get exam question category list
  useEffect(() => {
    http(
      {
        method: "GET",
        url: "examQuestionCategories",
        isSecure: true,
      },
      (res) => {
        setExamQuestionList(res.data);
      }
    );
    // eslint-disable-next-line
  }, []);

  return (
    <div className=" user_details edit_test_dash px-0">
      <div className="sup_history update_user ">
        <div className="scale_data_header">
          <BreadCrumb
            links={[
              
              {
                label: `${examId ? "Edit" : "Add"} Exams`,
                style: { color: "black" },
                url: " ",
              },
            ]}
          />
        </div>
        <div className="data_add_form admin-form">
          <form onSubmit={handleAddExam}>
            <div className="row">
              <div className="mb-4 col-lg-3">
                <InputBox
                  label="Exam Name"
                  id="exam_name"
                  placeholder="Enter exam name"
                  type="text"
                  name="name"
                  classname="form-control"
                  {...bindField("name")}
                  error={errors.name}
                  required="true"
                />
              </div>
              <div className="mb-4 col-lg-3">
                <label htmlFor="category" className="form-label">
                  Exam Category <span className="error_message">*</span>
                </label>
                <div className="dropdown-box">
                  <DropdownButton
                    className={`dropdown-btn`}
                    title={
                      examCatgoryList?.filter(
                        (item) => item.id === values.exam_category_id
                      )[0]?.name || <span>Select Category</span>
                    }
                  >
                    {examCatgoryList?.map((elem) => {
                      return (
                        <div
                          key={elem?.id}
                          className="dropdown_projects"
                          onClick={() =>
                            setInitialValues({
                              ...values,
                              exam_category_id: elem.id,
                              exam_name:elem.name
                            })
                          }
                        >
                          <Dropdown.Item
                            className="dropdown-status"
                            title={elem?.name}
                          >
                            <span className={
                                    elem?.name === values?.exam_name ?
                                    "class_active" : ""
                                  }>
                              {elem.name}
                            </span>
                          </Dropdown.Item>
                        </div>
                      );
                    })}
                  </DropdownButton>
                </div>
              </div>
            </div>

            {examId ? (
              " "
            ) : (
              <>
                <div className="row">
                  <div className="mb-4 col-lg-6">
                    <TextArea
                      label="Question"
                      id="qa_input"
                      name="question"
                      placeholder="Enter question"
                      star="true"
                      {...bindField("question")}
                      error={errors.question}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="mb-4 col-lg-6 col-xl-3">
                    <label htmlFor="sName" className="form-label">
                      Category
                    </label>

                    <div className="dropdown">
                      <select
                        onChange={changeCatogeryHandler}
                        className="custom_selectBox"
                        value={catagoryType}
                      >
                        {examQuestionList.map((item, index) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {catagoryType === 1 && (
                    <>
                      <div className="mb-4 col-lg-6 col-xl-3">
                        <label htmlFor="sName" className="form-label">
                          Answer count
                        </label>
                        <div className="qaAdd">
                          <input
                            type="number"
                            className="form-control qa_count text-input theme-color answerCount"
                            id="answer_count"
                            name="answer_count"
                            onChange={handleChange}
                            value={answers.length}
                          />

                          {/* Answer Count Button section start */}
                          <div className="ques-btns">
                            <button
                              type="button"
                              className="qa_add add_qa"
                              onClick={() => handleAdd()}
                            ></button>
                            <button
                              type="button"
                              className="qa_add dec_qa"
                              onClick={() => handleRemove()}
                            ></button>
                          </div>
                          {/* Answer Count Button section end */}
                        </div>
                      </div>

                      <div className="row">
                        {answers.map((field, idx) => {
                          return (
                            <div
                              className="mb-4 col-lg-6 col-xl-6"
                              key={idx + 1}
                              id={idx + 1}
                            >
                              <TextArea
                                label={`Answer ${idx + 1}`}
                                id={`aw_count${idx + 1}`}
                                placeholder="Enter Answer"
                                value={field.answer}
                                onChange={(e) =>
                                  fieldChange(
                                    field.id,
                                    e.target.value,
                                    "answer"
                                  )
                                }
                              />

                              <div className="form-check confirmBox">
                                <input
                                  className="form-check-input confirm_input"
                                  type="radio"
                                  name="awn"
                                  checked={field.is_correct}
                                  onChange={(e) =>
                                    fieldChange(
                                      field.id,
                                      e.target.checked,
                                      "isCorrect"
                                    )
                                  }
                                />
                                <label className="form-label confirmlabel">
                                  Right Answer
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>

                {/* Multiple Answer increment/Decrement section end */}

                {/* Yes or No section start */}
                {catagoryType === 2 && (
                  <div className="row">
                    <div className="mb-4 col-lg-2 col-6">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Yes
                      </label>
                      <div className="form-check confirmBox">
                        <input
                          className="form-check-input confirm_input"
                          type="radio"
                          name="answer"
                          id="flexCheckDefault"
                          checked={answers[0].is_correct}
                          onChange={(e) =>
                            fieldChange(
                              answers[0].id,
                              e.target.checked,
                              "isCorrect"
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Right Answer
                        </label>
                      </div>
                    </div>
                    <div className="mb-4 col-lg-2 col-6 ">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        No
                      </label>
                      <div className="form-check confirmBox">
                        <input
                          className="form-check-input confirm_input"
                          type="radio"
                          name="answer"
                          id="flexCheckDefault"
                          checked={answers[1].is_correct}
                          onChange={(e) =>
                            fieldChange(
                              answers[1].id,
                              e.target.checked,
                              "isCorrect"
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Right Answer
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                {/* Yes or No section end */}
              </>
            )}

            <div className="addon_btn row">
              <div className="col-xl-3 col-lg-6 ">
                <CustomButton text="Submit" type="submit" classname="sub-btn" />
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer theme="dark" />
    </div>
  );
}
export default AddExamForm;
