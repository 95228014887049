import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";

import BreadCrumb from "../../../../form-widgets/BreadCrumb";
import { http } from "../../../../services/http";
import { AppContext } from "../../../context/AppContext";

import binImg from "../../../../assets//images/trash.png";
import penImg from "../../../../assets//images/edit_action.png";
import PagePagination from "../../../Utils/pagination/PagePagination";
import Accordian from "../../../Utils/widgets/Accordian";
import { toast } from "react-toastify";

function TestPrepration() {
  const [testPrepData, setTestPrepData] = useState();
  const [page, setPage] = useState();
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    id: "",
  });

  const { chptrId, subId, id, testId } = useParams();
  const { setSpinner, size } = useContext(AppContext);
  const breadCrumbs = useLocation();

  const testPreplist = () => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: size,
    };
    http(
      {
        method: "GET",
        url: `class/${id}/subject/${subId}/chapter/${chptrId}/test/${testId}/question?${new URLSearchParams(
          params
        )}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setTestPrepData(res.data);
      }
    );
  };
  // useCallback(
  //   (page) => {
  //     setSpinner(true);
  //     http(
  //       {
  //         method: "GET",
  //         url: `class/${id}/subject/${subId}/chapter/${chptrId}/test/${testId}/question?page=${
  //           page ? page : 1
  //         }&size=10`,
  //         isSecure: true,
  //       },
  //       (res) => {
  //         setSpinner(false);
  //         setTestPrepData(res.data);
  //       }
  //     );
  //   },
  //   [id, subId, chptrId, testId, setSpinner]
  // );

  // School list item delete function start
  const generalExamDeleteHandle = (questionId) => {
    setSpinner(true);
    http(
      {
        method: "DELETE",
        url: `class/${id}/subject/${subId}/chapter/${chptrId}/test/${testId}/question/${questionId}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setIsModalVisible({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });
        setTestPrepData((prevData) => {
          return {
            ...prevData,
            data: prevData?.data?.filter((items) => items.id !== questionId),
          };
        });
      }
    );
  };

  // const handleOk = () => {
  //   const data = allData?.filter((item) => item?.id !== id);
  //   setAllData(data);
  //   setIsModalVisible(false);
  // };
  // School list item dalete function end

  // Custom Accordian handle function start
  useEffect(() => {
    testPreplist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // Custom Accordian handle function end
  return (
    <>
      <div className=" user_details prepration_dash px-0">
        <div className="sup_history">
          <div className="scale_data_header">
            <BreadCrumb
              links={[...(breadCrumbs.state ? breadCrumbs.state : "")]}
            />
            <div className="search-box">
              <div className="data_add">
                <Link to="add" className="btn sub-btn btn-primary">
                  Add Question
                </Link>
              </div>
            </div>
          </div>
          <div className="kina_tables table-responsive examDetails">
            <table className="dash_tables">
              <thead>
                <tr>
                  <th className="SnumData">S.No.</th>
                  <th className="questionTh">Questions</th>
                  <th className="pricingTh">Action</th>
                </tr>
              </thead>
              <tbody>
                {testPrepData?.data?.length ? (
                  testPrepData?.data?.map((items, index) => {
                    return (
                      <tr key={index}>
                        <td>{testPrepData?.from + index}</td>
                        <td>
                          <Accordian
                            question={items.question}
                            id={items.id}
                            Data={items?.answers}
                          />
                        </td>
                        <td>
                          <ul>
                            <li>
                              <Link to={`edit/${items.id}`}>
                                <img src={penImg} alt="penImg" />
                              </Link>
                            </li>
                            <li>
                              <img
                                src={binImg}
                                alt="binImg"
                                onClick={() =>
                                  setIsModalVisible({
                                    isShow: true,
                                    id: items.id,
                                  })
                                }
                              />
                            </li>
                          </ul>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="8" className="error01">
                      Data Not Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* Delete confirm modal section start */}
        <Modal
          show={isModalVisible.isShow}
          onHide={() => setIsModalVisible({ isShow: false })}
          className="delete-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Do you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsModalVisible({ isShow: false })}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => generalExamDeleteHandle(isModalVisible.id)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Delete confirm modal section end */}
        {testPrepData?.total > 0 &&
          testPrepData?.total > testPrepData?.per_page && (
            <PagePagination
              className="pagination-bar"
              currentPage={testPrepData?.current_page}
              totalCount={
                testPrepData?.last_page !== undefined
                  ? testPrepData?.total
                  : testPrepData?.length
              }
              pageSize={size}
              onPageChange={(page) => setPage(page)}
            />
          )}
      </div>
    </>
  );
}
export default TestPrepration;
