import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useForm } from "@kaydhiman/react-hook-useform";

import CustomButton from "../../../../Utils/forms/CustomButton";
import { AppContext } from "../../../../context/AppContext";
import { http } from "../../../../../services/http";
import { toast } from "react-toastify";

import PrefImg from "../../../../../assets/images/prev_ico.png";
import InputBox from "../../../../Utils/forms/InputBox";
import TextArea from "../../../../Utils/forms/TextArea";

function AddNotice() {
  const { setSpinner } = useContext(AppContext);
  const navigate = useNavigate();
  //validations
  const { values, errors, bindField, isValid } = useForm({
    validations: {
      name: {
        required: true,
      },
      description: {},
      link: {},
    },
  });

  //add notice
  const addNotice = (e) => {
    e.preventDefault();
    setSpinner(true);
    http(
      {
        method: "POST",
        url: `notice`,
        isSecure: true,
        body: {
          ...values,
        },
      },
      (res) => {
        setSpinner(false);
        toast.success(res.message, {
          autoClose: 3000,
        });
        navigate("/school/notice");
      },
      (res) => {
        setSpinner(false);
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  return (
    <div className="update_user add_notice">
      <div className="sup_history">
        <div className="scale_data_header">
          <h2 className="class_head">
            <Link to="/school/notice" className="prev_btn">
              <span className="backBtn">
                <img className="backPg" src={PrefImg} alt="Back button" />
              </span>
            </Link>
            Add Notice
          </h2>
        </div>
        <div className="data_add_form admin-form">
          <form onSubmit={addNotice}>
            <div className="row">
              <div className="mb-4 col-lg-3 ">
                <InputBox
                  label="Add Title"
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Enter title"
                  classname="form-control"
                  {...bindField("title")}
                  error={errors.title}
                  required="true"
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-4 col-lg-3 ">
                <TextArea
                  label="Description (Optional)"
                  id="description"
                  name="description"
                  placeholder="Enter description"
                  {...bindField("description")}
                  error={errors.description}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-4 col-lg-3">
                <InputBox
                  label="Link (Optional)"
                  type="text"
                  id="link"
                  name="link"
                  placeholder="Enter title"
                  classname="form-control"
                  {...bindField("link")}
                  error={errors.link}
                />
              </div>
            </div>
            <div className="addon_btn row">
              <div className="col-md-3">
                <CustomButton
                  text="Submit"
                  type="submit"
                  classname="sub-btn"
                  disabled={!isValid}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default AddNotice;
