import React, { useRef, useState } from "react";
import useOutsideClick from "../../../utilities/UseOutsideClick";

const Accordian = ({ question, id, Data}) => {
  const filterDropDown = useRef(null);
  const [show, setShow] = useState(false);

  useOutsideClick(filterDropDown, function() {
    if(show) setShow(false);
  });

  return (
    <>
      <div
        onClick={() => setShow(pre => !pre)}
        ref={filterDropDown}
      >
        {/* Title section start */}
        <p className={`accordion-header ${show ? `rotate` : ``}`}>
          {question}
        </p>
        {/* Title section end */}
      </div>
      {/* Question dropdown section start */}

      {show && (
        <div
          className="accordion-collapse collapse show w-100"
        >
          {/* Content Section start */}
          <div className="accordion-body02">
            <ul className="acord_lists">
              {Data.map((item) => 
              <li key={item.id} className="accordian_bottom">
              
                  <div
                    className="form-check-label"
                    htmlFor={"flexRadioDefault1" + item.id}
                  >
                    <div className="w-100">
                      <span className="srNum w-100 text-left">{item.answer ? item.answer : ""}</span>
                      <span className="acordHead"> </span>
                    </div>
                
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id={"flexRadioDefault1" + item.id}
                      checked={item.is_correct}
                      readOnly
                    //   onChange={() => closeOption()}
                    />
                  </div>

                 
               
              </li>)}
            </ul>
          </div>
          {/* Content Section end */}
        </div>
      )}
    </>
  );
};

export default Accordian;
