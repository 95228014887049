import React, { useContext, useEffect, useState, useCallback } from "react";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { AppContext } from "../../../context/AppContext";
import { toast } from "react-toastify";
import { http } from "../../../../services/http";
import ToggleBtn from "../../../Utils/forms/ToggleBtn";
import BreadCrumb from "../../../../form-widgets/BreadCrumb";
import debounce from "../../../../utilities/debounce";
import binImg from "../../../../assets/images/trash.png";
import penImg from "../../../../assets/images/edit_action.png";
import PagePagination from "../../../Utils/pagination/PagePagination";
import { ACTIVE, IN_ACTIVE } from "../../../../utilities/constants";
import SearchForm from "../../../Utils/forms/SearchFrorm";

function ClassDetails(props) {
  //states here
  // eslint-disable-next-line no-unused-vars
  const [label, setLabel] = useState();
  const [page, setPage] = useState();
  // const [select, setSelect] = useState();
  const [getSearch, setSearch] = useState("");
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    id: "",
  });
  const navigate = useNavigate();
  const breadCrumbs = useLocation();
  const [subject, setSubject] = useState({});
  const { id } = useParams();
  const { setSpinner, size } = useContext(AppContext);

  const getSubjectDetails = () => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: size,
    };
    if (getSearch) {
      params.search = getSearch;
    }

    http(
      {
        method: "POST",
        url: `class/subjects?${new URLSearchParams(params)}`,
        isSecure: true,
        body: {
          class_id: [id],
        },
      },
      (res) => {
        setSpinner(false);
        setSubject(res.data);
        setLabel(res.data?.data[0].class.name);
      }
    );
  };

  // eslint-disable-next-line
  const debouncedChangeHandler = useCallback(
    debounce((event) => {
      setSearch(event?.target?.value);
      setPage(1);
    }, 500)
  );
  //function for delete subject
  const deletSubject = (sid) => {
    setSpinner(true);
    http(
      {
        method: "DELETE",
        url: `class/${id}/subject/${sid}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setSubject((prevItem) => {
          return {
            ...prevItem,
            data: prevItem?.data?.filter((item) => item.id !== sid),
          };
        });
        setIsModalVisible({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  //function of active and inactive status of scubject

  const statusOfSubject = (sid, isChecked) => {
    setSpinner(true);
    http(
      {
        method: "PATCH",
        url: `class/${id}/subject/${sid}/activeInactive`,
        isSecure: true,
        body: {
          action: `${isChecked ? ACTIVE : IN_ACTIVE}`,
        },
      },
      (res) => {
        setSpinner(false);
        setSubject((prevalues) => ({
          ...prevalues,
          data: prevalues.data.map((item) => {
            if (item.id === sid) {
              item.is_active = isChecked ? 1 : 0;
            }
            return item;
          }),
        }));
        toast.success(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  //get subject list
  useEffect(() => {
    getSubjectDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSearch, page]);

  return (
    <>
      <div className=" user_details classDetails_dash px-0">
        <div className="sup_history">
          <div className="scale_data_header">
            <BreadCrumb
              links={[...(breadCrumbs.state ? breadCrumbs.state : "")]}
            />

            <div className="search-box">
              <SearchForm name="search" onChange={debouncedChangeHandler} />
              <div className="data_add">
                <Link to="add" className="btn sub-btn btn-primary">
                  Add Subject
                </Link>
              </div>
            </div>
          </div>
          <div className="kina_tables table-responsive">
            <table className="dash_tables">
              <thead>
                <tr>
                  <th className="SnumData">S.No.</th>
                  <th>Subject Name</th>
                  <th>School Name</th>
                  <th>Exams</th>
                  <th className="activeTh">Active / Inactive</th>
                  <th className="pricingTh">Action</th>
                </tr>
              </thead>
              <tbody>
                {subject.data?.length ? (
                  subject.data.map((item, index) => (
                    <tr key={index}>
                      <td>{subject.from + index}</td>
                      <td>
                        <span
                          onClick={() => {
                            navigate(`subject/${item.id}/chapter`, {
                              state: [
                                ...breadCrumbs.state,
                                {
                                  label: item.name,
                                  url: window.location.pathname,
                                },
                              ],
                            });
                          }}
                          style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                        >
                          {item.name}
                        </span>
                      </td>
                      <td>{item.school.name}</td>
                      <td>
                        {" "}
                        <button
                          onClick={() => {
                            navigate(`subject/${item.id}/exams`, {
                              state: [
                                ...breadCrumbs.state,
                                {
                                  label: item.name,
                                  url: window.location.pathname,
                                },
                              ],
                            });
                          }}
                          className="m-0 text-white w-auto small-btn sub-btn"
                        >
                          Exams
                        </button>
                      </td>
                      <td>
                        <ToggleBtn
                          onchange={(e) =>
                            statusOfSubject(item.id, e.target.checked)
                          }
                          checked={item.is_active === 1}
                        />
                      </td>
                      <td>
                        <ul>
                          <li>
                            <NavLink to={`edit/${item.id}`}>
                              <img src={penImg} alt="Edit" />
                            </NavLink>
                          </li>
                          <li>
                            <img
                              src={binImg}
                              alt="binImg"
                              onClick={() => {
                                setIsModalVisible({
                                  isShow: true,
                                  id: item.id,
                                });
                              }}
                            />
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="error01">
                      Data Not Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* Delete confirm modal section start */}{" "}
        {isModalVisible.isShow ? (
          <Modal
            show={isModalVisible}
            onHide={() => setIsModalVisible(false)}
            className="delete-modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>Do you want to delete?</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setIsModalVisible(false)}
              >
                No
              </Button>
              <Button
                variant="primary"
                onClick={() => deletSubject(isModalVisible.id)}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ""
        )}
        {/* Delete confirm modal section end */}
        {subject?.total > 0 && subject?.total > subject?.per_page && (
          <PagePagination
            className="pagination-bar"
            currentPage={subject?.current_page}
            totalCount={
              subject?.last_page !== undefined
                ? subject?.total
                : subject?.length
            }
            pageSize={size}
            onPageChange={(page) => setPage(page)}
          />
        )}
      </div>
    </>
  );
}
export default ClassDetails;
