import React from "react";

function InputBox({
  id,
  label,
  classname,
  name,
  onChange,
  placeholder,
  value,
  type,
  error,
  required,
  defaultValue,
  appEnd,
  classlable,
  disabled
}) {

  return (
    <div className="form-group position-relative">
      <label className={`form-label ${classlable || ""}`} htmlFor={id}>
        {label} {required && <span className="required">*</span>}
      </label>

      <input
        type={type}
        className={`text-input ${classname || ""}`}
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        autoComplete="off"
        defaultValue={defaultValue}
        disabled={disabled}
      />
      {appEnd && <div>{appEnd}</div>}

      {error && <span className="error"> {error}</span>}
    </div>
  );
}

export default InputBox;
