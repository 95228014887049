import React from "react";

const UserDetails = ({
  userName,
  className,
  email,
  phoneNo,
  schoolName,
  img,
}) => {
  return (
    <div className="user_card">
      <div className="user_profile">
        <img src={img} alt="User" className="user_profile_sub" />
      </div>
      <ul className="user_lists">
        <li>
          <div className="detail_sec">
            <p className="user_name_th"> Name</p>
            <h3 className="user_name_td">{userName}</h3>
          </div>
        </li>
        <li>
          <div className="detail_sec">
            <p className="user_name_th">Class</p>
            <h3 className="user_name_td">{className}</h3>
          </div>
        </li>
        <li>
          <div className="detail_sec">
            <p className="user_name_th">School</p>
            <h3 className="user_name_td">{schoolName}</h3>
          </div>
        </li>
        <li>
          <div className="detail_sec">
            <p className="user_name_th">Phone No</p>
            <h3 className="user_name_td">{phoneNo}</h3>
          </div>
        </li>
        <li>
          <div className="detail_sec">
            <p className="user_name_th">Email Id</p>
            <h3 className="user_name_td">{email}</h3>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default UserDetails;
