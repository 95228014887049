import React from "react";

import OwlCarousel from "react-owl-carousel";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import BookNVideoCard from "./BookNVideoCard";

class Slider extends React.Component {
  state = {};

  componentDidUpdate(prevProps, nextProps) {
    if (prevProps !== this.props) {
      this.setState(this.props);
    }
  }

  // shouldComponentUpdate(props) {
  //   return this.props.title !== props.title
  // }

  render() {
    return (
      this.props.data?.length > 0 && (
        <div className="data_body_cards cards01">
          <div className="data_card_head border-bottom">
            <div className="data_card_heading">
              <h1 className=" mb-0">
                <span className="bg-white">{this.props.title}</span>
              </h1>
            </div>
          </div>
          {/* Digital Electronic section start */}
          <div className="main_slider_box">
            {this.props.data?.length > 0 ? (
              <OwlCarousel
                className="owl-theme "
                {...this.props.options}
                margin={10}
                nav
              >
                {this.props.data?.map((book, index) => {
                  return (
                    <BookNVideoCard
                      key={book.id + index}
                      bookId={book.id}
                      type={this.props.type}
                      image={process.env.REACT_APP_MEDIA_URL + book.cover_image}
                      video={process.env.REACT_APP_VIDEO_URL + book.file}
                      name={book.name}
                      price={book.price}
                      onClick={this.props.onClick}
                    />
                  );
                })}
              </OwlCarousel>
            ) : (
              <div>
                <p>No videos</p>
              </div>
            )}
          </div>
          {/* Digital Electronic section end */}
        </div>
      )
    );
  }
}

export default Slider;
