import React, { useState, useContext, useCallback, useEffect } from "react";

import { toast } from "react-toastify";

import PagePagination from "../../../Utils/pagination/PagePagination";
import { BLOCK, UNBLOCK } from "../../../../utilities/constants";
import { AppContext } from "../../../context/AppContext";
import { http } from "../../../../services/http";
import SearchForm from "../../../Utils/forms/SearchFrorm";
import debounce from "../../../../utilities/debounce";

function OtherUser() {
  const [page, setPage] = useState();
  const [getSearch, setSearch] = useState("");
  const { setSpinner, size } = useContext(AppContext);
  const [userList, SetUserList] = useState([]);
  const statusOfUser = (id, isChecked) => {
    setSpinner(true);
    http(
      {
        method: "PATCH",
        url: `otheruser/${id}/blockUnblock`,
        isSecure: true,
        body: {
          action: `${!isChecked ? UNBLOCK : BLOCK}`,
        },
      },
      (res) => {
        setSpinner(false);
        SetUserList((prevUser) => ({
          ...prevUser,
          data: prevUser.data.map((item) => {
            if (item.id === id) {
              item.is_active = isChecked ? 0 : 1;
            }
            return item;
          }),
        }));
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  //get user Listing
  const getuserList = () => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: size,
      filter: "otherusers",
    };
    if (getSearch) {
      params.search = getSearch;
    }

    http(
      {
        method: "GET",
        url: `user?${new URLSearchParams(params)}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        SetUserList(res.data);
      }
    );
  };
  // useCallback(
  //   ({ page, search }) => {
  //     setSpinner(true);
  //     http(
  //       {
  //         method: "GET",
  //         url: `user?page=${page ? page : 1}&size=10&filter=otherusers${search ? "&search=" + search : ""}`,
  //         isSecure: true,
  //       },
  //       (res) => {
  //         setSpinner(false);
  //         SetUserList(res.data);
  //       }
  //     );
  //   },
  //   [setSpinner]
  // );

  // eslint-disable-next-line
  const debouncedChangeHandler = useCallback(
    debounce((event) => {
      setSearch(event?.target?.value);
      setPage(1);
    }, 500)
  );

  // get Teacher lisiting
  useEffect(() => {
    getuserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, getSearch]);

  return (
    <div className="teacher-dashboard">
      <div className="col-md-12 ">
        <div className="sup_history">
          <div className="scale_data_header">
            <h2 className="class_head">Other Users</h2>
            <div className="search-box">
              <SearchForm onChange={debouncedChangeHandler} />
            </div>
          </div>
          <div className="kina_tables table-responsive">
            <table className="dash_tables">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Name</th>
                  <th>Phone No</th>
                  <th className="w-20">Email Id</th>
                  <th>Classification</th>
                  <th>Country</th>
                  <th>Region or state</th>
                  <th className="pricingTh">Action</th>
                </tr>
              </thead>
              <tbody>
                {userList?.data?.length ? (
                  userList?.data?.map((userinfo, index) => (
                    <tr key={userinfo.id}>
                      <td className="text-nowrap">{userList.from + index}</td>
                      <td className="text-nowrap">
                        {userinfo?.name ? userinfo?.name : "N/A"}
                      </td>
                      <td className="text-nowrap">
                        {userinfo?.phone_number
                          ? userinfo?.phone_number
                          : "N/A"}
                      </td>
                      <td className="w-20">{userinfo?.email}</td>
                      <td>
                        {userinfo?.other_user_classification?.name
                          ? userinfo?.other_user_classification?.name
                          : "N/A"}
                      </td>
                      <td>{userinfo.country ? userinfo.country : "N/A"}</td>
                      <td>{userinfo.state ? userinfo.state : "N/A"}</td>
                      <td className="p-0">
                        <div className="block_unblock">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={userinfo.is_active === 0}
                              onChange={(e) =>
                                statusOfUser(userinfo.id, e.target.checked)
                              }
                            />
                            <span className="slider round widunset"></span>
                          </label>
                          <label className="custom_marg_left">
                            {userinfo.is_active === 0 ? (
                              <p className="text-danger mb-0">Block</p>
                            ) : (
                              "Unblock"
                            )}
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="error01">
                      Data Not Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {userList?.total > 0 && userList?.total > userList?.per_page && (
        <PagePagination
          className="pagination-bar"
          currentPage={userList?.current_page}
          totalCount={
            userList?.last_page !== undefined
              ? userList?.total
              : userList?.length
          }
          pageSize={size}
          onPageChange={(page) => setPage(page)}
        />
      )}
    </div>
  );
}
export default OtherUser;
