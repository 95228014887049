import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, patterns } from "@kaydhiman/react-hook-useform";
import { AppContext } from "../../../../context/AppContext";
import { toast } from "react-toastify";
import { http } from "../../../../../services/http";
import Auth from "../../../../../Auth/Auth";

import InputBox from "../../../../Utils/forms/InputBox";
import CustomButton from "../../../../Utils/forms/CustomButton";

import PrefImg from "../../../../../assets/images/prev_ico.png";
import UploadProfile from "../../../../Utils/widgets/UploadProfile";

function SchoolForm() {
  //states here
  const [profileImage, setProfileImage] = useState();
  const { setSpinner } = useContext(AppContext);
  const navigate = useNavigate();

  const { id } = useParams();

  // validations code here

  const { values, errors, bindField, isValid, setInitialValues } = useForm({
    validations: {
      email: {
        pattern: {
          value: patterns.email,
          message: "Invalid email address.",
        },
        required: true,
      },
      phone_number: {
        required: true,
        minLength: {
          value: 10,
          message: "Enter Minimum 10 digits",
        },
        maxLength: {
          value: 10,
          message: "Enter Maximum 10 digits",
        },
        pattern: {
          value: patterns.onlyNumber,
          message: "Enter valid phone no.",
        },
      },
      name: {
        required: true,
      },
      location: {
        required: true,
      },
      contact_person: {},
    },
  });

  const handleUploadImg = (e) => {
    setProfileImage({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  const handleImage = async (e) => {
    e.preventDefault();
    if (!profileImage.file) {
      addSchool();
    } else {
      const formData = new FormData();
      formData.append("type", "photo");
      formData.append("file", profileImage.file);
      setSpinner(true);
      await http(
        {
          method: "POST",
          url: `upload-image`,
          isSecure: true,
          contentType: "multipart/form-data",
          body: formData,
        },
        (res) => {
          setSpinner(false);
          addSchool(res.content.imageName);
        },
        (res) => {
          setSpinner(false);
          toast.error(res.message, {
            autoClose: 3000,
          });
        }
      );
    }
  };

  //function to add school
  const addSchool = async (img) => {
    setSpinner(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/school/${id ? id : ""}`,
        {
          method: id ? "PUT" : "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Auth.token()}`,
          },
          body: JSON.stringify({
            ...values,
            ...(img ? { file: img } : ""),
          }),
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        navigate("/admin/school");
        toast.success(data.message, {
          autoClose: 3000,
        });
      }

      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        toast.error(data.message, {
          autoClose: 3000,
        });
      }
      setSpinner(false);
    } catch (err) {
      console.error(err);
    }
  };

  //get the data

  const getSchoolDetails = useCallback(() => {
    http(
      {
        method: "GET",
        url: `school/${id}`,
        isSecure: true,
      },
      (res) => {
        setInitialValues(res.data);
        setProfileImage({
          url: process.env.REACT_APP_MEDIA_URL + res.data.file,
        });
      }
    );
  }, [setInitialValues, id]);

  // Google Place API Method

  const placeInputRef = useRef(null);

  const initPlaceAPI = useCallback(() => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      placeInputRef.current
    );

    new window.google.maps.event.addListener(
      autocomplete,
      "place_changed",
      function () {
        let place = autocomplete.getPlace();

        if (place.geometry) {
          setInitialValues((prevValues) => ({
            ...prevValues,
            lat: place.geometry.location.lat(),
            long: place.geometry.location.lng(),
            location: place.formatted_address,
          }));
        }
      }
    );
  }, [setInitialValues]);

  useEffect(() => {
    initPlaceAPI();
  }, [initPlaceAPI]);

  useEffect(() => {
    id && getSchoolDetails();
  }, [getSchoolDetails, id]);

  return (
    <div className="update_user add_skool row">
      <div className="col-md-12">
        <div className="sup_history">
          <div className="scale_data_header">
            <h2 className="class_head">
              <Link to="/admin/school" className="prev_btn">
                <span className="backBtn">
                  <img className="backPg" src={PrefImg} alt="Back button" />
                </span>
              </Link>
              {`${id ? "Edit" : "Add"}`} School
            </h2>
          </div>
          <div className="data_add_form admin-form">
            <form onSubmit={handleImage}>
              <div className="row">
                <div className="col-lg-3">
                  <UploadProfile
                    picture={profileImage}
                    label="Upload Profile Photo"
                    required="true"
                    {...bindField("file")}
                    error={errors.file}
                    onChange={handleUploadImg}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-3 col-sm-4">
                  <InputBox
                    label="School Name"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter school name"
                    classname="form-control"
                    {...bindField("name")}
                    error={errors.name}
                    required="true"
                  />
                </div>
                <div className="col-lg-3 col-sm-4">
                  <label className="form-label" htmlFor="location">
                    Location <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="location"
                    name="location"
                    placeholder="Enter location"
                    className="form-control text-input"
                    ref={placeInputRef}
                    {...bindField("location")}
                    error={errors.location}
                    required={true}
                  />
                  {errors.location && (
                    <span className="error"> {errors.location}</span>
                  )}
                </div>
                <div className="col-lg-3 col-sm-4">
                  <InputBox
                    label="Email"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter email"
                    classname="form-control"
                    {...bindField("email")}
                    error={errors.email}
                    required="true"
                  />
                </div>
                <div className="col-lg-3 col-sm-4">
                  <InputBox
                    label="Phone Number"
                    type="text"
                    id="pNum"
                    name="phone_number"
                    placeholder="Enter phone number"
                    classname="form-control "
                    {...bindField("phone_number")}
                    error={errors.phone_number}
                    required="true"
                  />
                </div>
                <div className="col-lg-3 col-sm-4">
                  <InputBox
                    label="Contact Person"
                    type="text"
                    id="cPerson"
                    placeholder="Enter contact person"
                    classname="form-control"
                    {...bindField("contact_person")}
                    error={errors.contact_person}
                  />
                </div>
              </div>
              <div className="addon_btn row">
                <div className="col-md-3">
                  <CustomButton
                    text="Submit"
                    type="submit"
                    classname="sub-btn"
                    disabled={!isValid() || !profileImage?.url}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SchoolForm;
