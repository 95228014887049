import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";

import { http } from "../../../../services/http";
import { AppContext } from "../../../context/AppContext";

import binImg from "../../../../assets//images/trash.png";
import ShortIcon from "../../../../assets/images/sorting.png";
import penImg from "../../../../assets//images/edit_action.png";

function SchoolAdminDashboard() {
  const [teacherList, setTeacherList] = useState({});
  const [studentList, setStudentList] = useState({});
  const { setSpinner } = useContext(AppContext);
  const [isAscending, setIsAscending] = useState();
  const [isAscendingStudnet, setIsAscendingStudnet] = useState();

  const [totalStudents, setTotalStudents] = useState({});
  const [totalTeachers, setTotalTeachers] = useState({});

  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    id: "",
  });

  const [stuModal, setStuModal] = useState({
    isShow: false,
    id: "",
  });

  // Student list item delete function

  const deleteStudent = (id) => {
    http(
      {
        method: "DELETE",
        url: `student/${id}`,
        isSecure: true,
      },
      (res) => {
        setStudentList((prevData) => {
          return {
            ...prevData,
            data: prevData.data?.filter((items) => items.student_id !== id),
          };
        });
        setStuModal({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  // teacher list item delete function

  const deleteTeacher = (id) => {
    http(
      {
        method: "DELETE",
        url: `teacher/${id}`,
        isSecure: true,
      },
      (res) => {
        setTeacherList((prevData) => {
          return {
            ...prevData,
            data: prevData.data?.filter((items) => items.teacher_id !== id),
          };
        });
        setIsModalVisible({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  //for sort Teacher
  const sortTeacherData = () => {
    setTeacherList((prevData) => ({
      ...prevData,
      data: prevData.data?.sort((item1, item2) => {
        if (item1.teacher.user.name < item2.teacher.user.name) {
          return isAscending ? -1 : 1;
        }
        if (item1.teacher.user.name > item2.teacher.user.name) {
          return isAscending ? 1 : -1;
        }
        // names must be equal
        return 0;
      }),
    }));

    setIsAscending((prev) => !prev);
  };

  //for sort studnet
  const sortStudentData = () => {
    setStudentList((prevData) => ({
      ...prevData,
      data: prevData.data?.sort((item1, item2) => {
        if (item1.student.user.name < item2.student.user.name) {
          return isAscendingStudnet ? -1 : 1;
        }
        if (item1.student.user.name > item2.student.user.name) {
          return isAscendingStudnet ? 1 : -1;
        }

        // names must be equal
        return 0;
      }),
    }));

    setIsAscendingStudnet((prev) => !prev);
  };

  // get Teacher lisiting
  useEffect(() => {
    setSpinner(true);
    http(
      {
        method: "GET",
        url: "teacher?page=1&size=8",
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setTeacherList(res.data);
        setTotalTeachers(res);
      }
    );
  }, [setTeacherList, setSpinner]);

  // get Student lisiting
  useEffect(() => {
    setSpinner(true);
    http(
      {
        method: "GET",
        url: "student?page=1&size=8",
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setStudentList(res.data);
        setTotalStudents(res);
      }
    );
  }, [setStudentList, setSpinner]);

  // Student list item delete function end

  return (
    <div className="school-dashboard row">
      <div className="dash_body_head col-md-12">
        <h2 className="class_head">Dashboard</h2>
      </div>
      <div className="col-md-12">
        <div className="total_count">
          <ul className="user_lists">
            <li>
              <div className="detail_sec">
                <p className="user_name_th">Total Teachers</p>
                <h3 className="user_name_td">
                  {totalTeachers?.total_teachers}
                </h3>
              </div>
            </li>
            <li>
              <div className="detail_sec">
                <p className="user_class_th">Total Students</p>
                <h3 className="user_class_td">
                  {totalStudents?.total_students}
                </h3>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12 col-12 pe-5">
        <div className="school_history">
          <div className="scale_data_header table_heading">
            <h2 className="class_head">Teachers</h2>
            <div className="view_all">
              <Link to="/school/teachers">View All</Link>
            </div>
          </div>
          <div className="kina_tables table-responsive">
            <table className="dash_tables w-100">
              <thead>
                <tr>
                  <th className="SnumData">S.No.</th>
                  <th>
                    Teacher Name &nbsp;
                    <img
                      src={ShortIcon}
                      alt="sorting icon"
                      className="hand"
                      onClick={sortTeacherData}
                    />
                  </th>
                  <th>Subject</th>
                  <th className="pricingTh">Action</th>
                </tr>
              </thead>
              <tbody>
                {/* Teacher Table section start */}

                {teacherList?.data?.length ? (
                  teacherList?.data?.map((item, index) => (
                    <tr key={item.id}>
                      <td>{teacherList.from + index}</td>
                      <td>
                        <Link to={`/school/teachers/${item.teacher_id}`}>
                          {item?.teacher?.user?.name}
                        </Link>
                      </td>
                      <td>
                        {item?.teacher?.teacher_subjects
                          .map((items) => items.subject?.name)
                          .join(" , ")}
                      </td>

                      <td className="p-0">
                        <div className="d-flex justify-content-center">
                          <Link to={`/school/teachers/edit/${item.teacher_id}`}>
                            <img src={penImg} alt="Edit" />
                          </Link>
                          <img
                            src={binImg}
                            alt="Delete"
                            className="custom_marg_left"
                            onClick={() => {
                              setIsModalVisible({
                                isShow: true,
                                id: item.id,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="error01">
                      Data Not Found
                    </td>
                  </tr>
                )}

                {/* Teacher Table section end */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="school_history">
          <div className="scale_data_header table_heading">
            <h2 className="class_head">Students</h2>
            <div className="view_all">
              <Link to="/school/students">View All</Link>
            </div>
          </div>
          <div className="kina_tables table-responsive">
            <table className="dash_tables w-100">
              <thead>
                <tr>
                  <th className="SnumData">S.No.</th>
                  <th>
                    Student Name &nbsp;
                    <img
                      src={ShortIcon}
                      alt="sorting icon"
                      className="hand"
                      onClick={sortStudentData}
                    />
                  </th>
                  <th>Classes</th>
                  <th className="pricingTh">Action</th>
                </tr>
              </thead>
              <tbody>
                {/* Student Table section start */}

                {studentList?.data?.length ? (
                  studentList?.data?.map((studentInfo, index) => (
                    <tr key={studentInfo.id}>
                      <td>{studentList.from + index}</td>
                      <td>
                        <Link to={`/school/students/${studentInfo.student_id}`}>
                          {studentInfo?.student?.user?.name}
                        </Link>
                      </td>
                      <td>{studentInfo?.student?.class?.name}</td>
                      <td className="p-0">
                        <div className="d-flex justify-content-center">
                          <Link
                            to={`/school/students/edit/${studentInfo.student_id}`}
                          >
                            <img src={penImg} alt="Edit" />
                          </Link>
                          <img
                            src={binImg}
                            alt="Delete"
                            className="custom_marg_left"
                            onClick={() => {
                              setStuModal({
                                isShow: true,
                                id: studentInfo.student_id,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="error01">
                      Data Not Found
                    </td>
                  </tr>
                )}
                {/* Student Table section start */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Delete teacher data confirm modal section start */}
      {isModalVisible.isShow ? (
        <Modal
          show={isModalVisible}
          onHide={() => setIsModalVisible(false)}
          className="delete-modal"
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Teacher Name List</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>Do you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsModalVisible(false)}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => deleteTeacher(isModalVisible.id)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        " "
      )}

      {/* Delete student dataconfirm modal section end */}
      {stuModal.isShow && (
        <Modal
          show={stuModal}
          onHide={() => setStuModal(false)}
          className="delete-modal"
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Teacher Name List</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>Do you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setStuModal(false)}>
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => deleteStudent(stuModal.id)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Delete student dataconfirm modal section end */}
    </div>
  );
}
export default SchoolAdminDashboard;
