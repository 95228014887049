import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { http } from "../../../../services/http";
import { AppContext } from "../../../context/AppContext";

import PrefImg from "../../../../assets/images/prev_ico.png";
import UserDp from "../../../../assets/images/student_detail.png";

function TeacherDetails() {
  const { setSpinner } = useContext(AppContext);
  const [teacherInfo, setTeacherInfo] = useState({});

  const { teacherId } = useParams();

  const subjectsDropDownOpner = (id) => {
    document.querySelectorAll(".subjectListing_section").forEach((elm) => {
      if (elm.id !== `teacher-${id}`) {
        elm.classList.add("d-none");
      }
    });
    document.getElementById(`teacher-${id}`).classList.toggle("d-none");
  };

  // get Teacher lisiting
  useEffect(() => {
    setSpinner(true);
    http(
      {
        method: "GET",
        url: `teacher/${teacherId}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setTeacherInfo(res.data);
      }
    );
  }, [setTeacherInfo, setSpinner, teacherId]);

  return (
    <div className="user_details">
      <div className="col-md-12 ">
        <div className="sup_history teacher_details_section">
          <div className="scale_data_header">
            <h2 className="class_head">
              <Link to="/school/teachers" className="prev_btn">
                <span className="backBtn">
                  <img className="backPg" src={PrefImg} alt="Back button" />
                </span>
              </Link>
              Teacher Details
            </h2>
          </div>
          <div className="user_card">
            <div className="">
              <img
                src={
                  teacherInfo.file
                    ? process.env.REACT_APP_MEDIA_URL + teacherInfo.file
                    : UserDp
                }
                alt="User"
                className="user_profile_sub"
              />
            </div>
            <ul className="user_lists skool_list">
              <li>
                <div className="detail_sec">
                  <p className="user_name_th">Name</p>
                  <h3 className="user_name_td">
                    {/* {location?.state?.teacher_name} */}
                    {teacherInfo?.user?.name}
                  </h3>
                </div>
              </li>
              <li>
                <div className="detail_sec">
                  <p className="user_name_th">Subjects</p>
                  <div className="teacherSubjectListing pt-2">
                    <button
                      className="teacher_subj_btn"
                      onClick={() =>
                        subjectsDropDownOpner(teacherInfo.teacher_id)
                      }
                    >
                      Subjects
                    </button>
                    <ul
                      id={`teacher-${teacherInfo.teacher_id}`}
                      className="teacher_subj_section d-none"
                    >
                      {teacherInfo?.teacher_subjects?.map((subjInfo) => (
                        <li
                          className="teacher_subj_section_text"
                          key={subjInfo?.subject?.id}
                        >
                          {subjInfo?.subject?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div className="detail_sec">
                  <p className="user_name_th">Email Id</p>
                  <h3 className="user_name_td">{teacherInfo?.user?.email}</h3>
                </div>
              </li>
              <li>
                <div className="detail_sec">
                  <p className="user_name_th text-nowrap">Phone Number</p>
                  <h3 className="user_name_td">
                    {teacherInfo?.user?.phone_number}
                  </h3>
                </div>
              </li>
              <li>
                <div className="detail_sec">
                  <p className="user_name_th">Roll No</p>
                  <h3 className="user_name_td">{teacherInfo?.roll_number}</h3>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TeacherDetails;
