import React from "react";

function CustomButton({
  type = "button",
  text,
  classname,
  disabled,
  onclick,
  title,
}) {
  return (
    <button
      type={type}
      disabled={disabled}
      className={`btn btn-primary ${classname || ""}`}
      onClick={onclick}
      title={title}
    >
      {text}
    </button>
  );
}

export default CustomButton;
