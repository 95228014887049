import React from "react";
function InputField({ value, type, className, onChange, placeholder, name, id }) {
    return (
        <input
            id={id}
            type={type}
            value={value}
            className={className}
            placeholder={placeholder}
            onChange={onChange}
            name={name}
        />
    )
}
export default InputField;