import React, { useState, useContext, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import PagePagination from "../../../Utils/pagination/PagePagination";
import BreadCrumb from "../../../../form-widgets/BreadCrumb";
import binImg from "../../../../assets//images/trash.png";
import penImg from "../../../../assets//images/edit_action.png";
import { AppContext } from "../../../context/AppContext";
import { http } from "../../../../services/http";
import { toast } from "react-toastify";
import Accordian from "../../../Utils/widgets/Accordian";

function ExamSubjectDetails() {
  const location = useLocation();
  const { id, subId, examId } = useParams();
  const [examQuestionList, setExamQuestionList] = useState([]);
  const [page, setPage] = useState();
  const breadCrumbs = useLocation();
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    modalId: "",
  });
  const { setSpinner, size } = useContext(AppContext);

  // Delete handle function section end
  const deleteExamQuestion = (modalId) => {
    http(
      {
        method: "DELETE",
        url: `class/${id}/subject/${subId}/exam/${examId}/question/${modalId}`,
        isSecure: true,
      },
      (res) => {
        setExamQuestionList((prevData) => ({
          ...prevData,
          data: prevData.data?.filter((items) => items.id !== modalId),
        }));
        setIsModalVisible({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  //exam question listing
  const getExamQuestionList = () => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: size,
    };

    http(
      {
        method: "GET",
        url: `class/${id}/subject/${subId}/exam/${examId}/question?${new URLSearchParams(
          params
        )}`,
        isSecure: true,
      },
      (res) => {
        setExamQuestionList(res.data);
        setSpinner(false);
      }
    );
  };
  // useCallback(
  //   ({ page }) => {
  //     setSpinner(true);
  //     http(
  //       {
  //         method: "GET",
  //         url: `class/${id}/subject/${subId}/exam/${examId}/question?page=${
  //           page ? page : 1
  //         }&size=10`,
  //         isSecure: true,
  //       },
  //       (res) => {
  //         setSpinner(false);
  //         setExamQuestionList(res.data);
  //         setLabels({
  //           first: res?.data?.data[0]?.subject?.name,
  //           second: res?.data?.data[0]?.exam?.name,
  //         });
  //       }
  //     );
  //   },
  //   [setSpinner, examId, id, subId]
  // );

  // Custom Accordian handle function end

  useEffect(() => {
    getExamQuestionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <div className=" user_details prepration_dash px-0">
        <div className="sup_history">
          <div className="scale_data_header">
            <BreadCrumb links={[...breadCrumbs.state]} />

            <div className="search-box">
              <div className="data_add">
                <Link
                  to="question/add"
                  className="btn sub-btn btn-primary"
                  state={location.state}
                >
                  Add Questions
                </Link>
              </div>
            </div>
          </div>
          <div className="kina_tables custom_exam_table table-responsive">
            <table className="dash_tables">
              <thead>
                <tr>
                  <th className="SnumData">S.No.</th>
                  <th className="questionTh">Questions</th>
                  <th className="pricingTh">Action</th>
                </tr>
              </thead>
              <tbody>
                {examQuestionList?.data?.length ? (
                  examQuestionList?.data?.map((item, index) => (
                    <tr key={index}>
                      <td>{examQuestionList.from + index}</td>
                      <td>
                        <Accordian
                          question={item.question}
                          id={item.id}
                          Data={item?.answers}
                        />
                      </td>
                      <td>
                        <ul>
                          <li>
                            <Link to={`question/${item.id}/edit`}>
                              <img src={penImg} alt="penImg" />
                            </Link>
                          </li>
                          <li>
                            <img
                              src={binImg}
                              alt="Delete"
                              onClick={() => {
                                setIsModalVisible({
                                  isShow: true,
                                  modalId: item.id,
                                });
                              }}
                            />
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="error01">
                      Data Not Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {examQuestionList?.total > 0 &&
            examQuestionList?.total > examQuestionList?.per_page && (
              <PagePagination
                className="pagination-bar"
                currentPage={examQuestionList?.current_page}
                totalCount={
                  examQuestionList?.last_page !== undefined
                    ? examQuestionList?.total
                    : examQuestionList?.length
                }
                pageSize={size}
                onPageChange={(page) => setPage(page)}
              />
            )}
        </div>

        {/* Delete confirm modal section start */}

        {isModalVisible.isShow && (
          <Modal
            show={isModalVisible}
            onHide={() => setIsModalVisible(false)}
            className="delete-modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>Do you want to delete?</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setIsModalVisible(false)}
              >
                No
              </Button>
              <Button
                variant="primary"
                onClick={() => deleteExamQuestion(isModalVisible.modalId)}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {/* Delete confirm modal section end */}
      </div>
    </>
  );
}
export default ExamSubjectDetails;
