import React, { useState, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import PLayBtn from "../../../assets/images/play-btn.svg";
import ImgDelete from "../../../assets/images/imgDelete.svg";
import { http } from "../../../services/http";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const BookNVideoCard = ({
  type = "image",
  image,
  name,
  price,
  video,
  bookId,
}) => {
  const [showModal, setShowModal] = useState();
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    id: "",
  });
  const navigate = useNavigate();
  const { setSpinner } = useContext(AppContext);
  const handleDeleteBookNVideo = (id) => {
    setSpinner(true);
    http(
      {
        method: "DELETE",
        url: `${type === "video" ? "video" : "book"}/${id}`,
        isSecure: true,
      },
      (res) => {
        setIsModalVisible({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });
        setSpinner(false);
        navigate(0);
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };
  const renderTooltip = (props) => (
    <Tooltip style={{ fontSize: "12px" }} {...props}>
      {name}
    </Tooltip>
  );

  // <Tooltip {...props}>{props.name}</Tooltip>;

  return (
    <div className="slider_box">
      <div className="video_cards">
        <img src={image} alt={name} />
        {type === "video" && (
          <span className="play-vid">
            <img
              src={PLayBtn}
              className="vid_imgOverlay"
              alt=" PLay Btn"
              onClick={() => setShowModal(true)}
            />
          </span>
        )}
      </div>
      <div className="row">
        <div className="card_description col-10">
          <OverlayTrigger placement="top" overlay={renderTooltip(name)}>
            <p className="cardName">{name}</p>
          </OverlayTrigger>
          <p className="cardPricing">{price ? "$ " + price : "Free"} </p>
        </div>
        <div className="closeData col-2">
          <img
            src={ImgDelete}
            alt="delete"
            onClick={() => setIsModalVisible({ isShow: true, id: bookId })}
            className="pointer"
          />
        </div>
      </div>
      {showModal && (
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title className="video_title_name">{name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <video width="100%" height="100%" controls type="file">
              <source src={video} type="video/mp4" />
            </video>
          </Modal.Body>
        </Modal>
      )}

      {/* Delete confirm modal section start */}
      {isModalVisible.isShow ? (
        <Modal
          show={isModalVisible.isShow}
          onHide={() => setIsModalVisible(false)}
          className="delete-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Do you want to delete this book?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsModalVisible(false)}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => handleDeleteBookNVideo(isModalVisible.id)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
      {/* Delete confirm modal section end */}
    </div>
  );
};

export default BookNVideoCard;
