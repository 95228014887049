import React, { useState, useEffect, useContext, useCallback } from "react";
// eslint-disable-next-line no-unused-vars
import { useForm, patterns } from "@kaydhiman/react-hook-useform";
import { Modal, Button, DropdownButton, Dropdown } from "react-bootstrap";
import PagePagination from "../../../Utils/pagination/PagePagination";
import binImg from "../../../../assets/images/trash.png";
import debounce from "../../../../utilities/debounce";
import CustomButton from "../../../Utils/forms/CustomButton";
import { AppContext } from "../../../context/AppContext";
import { toast } from "react-toastify";
import { http } from "../../../../services/http";
import InputBox from "../../../Utils/forms/InputBox";
import moment from "moment/moment";
import SearchForm from "../../../Utils/forms/SearchFrorm";

function PushNotifications() {
  const [classList, setClassList] = useState();
  const [userRole, setUserRole] = useState([]);
  const [getUserRole, setUsersRole] = useState([]);
  const [getNotification, setNotification] = useState();
  const [schoolList, setSchoolList] = useState();
  const localTime = moment.utc();
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    id: "",
  });
  const [page, setPage] = useState();
  const [getSearch, setSearch] = useState("");
  const [expanded, setExpand] = useState(false);
  const { setSpinner } = useContext(AppContext);
  const addNotification = (e) => {
    e.preventDefault();
    http(
      {
        method: "POST",
        url: "notification",
        isSecure: true,
        body: {
          ...values,
          sent_by: "superAdmin",
          role: document.getElementById("checkinput").checked
            ? null
            : getUserRole,
          ...(values.message && { message: values.message }),
        },
      },
      (res) => {
        setNotification((prevData) => {
          let data;
          if (values.id) {
            data = prevData.data.map((notf) => {
              if (notf.id === values.id) {
                notf = res.data;
              }
              return notf;
            });
          } else {
            if (prevData.last_page === 1 && prevData.data.length < 4) {
              data = [...prevData.data, res.data];
            } else {
              data = prevData.data;
            }
          }
          return {
            ...prevData,
            data,
            ...(getNotification.data.length === 4 &&
              getNotification.last_page === 1 && {
                last_page: getNotification.last_page + 1,
                total: getNotification.total + 1,
              }),
          };
        });
        toast.success(res.message, {
          autoClose: 3000,
        });
        getNotifications();
        setInitialValues({});
        for (
          let j = 0;
          j < document.getElementsByName("checkmark").length;
          j++
        ) {
          if (document.getElementsByName("checkmark")[j].type === "checkbox")
            document.getElementsByName("checkmark")[j].checked = false;
        }
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };
  function showCheckboxes() {
    let checkboxes = document.getElementById("checkboxes");
    const element = document.getElementById("overSelect");
    element.classList.toggle("focus");
    if (!expanded) {
      checkboxes.style.display = "block";
      setExpand(true);
    } else {
      checkboxes.style.display = "none";
      setExpand(false);
    }
  }
  let selectAll = () => {
    const elem = document.getElementsByName("checkmark");
    if (document.getElementById("checkinput").checked) {
      for (let i = 0; i < elem.length; i++) {
        if (elem[i].type === "checkbox") elem[i].checked = true;
      }
    } else {
      for (let j = 0; j < elem.length; j++) {
        if (elem[j].type === "checkbox") elem[j].checked = false;
      }
    }
  };

  // get checkbox id
  function getCheckedBoxes(chkboxName, id) {
    let checkboxes = document.getElementsByName(chkboxName);
    let checkboxesChecked = [];
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        checkboxesChecked.push(Number(checkboxes[i].id));
      } else if (checkboxes[i].checked < 3) {
        document.getElementById("checkinput").checked = false;
      }
    }
    if (checkboxesChecked.length > 2) {
      document.getElementById("checkinput").checked = true;
    }
    setUsersRole(checkboxesChecked);
    return checkboxesChecked.length > 0 ? checkboxesChecked : null;
  }
  // checkbox
  const multiSelect = (
    <div className="multiselectCheck">
      <div className="selectBox">
        <select className="mb-0">
          <option>Select an option</option>
        </select>
        <div
          className="overSelect pointer"
          id="overSelect"
          onClick={showCheckboxes}
        ></div>
      </div>
      <div id="checkboxes">
        <div className="checkbox-group" onClick={selectAll}>
          <label className="check-container w-100" htmlFor="checkinput">
            ALL
            <input type="checkbox" className="d-none" id="checkinput" />
            <span className="checkmark"></span>
          </label>
        </div>
        {userRole.map((role, index) => (
          <div
            key={index}
            className="checkbox-group"
            onClick={() => {
              getCheckedBoxes("checkmark", role.id);
            }}
          >
            <label className="check-container w-100" htmlFor={role.id}>
              {role.role}
              <input
                name="checkmark"
                type="checkbox"
                className="d-none"
                id={role.id}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
  const { values, errors, bindField, setInitialValues } = useForm({
    validations: {
      title: {
        required: true,
      },
      message: {
        requiered: true,
      },
    },
  });
  // console.log("get users role id", getUserRole);
  //school list
  useEffect(() => {
    http(
      {
        method: "GET",
        url: `school`,
        isSecure: true,
      },
      (res) => {
        setSchoolList(res);
      }
    );
  }, []);
  //class list
  useEffect(() => {
    http(
      {
        method: "GET",
        url: `class`,
        isSecure: true,
      },
      (res) => {
        setClassList(res);
      }
    );
  }, [setClassList]);
  //role list
  useEffect(() => {
    http(
      {
        method: "GET",
        url: `roles`,
        isSecure: true,
      },
      (res) => {
        setUserRole(res.data);
      }
    );
  }, [setUserRole]);

  //notifiacation listing
  const getNotifications = () => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: 5,
    };
    if (getSearch) {
      params.search = getSearch;
    }

    http(
      {
        method: "GET",
        url: `notification?${new URLSearchParams(params)}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setNotification(res.data);
      }
    );
  };
  // useCallback(
  //   ({ page } = {}) => {
  //     setSpinner(true);
  //     http(
  //       {
  //         method: "GET",
  //         url: `notification?page=${page || 1}&size=5`,
  //         isSecure: true,
  //       },
  //       (res) => {
  //         setSpinner(false);
  //         setNotification(res.data);
  //       }
  //     );
  //   },
  //   [setSpinner]
  // );
  // eslint-disable-next-line
  const debouncedChangeHandler = useCallback(
    debounce((event) => {
      setSearch(event?.target?.value);
      setPage(1);
    }, 500)
  );
  //delete notifaiction
  const deleteNotification = (id) => {
    http(
      {
        method: "DELETE",
        url: `notification/${id}`,
        isSecure: true,
      },
      (res) => {
        setNotification((prevData) => {
          return {
            ...prevData,
            data: prevData.data?.filter((items) => items.id !== id),
          };
        });
        setIsModalVisible({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });

        if (
          getNotification.data.length - 1 === 0 &&
          getNotification.last_page > 1
        ) {
          getNotifications();
        }
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSearch, page]);
  // console.log(userRole.role);

  return (
    <div className="teacher-dashboard update_user">
      <div className="col-md-12 ">
        <div className="sup_history">
          <div className="scale_data_header">
            <h2 className="class_head">Push Notification</h2>
          </div>
          <div className="row">
            <div className="col-xl-4">
              <h2 className="class_head  mb-5 pb-2">All Notifications</h2>
              <div className="clearboth">
                <div className="search_container">
                  <div className="search-group mb-5 w-100">
                    <SearchForm
                      name="search"
                      onChange={debouncedChangeHandler}
                    />
                  </div>

                  {getNotification?.data?.length ? (
                    getNotification?.data?.map((item, index) => {
                      const endTime = moment.utc(item.created_at);
                      let duration = moment.duration(localTime.diff(endTime));
                      const getDurationTime = duration._data;

                      return (
                        <>
                          <div
                            className="search-item d-flex justify-content-between"
                            key={index}
                          >
                            <div className="search-cap">
                              <h6 className="head6">{item.title}</h6>
                              <p className="para mb-0 mt-3">{item.message}</p>
                            </div>
                            <div className="search-act d-flex flex-column align-items-end">
                              <p>
                                {getDurationTime.days > 1 ? (
                                  <>{getDurationTime.days} days ago</>
                                ) : (
                                  <>
                                    {getDurationTime.days === 1 ? (
                                      <>{getDurationTime.days} day ago</>
                                    ) : (
                                      <>
                                        {getDurationTime.hours < 1 ? (
                                          <>
                                            {getDurationTime.minutes === 0 ? (
                                              <>Now</>
                                            ) : (
                                              <>
                                                {getDurationTime.minutes} mins
                                                ago
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {getDurationTime.hours} hrs{" "}
                                            {getDurationTime.minutes} mins ago
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </p>
                              <img
                                src={binImg}
                                alt="Delete"
                                className="custom_marg_left hand delete-icon"
                                onClick={() => {
                                  setIsModalVisible({
                                    isShow: true,
                                    id: item.id,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="midd-line"></div>
                        </>
                      );
                    })
                  ) : (
                    <div className="w-100 text-center h4">Data Not Found</div>
                  )}
                  {getNotification?.total > 0 &&
                    getNotification?.total > getNotification?.per_page && (
                      <PagePagination
                        className="pagination-bar"
                        currentPage={getNotification?.current_page}
                        totalCount={
                          getNotification?.last_page !== undefined
                            ? getNotification?.total
                            : getNotification?.length
                        }
                        pageSize={5}
                        onPageChange={(page) => setPage(page)}
                      />
                    )}
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="clearboth notiF mt70">
                <form onSubmit={addNotification}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="formgroup">
                        <InputBox
                          label="Category"
                          type="text"
                          id="Category"
                          name="Category"
                          placeholder="Enter Category"
                          classname="form-control"
                          {...bindField("title")}
                          error={errors.title}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="formgroup form-group">
                        <label htmlFor="category">User Role</label>
                        {multiSelect}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="formgroup">
                        <label htmlFor="schoolList" className="form-label">
                          School
                        </label>
                        <div className="dropdown-box w-100">
                          <DropdownButton
                            id="schoolList"
                            className={`dropdown-btn`}
                            title={
                              values?.school_data ? (
                                values?.school_data
                              ) : (
                                <span>Select School</span>
                              )
                            }
                          >
                            <div
                              className="dropdown_projects"
                              onClick={() =>
                                setInitialValues((prev) => ({
                                  ...prev,
                                  school_data: "All",
                                  schoolId: "",
                                }))
                              }
                            >
                              <Dropdown.Item title="All">
                                <span
                                  className={
                                    values?.school_data === "All"
                                      ? "class_active"
                                      : ""
                                  }
                                >
                                  All
                                </span>
                              </Dropdown.Item>
                            </div>
                            {schoolList?.data?.map((elem) => {
                              return (
                                <div
                                  key={elem?.id}
                                  className="dropdown_projects"
                                  onClick={() =>
                                    setInitialValues((prev) => ({
                                      ...prev,
                                      school_data: elem.name,
                                      school_id: elem.id,
                                    }))
                                  }
                                >
                                  <Dropdown.Item
                                    className="dropdown-status"
                                    title={elem?.school}
                                  >
                                    <span
                                      className={
                                        elem?.name === values?.school_data
                                          ? "class_active"
                                          : ""
                                      }
                                      // onClick={() => setInitialValues({ school_data: elem.school })}
                                    >
                                      {elem?.name}
                                    </span>
                                  </Dropdown.Item>
                                </div>
                              );
                            })}
                          </DropdownButton>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="formgroup">
                        <div className="col-12">
                          <label htmlFor="classList" className="form-label">
                            Class
                          </label>

                          <div className="dropdown-box w-100">
                            <DropdownButton
                              id="classList"
                              className={`dropdown-btn`}
                              title={
                                values?.class_data ? (
                                  values?.class_data
                                ) : (
                                  <span>Select Class</span>
                                )
                              }
                            >
                              {classList?.data?.map((elem) => {
                                return (
                                  <div
                                    key={elem?.id}
                                    className="dropdown_projects"
                                    onClick={() => {
                                      setInitialValues((prev) => ({
                                        ...prev,
                                        class_data: elem.name,
                                        class_id: elem.id,
                                      }));
                                    }}
                                  >
                                    <Dropdown.Item
                                      className="dropdown-status"
                                      title={elem?.class}
                                    >
                                      <span
                                        className={
                                          elem?.name === values?.class_data
                                            ? "class_active"
                                            : ""
                                        }
                                      >
                                        {elem?.name}
                                      </span>
                                    </Dropdown.Item>
                                  </div>
                                );
                              })}
                            </DropdownButton>
                          </div>
                        </div>
                        {/* <label htmlFor="category">Class</label>
                        <select className="custom-select" id="category">
                          <option selected>Select</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select> */}
                      </div>
                    </div>
                  </div>

                  <div className="formgroup mt-4">
                    <label htmlFor="textar_noti">Add Notification</label>
                    <textarea
                      id="textar_noti"
                      {...bindField("message")}
                    ></textarea>
                  </div>
                  {/* <button
                    type="submit"
                    className="btn btn-primary sub-btn subbtn380 mt-5"
                    onClick={addNotification}
                  >
                    Submit
                  </button> */}
                  <CustomButton
                    type="submit"
                    text="Submit"
                    classname="btn btn-primary sub-btn subbtn380 mt-5"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalVisible.isShow && (
        <Modal
          show={isModalVisible}
          onHide={() => setIsModalVisible(false)}
          className="delete-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Do you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsModalVisible(false)}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => deleteNotification(isModalVisible.id)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default PushNotifications;
