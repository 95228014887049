import React, { useState, useContext, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import SearchForm from "../../../Utils/forms/SearchFrorm";
import debounce from "../../../../utilities/debounce";
import PagePagination from "../../../Utils/pagination/PagePagination";
import { http } from "../../../../services/http";
import moment from "moment/moment";

function SalesHistory() {
  const [page, setPage] = useState();
  const [getSearch, setSearch] = useState("");
  const [getSaleHistoryList, setSaleHistoryList] = useState({});
  const { setSpinner, size } = useContext(AppContext);

  const handleSalesHistoryList = () => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: size,
    };
    if (getSearch) {
      params.search = getSearch;
    }

    http(
      {
        method: "GET",
        url: `book/sales-history?${new URLSearchParams(params)}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setSaleHistoryList(res.data);
      }
    );
  };
  // useCallback(
  //   ({ page, search }) => {
  //     setSpinner(true);
  //     http(
  //       {
  //         method: "GET",
  //         url: `book/sales-history?page=${page ? page : 1}&size=10${
  //           search ? "&search=" + search : ""
  //         }`,
  //         isSecure: true,
  //       },
  //       (res) => {
  //         setSpinner(false);
  //         setSaleHistoryList(res.data);
  //       }
  //     );
  //   },
  //   [setSpinner]
  // );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandlers = useCallback(
    debounce((event) => {
      setSearch(event?.target?.value);
      setPage(1);
    }, 500)
  );
  // school lisiting
  useEffect(() => {
    handleSalesHistoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSearch, page]);

  return (
    <>
      <div className="sale_history">
        <div className="col-md-12">
          <div className="sup_history">
            <div className="scale_data_header">
              <div className="scale_data_heading">
                <h2 className="class_head">Sales History</h2>
              </div>
              <div className="search-box">
                <SearchForm name="search" onChange={debouncedChangeHandlers} />
              </div>
            </div>
            <div className="salesListing kina_tables">
              <table className="dash_tables">
                <thead>
                  <tr>
                    <th className="SnumData">S.No.</th>
                    <th className="data01">User</th>
                    <th className="data02">Class</th>
                    <th className="data03">School</th>
                    <th className="data04">Book</th>
                    <th className="data05">Subject</th>
                    <th className="data06">Date and Time of Purchase</th>
                    <th className="pricingTh">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {getSaleHistoryList?.data?.length ? (
                    getSaleHistoryList?.data?.map((item, index) => {
                      const DateOfPurchase = moment
                        .utc(item.created_at)
                        .format("DD MMM YYYY");
                      const TimeOfPurchase = moment
                        .utc(item.created_at)
                        .format("hh:mm A");
                      const subjectName = item.book.subject.map(
                        (item) => item.name
                      );

                      return (
                        <tr key={item.id}>
                          <td>{getSaleHistoryList.from + index}</td>
                          <td>
                            <Link to="user-details" state={item}>
                              {item.user?.name}
                            </Link>
                          </td>
                          <td>{item.user.student?.class.name}</td>
                          <td>
                            {item.user.student?.school.school_detail.name}
                          </td>
                          <td>{item?.book?.name}</td>
                          <td>{subjectName[0]}</td>
                          <td>{`${DateOfPurchase}, ${TimeOfPurchase}`}</td>
                          <td>${item?.book?.price}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" className="error01">
                        Data Not Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {getSaleHistoryList?.total > 0 &&
          getSaleHistoryList?.total > getSaleHistoryList?.per_page && (
            <PagePagination
              className="pagination-bar"
              currentPage={getSaleHistoryList?.current_page}
              totalCount={
                getSaleHistoryList?.last_page !== undefined
                  ? getSaleHistoryList?.total
                  : getSaleHistoryList?.length
              }
              pageSize={size}
              onPageChange={(page) => setPage(page)}
            />
          )}
      </div>
    </>
  );
}
export default SalesHistory;
