import React, { useCallback, useEffect, useState, useContext } from "react";
import { Link, NavLink, useParams, useLocation } from "react-router-dom";
import PagePagination from "../../../Utils/pagination/PagePagination";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import ToggleBtn from "../../../Utils/forms/ToggleBtn";
// import Pagination from "../../../Utils/pagination/Pagination";
import { http } from "../../../../services/http";
import { Dropdown, DropdownButton } from "react-bootstrap";
import binImg from "../../../../assets/images/trash.png";
import PrefImg from "../../../../assets/images/prev_ico.png";
import penImg from "../../../../../src/assets/images/edit_action.png";
import SchoolLogo from "../../../../assets/images/schoolIcon.png";
import { AppContext } from "../../../context/AppContext";
import { ON, OFF } from "../../../../utilities/constants";
function SchoolDetail() {
  //states manage

  const [fee, setFee] = useState({});
  const [page, setPage] = useState();
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    id: "",
  });
  const { setSpinner } = useContext(AppContext);
  const params = useParams();

  const [schoolData, setSchoolData] = useState([]);
  const size = 10;
  const locationData = useLocation();
  const school_Id = locationData?.state?.id;
  // console.log("get school_Id", school_Id);
  //delete fee record
  const statusOfFee = (id, isChecked, schoolId) => {
    setSpinner(true);
    http(
      {
        method: "PATCH",
        url: `school/${
          school_Id === null || school_Id === undefined ? schoolId : school_Id
        }/fee/${id}/notifi-update`,
        isSecure: true,
        body: {
          action: `${isChecked ? ON : OFF}`,
        },
      },
      (res) => {
        setSpinner(false);

        setFee((prevState) => ({
          ...prevState,
          data: prevState.data.map((item) => {
            if (item.id === id) {
              item.is_active = isChecked ? 1 : 0;
            }
            return item;
          }),
        }));
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };
  const deleteFee = (id) => {
    http(
      {
        method: "DELETE",
        url: `school/${
          school_Id === null || school_Id === undefined ? params.id : school_Id
        }/fee/${id}`,
        isSecure: true,
      },
      (res) => {
        setFee((prevItem) => {
          return {
            ...prevItem,
            data: prevItem?.data?.filter((item) => item.id !== id),
          };
        });
        setIsModalVisible({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  //api for get school details

  useEffect(() => {
    setSpinner(true);
    http(
      {
        method: "GET",
        url: `school/${
          school_Id === null || school_Id === undefined ? params.id : school_Id
        }`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setSchoolData(res?.data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  //api for get fee list
  const getFeeList = useCallback(
    (page) => {
      http(
        {
          method: "GET",
          url: `school/${
            school_Id === null || school_Id === undefined
              ? params.id
              : school_Id
          }/fee?page=${page ? page : 1}&size=${size}`,
          isSecure: true,
        },
        (res) => {
          setFee(res.data);
        }
      );
    },
    [params.id]
  );

  useEffect(() => {
    getFeeList(page);
  }, [getFeeList, page]);

  return (
    <div className=" user_details skool_details row">
      <div className="col-md-12 ">
        <div className="sup_history">
          <div className="scale_data_header">
            <h2 className="class_head">
              <Link to="/admin/school" className="prev_btn">
                <span className="backBtn">
                  <img className="backPg" src={PrefImg} alt="Back button" />
                </span>
              </Link>
              School Details
            </h2>
          </div>
          <div className="user_card">
            <div className="skoll-detail-card d-flex w-100">
              <div className="">
                <img
                  src={
                    schoolData?.file
                      ? process.env.REACT_APP_MEDIA_URL + schoolData?.file
                      : SchoolLogo
                  }
                  alt="School logo"
                  className="user_profile_sub"
                />
              </div>
              <ul className="user_lists skool_list">
                <li>
                  <div className="detail_sec">
                    <p className="user_name_th">School</p>
                    <h3 className="user_name_td">{schoolData?.name}</h3>
                  </div>
                </li>
                <li>
                  <div className="detail_sec">
                    <p className="user_name_th">Location</p>
                    <h3 className="user_name_td">{schoolData?.location}</h3>
                  </div>
                </li>
                <li>
                  <div className="detail_sec">
                    <p className="user_name_th">Email</p>
                    <h3 className="user_name_td">{schoolData?.email}</h3>
                  </div>
                </li>
                <li>
                  <div className="detail_sec">
                    <p className="user_name_th">Phone Number</p>
                    <h3 className="user_name_td">{schoolData?.phone_number}</h3>
                  </div>
                </li>
                <li>
                  <div className="detail_sec">
                    <p className="user_name_th">Registration Date</p>
                    <h3 className="user_name_td">
                      {schoolData?.registration_date}
                    </h3>
                  </div>
                </li>
                <li>
                  <div className="detail_sec">
                    <p className="user_name_th">Contact Person</p>
                    <h3 className="user_name_td">
                      {" "}
                      {schoolData?.contact_person}
                    </h3>
                  </div>
                </li>
              </ul>
            </div>
            <div className="total_count">
              <ul className="user_lists">
                <li>
                  <div className="detail_sec">
                    <p className="user_name_th">Total Teachers</p>
                    <h3 className="user_name_td">
                      {schoolData?.totalTeachers}
                    </h3>
                  </div>
                </li>
                <li>
                  <div className="detail_sec">
                    <p className="user_class_th">Total Students</p>
                    <h3 className="user_class_td">
                      {schoolData?.totalStudents}
                    </h3>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="purcg_table">
            <div className="scale_data_header">
              <h2 className="class_head">Fees</h2>
              <div className="search-box">
                <div className="data_add">
                  <Link
                    to="add"
                    type="submit"
                    className="btn sub-btn btn-primary"
                  >
                    Add Fee
                  </Link>
                </div>
              </div>
            </div>
            <div className="kina_tables table-responsive">
              <table className="dash_tables pay_table">
                <thead>
                  <tr>
                    <th className="SnumData">S.No.</th>
                    <th>Category</th>
                    <th>Classes</th>
                    <th>Due Date</th>
                    <th>Amount</th>
                    <th>Notification</th>
                    <th className="pricingTh">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {fee.data?.length ? (
                    fee.data.map((item, index) => {
                      // const fee_due_dates = item.fee_due_dates;
                      // const getdate = fee_due_dates.map((item) => {
                      //   const date = new Date(item.due_date);
                      //   date.getUTCDate();
                      //   // return getDate.filter(
                      //   //   (item, index) => getDate.indexOf(item) === index
                      //   // );
                      // });
                      // let getDate = new Set(getdate);
                      const getDates = Object.keys(item.fee_due_dates).map(
                        (obj, i) => {
                          const fee_due_dates =
                            item.fee_due_dates[obj].due_date;
                          const date = new Date(fee_due_dates);
                          const getDate = date.getUTCDate();
                          // console.log(getDate, typeof getDate);
                          // setFeeId(item.fee_due_dates[obj].fee_id);
                          return getDate;
                        }
                      );
                      // console.log("get School id", item.school.id);
                      const getDateMonthYear = Object.keys(
                        item.fee_due_dates
                      ).map((obj, i) => {
                        const fee_due_dates = item.fee_due_dates[obj].due_date;
                        const date = new Date(fee_due_dates);
                        const get_Date = date.getDate();
                        const getMonth = date.getMonth();
                        const getYear = date.getFullYear();
                        const monthNames = [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sept",
                          "Oct",
                          "Nov",
                          "Dec",
                        ];
                        const myDateString =
                          ("0" + get_Date).slice(-2) +
                          " " +
                          monthNames[getMonth] +
                          " " +
                          getYear;
                        return myDateString;
                      });
                      const getDate = [...new Set(getDates)];
                      return (
                        <tr key={index}>
                          <td>{fee.from + index}</td>
                          <td>{item?.fee_category}</td>
                          <td>
                            {item?.class
                              .map((feeItem) => feeItem?.name)
                              .join(", ")}
                          </td>
                          <td>
                            {getDate.length !== 0 ? (
                              <div className="d-flex align-items-center">
                                <DropdownButton
                                  className="month-dropDown dropDown-white"
                                  title="Dates"
                                >
                                  {getDateMonthYear?.map((elem) => {
                                    // debugger;
                                    return (
                                      <div
                                        key={elem?.id}
                                        className="dropdown_projects p-0"
                                      >
                                        <Dropdown.Item
                                          className="dropdown-status"
                                          wxcn
                                          title={elem}
                                        >
                                          <span>{elem}</span>
                                        </Dropdown.Item>
                                      </div>
                                    );
                                  })}
                                </DropdownButton>
                              </div>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td>${item?.amount}</td>
                          <td>
                            <ToggleBtn
                              onchange={(e) =>
                                statusOfFee(
                                  item.id,
                                  e.target.checked,
                                  item.school.id
                                )
                              }
                              checked={item.is_active === 1}
                            />
                          </td>
                          <td className="custom_padding">
                            <ul>
                              <li>
                                <NavLink to={`edit/${item.id}`}>
                                  <img src={penImg} alt="Edit" />
                                </NavLink>
                              </li>
                              <li>
                                <img
                                  src={binImg}
                                  alt="binImg"
                                  onClick={() => {
                                    setIsModalVisible({
                                      isShow: true,
                                      id: item.id,
                                    });
                                  }}
                                />
                              </li>
                            </ul>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" className="error01">
                        Data Not Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {fee?.total > 0 && fee?.total > fee?.per_page && (
        <PagePagination
          className="pagination-bar"
          currentPage={fee?.current_page}
          totalCount={fee?.last_page !== undefined ? fee?.total : fee?.length}
          pageSize={size}
          onPageChange={(page) => setPage(page)}
        />
      )}
      {/* Delete confirm modal section start */}

      {isModalVisible.isShow ? (
        <Modal
          show={isModalVisible.isShow}
          onHide={() => setIsModalVisible(false)}
          className="delete-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Do you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsModalVisible(false)}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => deleteFee(isModalVisible.id)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}

      {/* Delete confirm modal section end */}
    </div>
  );
}
export default SchoolDetail;
