

export function manupulateBookNVideoData(data) {
  let mdata = data.reduce((acc, curr) => {
    let refnc = JSON.parse(JSON.stringify(curr));
    delete refnc.subject;
  
    if (curr?.subject?.length > 0) {
      curr.subject.forEach((video) => {
        acc[video?.name] = acc[video?.name]
          ? [...acc[video?.name], refnc]
          : [refnc];
      });
    } 
    else {
      acc["General"] = acc["General"] ? [...acc["General"], refnc] : [refnc];
    }

    return acc;
  }, {});

  return renderBookNVideoData(mdata);
}

export function renderBookNVideoData(data, type) {
  let result = [];

  for (const key in data) {    
    let comp = { title: key, data: data[key] };
    // let comp = (
    //   <Slider
    //     key={key}
    //     length={data[key].length}
    //     id={key}
    //     title={key}
    //     data={data[key]}
    //     type={type}
    //     options={{
    //       margin: 30,
    //       responsiveClass: true,
    //       nav: true,
    //       dots: false,
    //       autoplay: false,
    //       navText: ["", ""],
    //       smartSpeed: 1000,
    //       responsive: {
    //         ...(type === "video"
    //           ? {
    //               0: {
    //                 items: 1,
    //               },
    //               400: {
    //                 items: 1,
    //               },
    //               600: {
    //                 items: 2,
    //               },
    //               700: {
    //                 items: 3,
    //               },
    //               1000: {
    //                 items: 4,
    //               },
    //             }
    //           : {
    //               0: {
    //                 items: 1,
    //               },
    //               400: {
    //                 items: 1,
    //               },
    //               600: {
    //                 items: 2,
    //               },
    //               700: {
    //                 items: 4,
    //               },
    //               1000: {
    //                 items: 8,
    //               },
    //             }),
    //       },
    //     }}
    //   />
    // );
    if (key === "General") {
      result.unshift(comp);
    } else {
      result.push(comp);
    }
  }


  return result;
}
