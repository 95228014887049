import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { useForm, patterns } from "@kaydhiman/react-hook-useform";

import InputBox from "../Utils/forms/InputBox";
import { AppContext } from "../context/AppContext";
import { ADMIN, SCHOOL } from "../../Auth/userTypes";
import CustomButton from "../Utils/forms/CustomButton";

function ForgotPassword() {
  const { setSpinner , setShowLogin} = useContext(AppContext);
  const [role, setRole] = useState("");
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { values, errors, bindField, isValid } = useForm({
    validations: {
      email: {
        pattern: {
          value: patterns.email,
          message: "Invalid email address.",
        },
        required: true,
      },
    },
  });

  const formSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/auth/passwordForgot`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({...values, ...(role && { role }) }),
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        setShowLogin({ role: data.userInfo?.role_id === 1 ? ADMIN : SCHOOL });
        if (values.email.length >= 0) {
          navigate(pathname.includes("/admin") ? "/admin/verify-email" : "/verify-email", { state: values.email });
        } else {
          navigate("/forgot-password");
        }
        toast.success(data.message, {
          autoClose: 3000,
        });
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        if(response.status===422){
          toast.error(data.message, {
            autoClose: 3000,
          });
          setSpinner(false);
          return;
        }
        if (data.errors) {
          for (const [key] of Object.entries(data.errors)) {
            toast.error(data.errors[key][0], {
              autoClose: 3000,
            });
          }
        }
      }
      setSpinner(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setRole(pathname.includes("/admin") ? ADMIN : SCHOOL);
  }, [pathname]);

  return (
    <div className="forgot_form forgotPg">
      <div className="onboarding_heading">
        <h2>Forgot Password</h2>
      </div>

      <div className="data_add_form admin-form">
        <form onSubmit={formSubmit}>
          <div className="form-inputs">
            <div className="mb-4">
              <InputBox
                label="Email"
                type="email"
                name="email"
                placeholder="Enter Email"
                classname="w-100"
                {...bindField("email")}
                error={errors.email}
              />
            </div>
          </div>
          <div className="form-footer">
            <CustomButton
              type="submit"
              text="Submit"
              classname="sub-btn"
              disabled={!isValid()}
            />

            <div className="mb-3 form-signUp text-center">
              <p>
                Back to<Link to={pathname.includes("/admin") ? '/admin/login' : '/login'}> Sign In</Link>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default ForgotPassword;
