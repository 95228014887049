import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link } from "react-router-dom";

import { useForm } from "@kaydhiman/react-hook-useform";
import { DropdownButton, Dropdown } from "react-bootstrap";

import { http } from "../../../../services/http";
import { AppContext } from "../../../context/AppContext";
import debounce from "../../../../utilities/debounce";
import InputField from "../../../Utils/forms/InputField";
import PagePagination from "../../../Utils/pagination/PagePagination";

import ShortIcon from "../../../../assets/images/sorting.png";
import SearchIcon from "../../../../assets/images/search-bar.png";

function Students() {
  const [studentList, setStudentList] = useState();
  const [classList, setClassList] = useState();
  const [schoolList, setSchoolList] = useState();
  const [pointer, setPointer] = useState("none");
  const [page, setPage] = useState();
  const [getSearch, setSearch] = useState("");
  const { setInitialValues, values } = useForm({
    validations: {},
  });
  const [isAscending, setIsAscending] = useState(false);
  const { setSpinner, size } = useContext(AppContext);

  const getStudentsHandle = () => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: size,
      class_id: values.classId ? values.classId : "",
      school_id: values.schoolId ? values.schoolId : "",
    };
    if (getSearch) {
      params.search = getSearch;
    }
    http(
      {
        method: "GET",
        url: `student?${new URLSearchParams(params)}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setStudentList(res.data);
      }
    );
  };

  // eslint-disable-next-line
  const debouncedChangeHandler = useCallback(
    debounce((event) => {
      setSearch(event?.target?.value);
      setPage(1);
    }, 500)
  );

  const sortStudentData = () => {
    setStudentList((prevData) => ({
      ...prevData,
      data: prevData.data?.sort((item1, item2) => {
        if (item1.student.user.name < item2.student.user.name) {
          return isAscending ? -1 : 1;
        }
        if (item1.student.user.name > item2.student.user.name) {
          return isAscending ? 1 : -1;
        }

        // names must be equal
        return 0;
      }),
    }));

    setIsAscending((prev) => !prev);
  };

  useEffect(() => {
    getStudentsHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, getSearch, values.schoolId, values.classId]);

  useEffect(() => {
    http(
      {
        method: "GET",
        url: `school`,
        isSecure: true,
      },
      (res) => {
        setSchoolList(res);
      }
    );
  }, []);

  useEffect(() => {
    http(
      {
        method: "GET",
        url: `class`,
        isSecure: true,
      },
      (res) => {
        setClassList(res);
      }
    );
  }, []);
  // console.log("search", getSearch);
  return (
    <div className="sup_student_dash ">
      <div className="sup_history">
        <div className="scale_data_header">
          <div className="scale_data_heading">
            <h2 className="class_head">Students</h2>
            <div className="btns_header">
              <ul>
                <li>
                  {/* school dropdown section start */}
                  <div className="dropdown-box">
                    <DropdownButton
                      className={`dropdown-btn`}
                      title={
                        values?.school_data ? (
                          values?.school_data
                        ) : (
                          <span>Select School</span>
                        )
                      }
                    >
                      <div
                        className="dropdown_projects"
                        onClick={() => {
                          setInitialValues((prev) => ({
                            ...prev,
                            school_data: "All",
                            schoolId: "",
                            class_data: "",
                            classId: "",
                          }));
                          setPointer("auto");
                          setSearch("");
                        }}
                      >
                        <Dropdown.Item title="All">
                          <span
                            className={
                              values?.school_data === "All"
                                ? "class_active"
                                : ""
                            }
                          >
                            All
                          </span>
                        </Dropdown.Item>
                      </div>
                      {schoolList?.data?.map((elem) => {
                        return (
                          <div
                            key={elem?.id}
                            className="dropdown_projects"
                            onClick={() => {
                              setInitialValues((prev) => ({
                                ...prev,
                                school_data: elem.name,
                                schoolId: elem.id,
                                class_data: "",
                                classId: "",
                              }));
                              setPointer("auto");
                              setSearch("");
                            }}
                          >
                            <Dropdown.Item
                              className="dropdown-status"
                              title={elem?.school}
                            >
                              <span
                                className={
                                  elem?.name === values?.school_data
                                    ? "class_active"
                                    : ""
                                }
                                // onClick={() => setInitialValues({ school_data: elem.school })}
                              >
                                {elem?.name}
                              </span>
                            </Dropdown.Item>
                          </div>
                        );
                      })}
                    </DropdownButton>
                  </div>
                  {/* school dropdown section end */}
                </li>
                <li>
                  {/* class dropdown section start */}
                  <div
                    className="dropdown-box"
                    style={{ pointerEvents: pointer }}
                  >
                    <DropdownButton
                      className={`dropdown-btn`}
                      title={
                        values?.class_data ? (
                          values?.class_data
                        ) : (
                          <span>Select Class</span>
                        )
                      }
                    >
                      {classList?.data?.map((elem) => {
                        return (
                          <div
                            key={elem?.id}
                            className="dropdown_projects"
                            onClick={() => {
                              setInitialValues((prev) => ({
                                ...prev,
                                class_data: elem.name,
                                classId: elem.id,
                              }));
                            }}
                          >
                            <Dropdown.Item
                              className="dropdown-status"
                              title={elem?.class}
                            >
                              <span
                                className={
                                  elem?.name === values?.class_data
                                    ? "class_active"
                                    : ""
                                }
                              >
                                {elem?.name}
                              </span>
                            </Dropdown.Item>
                          </div>
                        );
                      })}
                    </DropdownButton>
                  </div>
                  {/* class dropdown section end */}
                </li>
              </ul>
            </div>
          </div>
          <div className="search-box">
            <div className="input-group flex-nowrap">
              <InputField
                type="text"
                className="form-control searchBar"
                placeholder="Search..."
                onChange={debouncedChangeHandler}
                name="search"
              />
              <span
                className="input-group-text search-icon"
                id="addon-wrapping"
              >
                <img src={SearchIcon} alt="SearchIcon" />
              </span>
            </div>
          </div>
        </div>
        <div className="kina_tables table-responsive">
          <table className="dash_tables">
            <thead>
              <tr>
                <th className="SnumData">S.No.</th>
                <th>
                  Students Name{" "}
                  <img
                    src={ShortIcon}
                    alt="sorting icon"
                    className="cursor"
                    onClick={sortStudentData}
                  />
                </th>
                <th>Class</th>
                <th>Roll No</th>
                <th>Phone No</th>
                <th> Email Id</th>
              </tr>
            </thead>
            <tbody>
              {studentList?.data?.length ? (
                studentList?.data?.map((historyData, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {studentList.from + index
                          ? studentList.from + index
                          : "-"}
                      </td>
                      <td>
                        <Link
                          to={`student-details/${historyData?.student?.id}`}
                          state={historyData}
                        >
                          {historyData.student.user.name
                            ? historyData.student.user.name
                            : "-"}
                        </Link>{" "}
                      </td>
                      <td>
                        {historyData.student.class.name
                          ? historyData.student.class.name
                          : "-"}
                      </td>
                      <td>
                        {historyData.student.roll_number
                          ? historyData.student.roll_number
                          : "-"}
                      </td>
                      <td>
                        {historyData.student.user.phone_number
                          ? historyData.student.user.phone_number
                          : "-"}
                      </td>
                      <td className="text-nowrap">
                        {historyData.student.user.email
                          ? historyData.student.user.email
                          : "-"}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="8" className="error01">
                    Data Not Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {studentList?.total > 0 && studentList?.total > studentList?.per_page && (
        <PagePagination
          className="pagination-bar"
          currentPage={studentList?.current_page}
          totalCount={
            studentList?.last_page !== undefined
              ? studentList?.total
              : studentList?.length
          }
          pageSize={size}
          onPageChange={(page) => setPage(page)}
        />
      )}
    </div>
  );
}
export default Students;
