import React, { useContext, useEffect, useState, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "@kaydhiman/react-hook-useform";
import binImg from "../../../../assets/images/trash.png";
import penImg from "../../../../assets/images/edit_action.png";
import BreadCrumb from "../../../../form-widgets/BreadCrumb";
import { AppContext } from "../../../context/AppContext";
import { http } from "../../../../services/http";
import PagePagination from "../../../Utils/pagination/PagePagination";
import debounce from "../../../../utilities/debounce";
import SearchForm from "../../../Utils/forms/SearchFrorm";

function Exams() {
  const [examlist, setExamList] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [label, setLabel] = useState({ firstLabel: "", secondLabel: "" });
  const [getSearch, setSearch] = useState("");
  const [page, setPage] = useState();
  const [examCatgoryList, setExamCatgoryList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    ExamId: "",
  });

  const { id, subId } = useParams();
  const { setSpinner, size } = useContext(AppContext);
  const breadCrumbs = useLocation();
  const navigate = useNavigate();
  // validations code
  const { values, setInitialValues } = useForm({
    validations: {
      exam_category_id: {},
    },
  });

  const getExamsApi = () => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: size,
    };
    if (getSearch) {
      params.search = getSearch;
    }

    http(
      {
        method: "GET",
        url: `class/${id}/subject/${subId}/exam?${new URLSearchParams(params)}`,
        isSecure: true,
      },
      (res) => {
        setLabel({
          firstLabel: res?.data?.data[0]?.class?.name,
          secondLabel: res?.data?.data[0]?.subject?.name,
        });
        setExamList(res.data);
        setSpinner(false);
      }
    );
  };
  // eslint-disable-next-line
  const debouncedChangeHandler = useCallback(
    debounce((event) => {
      setSearch(event?.target?.value);
      setPage(1);
    }, 500)
  );
  //delete exams api
  const deleteExams = (ExamId) => {
    setSpinner(true);
    http(
      {
        method: "DELETE",
        url: `class/${id}/subject/${subId}/exam/${ExamId}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setExamList((prevData) => {
          return {
            ...prevData,
            data: prevData?.data?.filter((items) => items.id !== ExamId),
          };
        });
        setIsModalVisible({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  // get exam category list
  useEffect(() => {
    http(
      {
        method: "GET",
        url: "examCategories",
        isSecure: true,
      },
      (res) => {
        setExamCatgoryList(res.data);
      }
    );
  }, []);

  // exams Listing API
  useEffect(() => {
    getExamsApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSearch, page]);

  return (
    <div className="exams_dash inner-exam-dash px-0">
      <div className="sup_history">
        <div className="scale_data_header">
          <BreadCrumb
            links={[...(breadCrumbs.state ? breadCrumbs.state : [])]}
          />

          <div className="search-box">
            <div className="btns_header">
              {/* Exam catagory dropdown section start */}

              <div className="dropdown-box">
                <DropdownButton
                  className={`dropdown-btn`}
                  title={
                    examCatgoryList?.filter(
                      (item) => item.id === values.exam_category_id
                    )[0]?.name || <span>Select Exams Category</span>
                  }
                >
                  {examCatgoryList?.map((elem) => {
                    return (
                      <div
                        key={elem?.id}
                        className="dropdown_projects"
                        onClick={() =>
                          setInitialValues({
                            ...values,
                            exam_category_id: elem.id,
                            exam_name: elem.name,
                          })
                        }
                      >
                        <Dropdown.Item
                          className="dropdown-status"
                          title={elem.name}
                        >
                          <span
                            className={
                              elem?.name === values?.exam_name
                                ? "class_active"
                                : ""
                            }
                          >
                            {elem.name}
                          </span>
                        </Dropdown.Item>
                      </div>
                    );
                  })}
                </DropdownButton>
              </div>
              {/* Exam catagory dropdown section end */}
            </div>

            <div className="input-group flex-nowrap">
              <SearchForm
                name="search"
                onChange={debouncedChangeHandler}
                placeholder="Search by exam name..."
              />
            </div>
            <div className="data_add">
              <Link to="add" className="btn sub-btn btn-primary">
                Add Exam
              </Link>
            </div>
          </div>
        </div>
        <div className="kina_tables table-responsive">
          <table className="dash_tables">
            <thead>
              <tr>
                <th className="SnumData">S.No.</th>
                <th>Subject Name</th>
                <th>Exam Category</th>
                <th>Exam Name</th>
                <th>Questions</th>
                <th className="pricingTh">Action</th>
              </tr>
            </thead>
            <tbody>
              {examlist?.data?.length ? (
                examlist?.data?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{examlist.from + index}</td>
                      <td>
                        <span
                          onClick={() => {
                            navigate(`${item.id}`, {
                              state: [
                                ...breadCrumbs.state,
                                {
                                  label: item.name,
                                  url: window.location.pathname,
                                },
                              ],
                            });
                          }}
                          className="hand text-nowrap"
                        >
                          {item?.subject?.name}
                        </span>
                      </td>
                      <td>{item?.exam_category?.name}</td>
                      <td>{item?.name}</td>
                      <td>
                        <span
                          onClick={() => {
                            navigate(`${item.id}`, {
                              state: [
                                ...breadCrumbs.state,
                                {
                                  label: item.name,
                                  url: window.location.pathname,
                                },
                              ],
                            });
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Question ({item.questions_count})
                        </span>
                      </td>
                      <td>
                        <ul>
                          <li>
                            <NavLink to={`edit/${item.id}`}>
                              <img src={penImg} alt="PDF Icon" />
                            </NavLink>
                          </li>
                          <li>
                            <img
                              src={binImg}
                              alt="binImg"
                              onClick={() => {
                                setIsModalVisible({
                                  isShow: true,
                                  ModalId: item.id,
                                });
                              }}
                            />
                          </li>
                        </ul>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="8" className="error01">
                    Data Not Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* Delete confirm modal section start */}
      {isModalVisible.isShow ? (
        <Modal
          show={isModalVisible}
          onHide={() => setIsModalVisible(false)}
          className="delete-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Do you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsModalVisible(false)}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => deleteExams(isModalVisible.ModalId)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}

      {/* Delete confirm modal section end */}
      {examlist?.total > 0 && examlist?.total > examlist?.per_page && (
        <PagePagination
          className="pagination-bar"
          currentPage={examlist?.current_page}
          totalCount={
            examlist?.last_page !== undefined
              ? examlist?.total
              : examlist?.length
          }
          pageSize={size}
          onPageChange={(page) => setPage(page)}
        />
      )}
    </div>
  );
}
export default Exams;
