import React, { useState } from "react";

import { Accordion } from "react-bootstrap";

import binImg from "../../../../assets//images/trash.png";
import penImg from "../../../../assets//images/edit_action.png";

import StudentAdminData from "../../../../admindata/StudentAdminData";

function TestListing() {
  const [allData, setAllData] = useState(StudentAdminData);

  // Test listing section start
  const deleteTestListing = (id) => {
    const data = allData.filter((item) => item.id !== id);
    setAllData(data);
  };
  // Test Listing section end

  return (
    <div className="kina_tables table-responsive">
      <table className="dash_tables">
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Subjects Name</th>
            <th>Questions</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {allData.length ? (
            allData?.map((historyData, index) => {
              const { id, sno, subject, question, qu1, qu2, qu3, qu4 } =
                historyData;
              return (
                <tr key={index}>
                  <td>{sno}</td>
                  <td>{subject}</td>
                  <td>
                    <Accordion>
                      <Accordion.Item eventKey={id}>
                        <Accordion.Header>
                          <p className="accordion-header">{question}</p>
                        </Accordion.Header>
                        {/* Question dropdown section start */}
                        <Accordion.Body>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <ul className="acord_lists">
                                <li>
                                  <div className="form-check">
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexRadioDefault1"
                                    >
                                      <span className="srNum">1. </span>
                                      <span className="acordHead">{qu1}</span>
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                    />
                                  </div>
                                </li>
                                <li>
                                  <div className="form-check">
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexRadioDefault1"
                                    >
                                      <span className="srNum">2. </span>
                                      <span className="acordHead">{qu2}</span>
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                    />
                                  </div>
                                </li>
                                <li>
                                  <div className="form-check">
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexRadioDefault1"
                                    >
                                      <span className="srNum">3. </span>
                                      <span className="acordHead">{qu3}</span>
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault3"
                                    />
                                  </div>
                                </li>
                                <li>
                                  <div className="form-check">
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexRadioDefault1"
                                    >
                                      <span className="srNum">4. </span>
                                      <span className="acordHead">{qu4}</span>
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault4"
                                    />
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      {/* Question dropdown section start */}
                    </Accordion>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <img src={penImg} alt="penImg" />
                      </li>
                      <li>
                        <img
                          src={binImg}
                          alt="binImg"
                          onClick={() => deleteTestListing(id)}
                        />
                      </li>
                    </ul>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="8" className="error01">
                Data Not Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
export default TestListing;
