import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import PagePagination from "../../../Utils/pagination/PagePagination";
import { AppContext } from "../../../context/AppContext";
import { http } from "../../../../services/http";
import SearchForm from "../../../Utils/forms/SearchFrorm";
import debounce from "../../../../utilities/debounce";

import binImg from "../../../../assets//images/trash.png";
import ShortIcon from "../../../../assets/images/sorting.png";
import penImg from "../../../../assets//images/edit_action.png";

function Teachers() {
  const [page, setPage] = useState();
  const [getSearch, setSearch] = useState("");
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    id: "",
  });
  const { setSpinner, size } = useContext(AppContext);
  const [teacherList, setTeacherList] = useState({});
  const [isAscending, setIsAscending] = useState();
  // teacher list item delete function

  const deleteTeacher = (id) => {
    http(
      {
        method: "DELETE",
        url: `teacher/${id}`,
        isSecure: true,
      },
      (res) => {
        setTeacherList((prevData) => {
          return {
            ...prevData,
            data: prevData.data?.filter((items) => items.teacher_id !== id),
          };
        });
        setIsModalVisible({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  const subjectsDropDownOpner = (id) => {
    document.querySelectorAll(".subjectListing_section").forEach((elm) => {
      if (elm.id !== `teacher-${id}`) {
        elm.classList.add("d-none");
      }
    });
    document.getElementById(`teacher-${id}`).classList.toggle("d-none");
  };

  const getTeachersHandle = () => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: size,
    };
    if (getSearch) {
      params.search = getSearch;
    }

    http(
      {
        method: "GET",
        url: `teacher?${new URLSearchParams(params)}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setTeacherList(res.data);
      }
    );
  };
  // useCallback(
  //   ({ page, search }) => {
  //     setSpinner(true);
  //     http(
  //       {
  //         method: "GET",
  //         url: `teacher?page=${page ? page : 1}&size=10${
  //           search ? "&search=" + search : ""
  //         }`,
  //         isSecure: true,
  //       },
  //       (res) => {
  //         setSpinner(false);
  //         setTeacherList(res.data);
  //       }
  //     );
  //   },
  //   [setSpinner]
  // );

  //for sort Teacher
  const sortStudentData = () => {
    setTeacherList((prevData) => ({
      ...prevData,
      data: prevData.data?.sort((item1, item2) => {
        if (item1.teacher.user.name < item2.teacher.user.name) {
          return isAscending ? -1 : 1;
        }
        if (item1.teacher.user.name > item2.teacher.user.name) {
          return isAscending ? 1 : -1;
        }
        // names must be equal
        return 0;
      }),
    }));

    setIsAscending((prev) => !prev);
  };

  // eslint-disable-next-line
  const debouncedChangeHandler = useCallback(
    debounce((event) => {
      setSearch(event?.target?.value);
      setPage(1);
    }, 500)
  );

  // get Teacher lisiting
  useEffect(() => {
    getTeachersHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, getSearch]);

  return (
    <div className="teacher-dashboard">
      <div className="col-md-12">
        <div className="sup_history">
          <div className="scale_data_header">
            <h2 className="class_head">Teachers</h2>
            <div className="search-box">
              <SearchForm onChange={debouncedChangeHandler} />

              <div className="data_add">
                <Link
                  to="add"
                  type="submit"
                  className="btn sub-btn btn-primary"
                >
                  Add Teacher
                </Link>
              </div>
            </div>
          </div>
          <div className="kina_tables">
            <table className="dash_tables">
              <thead>
                <tr>
                  <th className="SnumData">S.No.</th>
                  <th className="data01">
                    Teacher Name{" "}
                    <img
                      src={ShortIcon}
                      alt="sorting icon"
                      className="hand"
                      onClick={sortStudentData}
                    />
                  </th>
                  <th className="data02">Subjects</th>
                  <th className="data03">Roll No</th>
                  <th className="data04">Phone Number</th>
                  <th className="data05">Email Id</th>
                  <th className="pricingTh">Action</th>
                </tr>
              </thead>
              <tbody>
                {teacherList?.data?.length ? (
                  teacherList?.data?.map((teachData, index) => (
                    <tr key={teachData.teacher_id}>
                      <td>{teacherList.from + index}</td>
                      <td>
                        <Link to={`${teachData.teacher_id}`}>
                          {teachData?.teacher?.user?.name}
                        </Link>
                      </td>
                      <td>
                        <div className="subjectListing">
                          <button
                            className="subject_btn"
                            onClick={() =>
                              subjectsDropDownOpner(teachData.teacher_id)
                            }
                          >
                            Subjects
                          </button>
                          <ul
                            id={`teacher-${teachData.teacher_id}`}
                            className="subjectListing_section d-none"
                          >
                            {teachData?.teacher.teacher_subjects.map(
                              (subjInfo) => (
                                <li
                                  className="subjectListing_section_text"
                                  key={subjInfo?.subject?.id}
                                >
                                  {subjInfo?.subject?.name}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </td>
                      <td>{teachData?.teacher?.roll_number}</td>
                      <td>{teachData?.teacher?.user?.phone_number}</td>
                      <td>{teachData?.teacher?.user?.email}</td>
                      <td className="p-0">
                        <div className="d-flex justify-content-center">
                          <Link
                            to={`/school/teachers/edit/${teachData.teacher_id}`}
                          >
                            <img src={penImg} alt="Edit" />
                          </Link>
                          <img
                            src={binImg}
                            alt="Delete"
                            className="custom_marg_left hand"
                            onClick={() => {
                              setIsModalVisible({
                                isShow: true,
                                id: teachData.id,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="error01">
                      Data Not Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {teacherList?.total > 0 && teacherList?.total > teacherList?.per_page && (
        <PagePagination
          className="pagination-bar"
          currentPage={teacherList?.current_page}
          totalCount={
            teacherList?.last_page !== undefined
              ? teacherList?.total
              : teacherList?.length
          }
          pageSize={size}
          onPageChange={(page) => setPage(page)}
        />
      )}

      {/* Delete confirm modal section start */}
      {isModalVisible.isShow ? (
        <Modal
          show={isModalVisible}
          onHide={() => setIsModalVisible(false)}
          className="delete-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Do you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsModalVisible(false)}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => deleteTeacher(isModalVisible.id)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}

      {/* Delete confirm modal section end */}
    </div>
  );
}
export default Teachers;
