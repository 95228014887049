import { useForm } from "@kaydhiman/react-hook-useform";
import React, { useEffect, useState } from "react";
import { useContext } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import BreadCrumb from "../../../../../form-widgets/BreadCrumb";
import QuestionMaker from "../../../../../form-widgets/QuestionMaker";
import { http } from "../../../../../services/http";
import { AppContext } from "../../../../context/AppContext";
import CustomButton from "../../../../Utils/forms/CustomButton";
// import InputBox from "../../../../Utils/forms/InputBox";

function AddQuestion() {
  const [catagoryType, setCatagoryType] = useState(1);
  const [answers, setAnswers] = useState([
    { id: 1, answer: "", is_correct: false },
  ]);

  const { setSpinner } = useContext(AppContext);
  const { id, subId, chptrId, testId, quesId, genquesId, editQuesId } =
    useParams();
  const navigate = useNavigate();

  const { setInitialValues, values, bindField } = useForm({
    validations: {
      initialValues: { class_data: [], sub_data: [], exam_data: [] },
      question: {},
    },
  });

  const handleAddForm = (e) => {
    e.preventDefault();
    if (answers?.some((item) => item.answer === "") && !quesId) {
      toast.success("Please enter all answers fields", {
        autoClose: 3000,
      });
      return;
    }
    if (!answers?.some((item) => item.is_correct) && !quesId) {
      toast.success("Please select atleast one correct answer", {
        autoClose: 3000,
      });
      return;
    }
    setSpinner(true);
    http(
      {
        method: quesId ? "PUT" : "POST",
        url: `class/${id}/subject/${subId}/chapter/${chptrId}/test/${testId}/question${
          quesId ? "/" + quesId : ""
        }`,
        isSecure: true,
        body: {
          answers,
          ...(values.question && { question: values.question }),
          ...(catagoryType && { test_question_category_id: catagoryType }),
          ...(catagoryType === 1 ? { answer_count: answers.length } : {}),
        },
      },
      (res) => {
        setSpinner(false);
        navigate(-1);
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  const addGenralQuestion = (e) => {
    e.preventDefault();
    if (answers?.some((item) => item.answer === "") && !editQuesId) {
      toast.success("Please enter all answers fields", {
        autoClose: 3000,
      });
      return;
    }
    if (!answers?.some((item) => item.is_correct) && !editQuesId) {
      toast.success("Please select atleast one correct answer", {
        autoClose: 3000,
      });
      return;
    }
    setSpinner(true);
    http(
      {
        method: editQuesId ? "PUT" : "POST",
        url: `generalExam/${genquesId}/question${
          editQuesId ? "/" + editQuesId : ""
        }`,
        isSecure: true,
        body: {
          answers,
          ...(values.question && { question: values.question }),
          ...(catagoryType && { exam_question_category_id: catagoryType }),
          ...(catagoryType === 1 ? { answer_count: answers.length } : {}),
        },
      },
      (res) => {
        setSpinner(false);
        navigate(-1);
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  useEffect(() => {
    quesId &&
      http(
        {
          method: "GET",
          url: `class/${id}/subject/${subId}/chapter/${chptrId}/test/${testId}/question/${quesId}`,
          isSecure: true,
        },
        (res) => {
          setInitialValues({ question: res.data.question });
          setCatagoryType(res.data.test_question_category_id);
          setAnswers(
            res.data.answers.map((ans) => ({
              id: ans.id,
              answer: ans.answer,
              is_correct: ans.is_correct === 1,
            }))
          );
        }
      );
  }, [id, subId, chptrId, testId, quesId, setInitialValues]);

  useEffect(() => {
    editQuesId &&
      http(
        {
          method: "GET",
          url: `generalExam/${genquesId}/question/${editQuesId}`,
          isSecure: true,
        },
        (res) => {
          setInitialValues({ question: res.data.question });
          setCatagoryType(
            res.data.exam_question_category_id
          );
          setAnswers(
            res.data.answers.map((ans) => ({
              id: ans.id,
              answer: ans.answer,
              is_correct: ans.is_correct === 1,
            }))
          );
        }
      );
  }, [editQuesId, setInitialValues, genquesId]);

  return (
    <div className=" user_details edit_test_dash px-0">
      <div className="sup_history update_user ">
        <div className="scale_data_header">
          <BreadCrumb
            links={[
              {
                label: `${quesId || editQuesId ? "Edit" : "Add"} Question`,
                style: { color: "black" },
                url: "",
              },
            ]}
          />
        </div>
        <div className="data_add_form admin-form">
          <form onSubmit={genquesId ? addGenralQuestion : handleAddForm}>
            <QuestionMaker
              {...bindField("question")}
              catagoryType={catagoryType}
              setCatagoryType={setCatagoryType}
              answers={answers}
              setAnswers={setAnswers}
            />
            <div className="addon_btn row">
              <div className="col-xl-3 col-lg-6">
                <CustomButton text="Submit" type="submit" classname="sub-btn" />
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer theme="dark" />
    </div>
  );
}
export default AddQuestion;
