import React, { useCallback, useEffect, useState, useContext } from "react";

import { Link } from "react-router-dom";

import { DropdownButton, Dropdown } from "react-bootstrap";

import { http } from "../../../../services/http";
import debounce from "../../../../utilities/debounce";
import InputField from "../../../Utils/forms/InputField";
import { AppContext } from "../../../context/AppContext";
import { manupulateBookNVideoData } from "../../../../utilities/bookNvideo";

import SearchIcon from "../../../../assets/images/search-bar.png";
import Slider from "../../../Utils/widgets/Slider";

function Video() {
  const [classList, setClassList] = useState();
  const [classID, setClassID] = useState({});
  const [videoList, setVideoList] = useState([]);

  const { setSpinner } = useContext(AppContext);

  const getVideoHandle = useCallback(
    ({ search, id }) => {
      setSpinner(true);
      http(
        {
          method: "GET",
          url: `video?${search ? "search=" + search : ""}${
            id ? (id && !search ? "" : "&") + "class_id=" + id : ""
          }`,
          isSecure: true,
        },
        (res) => {
          setSpinner(false);
          setVideoList(manupulateBookNVideoData(res.data));
        }
      );
    },
    [setSpinner]
  );

  // eslint-disable-next-line
  const debouncedChangeHandler = useCallback(debounce(getVideoHandle, 300), []);

  useEffect(() => {
    http(
      {
        method: "GET",
        url: `class`,
        isSecure: true,
      },
      (res) => {
        setClassList(res);
      }
    );
  }, []);

  useEffect(() => {
    getVideoHandle({ id: classID.id });
  }, [getVideoHandle, classID.id]);

  return (
    <div
      className={`data_cards video_dash ${
        videoList.length === 0 ? "custom_height" : " "
      }`}
    >
      <div className="sup_history">
        <div className="scale_data_header border-bottom">
          <h2 className="class_head ">Videos</h2>
          <div className="search-box">
            <div className="input-group flex-nowrap">
              <InputField
                type="text"
                className="form-control searchBar"
                placeholder="Search..."
                onChange={(e) =>
                  debouncedChangeHandler({
                    search: e.target.value,

                    id: classID.id,
                  })
                }
                name="search"
              />

              <span
                className="input-group-text search-icon"
                id="addon-wrapping"
              >
                <img src={SearchIcon} alt="SearchIcon" />
              </span>
            </div>
            <div className="data_add">
              <Link
                to="add-videos"
                type="submit"
                className="btn sub-btn btn-primary"
              >
                Add Videos
              </Link>
            </div>
          </div>
        </div>
        <div className="dash_body01">
          <div className="dropdown col-xl-3 mt-4">
            <div className="dropdown-box">
              <DropdownButton
                className={`dropdown-btn`}
                title={
                  classID?.name ? classID?.name : <span>Select Class</span>
                }
              >
                {classList?.data?.map((elem) => {
                  return (
                    <div
                      key={elem?.id}
                      className="dropdown_projects px-3"
                      onClick={() => {
                        setClassID({
                          name: elem.name,
                          id: elem.id,
                        });
                      }}
                    >
                      <Dropdown.Item
                        className="dropdown-status"
                        title={elem?.name}
                      >
                        <span
                          className={
                            elem?.name === classID?.name ? "class_active" : ""
                          }
                          // onClick={() => {
                          //   setInitialValues({ priority: elem.h_class });
                          // }}
                        >
                          {elem?.name}
                        </span>
                      </Dropdown.Item>
                    </div>
                  );
                })}
              </DropdownButton>
            </div>
          </div>
        </div>

        {videoList.map((video, ind) => (
          <Slider
            key={ind}
            length={video.data.length}
            id={ind}
            title={video.title}
            type="video"
            data={video.data}
            options={{
              margin: 30,
              responsiveClass: true,
              nav: true,
              dots: false,
              autoplay: false,
              navText: ["", ""],
              smartSpeed: 1000,
              responsive: {
                0: {
                  items: 1,
                },
                400: {
                  items: 1,
                },
                600: {
                  items: 2,
                },
                700: {
                  items: 3,
                },
                1000: {
                  items: 4,
                },
              },
            }}
          />
        ))}

        {Object.keys(videoList).length === 0 && (
          <h1 className="text-center">No data found</h1>
        )}
      </div>
    </div>
  );
}
export default Video;
