import React, { useState, useContext, useEffect } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { useForm } from "@kaydhiman/react-hook-useform";
import CustomButton from "../../../../Utils/forms/CustomButton";
import InputBox from "../../../../Utils/forms/InputBox";
import BreadCrumb from "../../../../../form-widgets/BreadCrumb";
import { toast } from "react-toastify";

import { AppContext } from "../../../../context/AppContext";
import { http } from "../../../../../services/http";

import CrossIcon from "../../../../../assets/images/red_cross.svg";
import PDFicon from "../../../../../assets/images/pdf-file.png";

function AddReportCard() {
  const [doc, setDoc] = useState({ file: "", url: "" });

  const { setSpinner } = useContext(AppContext);

  const [classList, setClassList] = useState();

  const { studentId, classId } = useParams();

  const navigate = useNavigate();

  const { pathname, state } = useLocation();

  // pdf Upload handle section start
  const uploadPdfFile = (e) => {
    const url = URL.createObjectURL(e.target.files[0]);
    setDoc({ file: e.target.files[0], url });
  };
  // pdf Upload handle section end

  const { values, errors, bindField, isValid, setInitialValues } = useForm({
    validations: {
      name: {
        required: true,
      },
    },
  });

  //upload report card

  const handleReportCard = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("type", "reportcard");
    formData.append("file", doc.file);

    await http(
      {
        method: "POST",
        url: `upload-image`,
        isSecure: true,
        contentType: "multipart/form-data",
        body: formData,
      },
      (res) => {
        setSpinner(false);
        // toast.success(res.message, {
        //   autoClose: 3000,
        // });
        if (pathname.includes("/students")) {
          AddReportCard(res.content.imageName);
          sendOrAddReportCard(res.content.imageName);
        } else {
          sendOrAddReportCard(res.content.imageName);
        }
      },
      (res) => {
        setSpinner(false);
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  const AddReportCard = (report) => {
    setSpinner(true);
    http(
      {
        method: "POST",
        url: `student/${studentId}/reportcard`,
        isSecure: true,
        body: {
          name: values.name,
          report: report,
        },
      },
      (res) => {
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
    setSpinner(false);
  };

  //send or add report card

  const sendOrAddReportCard = (report) => {
    const url = studentId
      ? `student/${studentId}/reportcard/send`
      : `class/${classId || values.classId}/reportcard${
          !studentId && !classId ? "" : "/send"
        }`;

    setSpinner(true);
    http(
      {
        method: "POST",
        url,
        isSecure: true,
        body: {
          name: values.name,
          report: report,
        },
      },
      (res) => {
        setSpinner(false);
        toast.success(res.message, {
          autoClose: 3000,
        });
        navigate(
          `${
            pathname.includes("/reports")
              ? "/school/reports"
              : "/school/students"
          }`
        );
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  useEffect(() => {
    http(
      {
        method: "GET",
        url: `class`,
        isSecure: true,
      },
      (res) => {
        setClassList(res);
      }
    );
  }, []);

  return (
    <div className="user_details report-card_dash px-0">
      <div className="sup_history update_user">
        <div className="scale_data_header">
          <div className="scale_data_heading">
            {pathname.includes("/reports") ? (
              <BreadCrumb
                links={[
                  {
                    label: "Add Report",
                  },
                ]}
              />
            ) : (
              <BreadCrumb
                links={[
                  ...(state ? state : ""),
                  {
                    label: "Send Report Card",
                    url: ``,
                  },
                ]}
              />
            )}
          </div>
        </div>
        <div className="data_add_form admin-form">
          <form onSubmit={handleReportCard}>
            {!studentId && !classId ? (
              <div className="row">
                <div className="col-lg-3">
                  <label className="form-label" htmlFor="name">
                    Class <span className="text-danger">*</span>
                  </label>
                  <div className="dropdown-box">
                    <DropdownButton
                      className={`dropdown-btn`}
                      title={
                        values?.classNm ? (
                          values?.classNm
                        ) : (
                          <span>Select class</span>
                        )
                      }
                    >
                      {classList?.data?.map((elem) => {
                        return (
                          <div
                            key={elem?.id}
                            className="dropdown_projects"
                            onClick={() =>
                              setInitialValues({
                                ...values,
                                classId: elem.id,
                                classNm: elem.name,
                              })
                            }
                          >
                            <Dropdown.Item
                              className="dropdown-status"
                              title={elem?.name}
                            >
                              <span
                                className={
                                  elem?.name === values?.classNm
                                    ? "class_active"
                                    : ""
                                }
                              >
                                {elem?.name}
                              </span>
                            </Dropdown.Item>
                          </div>
                        );
                      })}
                    </DropdownButton>
                  </div>
                </div>
                {/* {values.classNm ? (
                  " "
                ) : (
                  <span className="text-danger">Please Select Class</span>
                )} */}
              </div>
            ) : (
              " "
            )}

            <div className="row mt-2">
              <div className="mb-4 col-lg-3">
                <InputBox
                  label="Report Card Name"
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter report card name"
                  classname="form-control"
                  {...bindField("name")}
                  error={errors.name}
                  required="true"
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-4 col-lg-3">
                <label htmlFor="file" className="form-label">
                  Upload file <span className="text-danger">*</span>
                </label>
                <div className="item-content file_up">
                  <div className="pdf-upload">
                    <input
                      type="file"
                      className="btn sub-btn upload_btn mr-3"
                      accept="application/pdf"
                      onChange={uploadPdfFile}
                    />

                    {doc.url && (
                      <div className="file_icon">
                        <img
                          className="file"
                          src={PDFicon}
                          alt="Upload book as pdf"
                        />
                        <img
                          className="cut_pdf"
                          src={CrossIcon}
                          alt="Remove selected file"
                          onClick={() => setDoc({})}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {/* {doc?.url?.length > 0 ? (
                  " "
                ) : (
                  <p className="text-danger">Please choose file to upload</p>
                )} */}
              </div>
            </div>

            <div className="addon_btn row">
              <div className="col-xl-3 col-lg-6">
                {pathname.includes("/reports") ? (
                  <CustomButton
                    text="Submit"
                    type="submit"
                    classname="sub-btn"
                    disabled={!isValid() || !doc.url || !values.classNm}
                  />
                ) : (
                  <CustomButton
                    text="Send Report Card"
                    type="submit"
                    classname="sub-btn"
                    disabled={!isValid() || !doc.url}
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default AddReportCard;
