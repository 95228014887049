import React from "react";

function Spinner() {
  return (
    <div className="main-load">
      <div className="spinner dotted"></div>
    </div>
  );
}
export default Spinner;
