import React, { useContext, useEffect, Suspense } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";

import Auth from "./Auth/Auth";
import { ADMIN, SCHOOL } from "./Auth/userTypes";
import { AppContext } from "./components/context/AppContext";

// onboarding start
import OnbordingLayout from "./components/onboarding/OnbordingLayout";

import Login from "./components/onboarding/Login";
import ForgotPassword from "./components/onboarding/ForgotPassword";
import Varification from "./components/onboarding/Varification";
import ResetPassword from "./components/onboarding/ResetPassword";

import AdminLayout from "./components/portal/superAdmin/AdminLayout";

// onboarding end
import PortalLayout from "./components/portal/PortalLayout";
import SuperAdminDashboard from "./components/portal/superAdmin/dashboard/SuperAdminDashboard";
import SalesHistory from "./components/portal/superAdmin/salesHistory/SalesHistory";
import SalesHistoryDetails from "./components/portal/superAdmin/salesHistory/SaleHistoryDetails";
import School from "./components/portal/superAdmin/school/School";
import SchoolDetail from "./components/portal/superAdmin/school/SchoolDetail";
import SchoolForm from "./components/portal/superAdmin/school/forms/SchoolForm";
import AddFee from "./components/portal/superAdmin/school/forms/AddFee";
import Classes from "./components/portal/superAdmin/classes/Classes";
import ClassForm from "./components/portal/superAdmin/classes/forms/ClassForm";
import ClassDetails from "./components/portal/superAdmin/classes/ClassDetails";
import Chapter from "./components/portal/superAdmin/classes/Chapter";

import TestPrepration from "./components/portal/superAdmin/classes/TestPrepration";
import TestEdit from "./components/portal/superAdmin/classes/forms/TestEditForm";
import OptionalQuestions from "./components/portal/superAdmin/classes/forms/OptionalQuestionsFrorm";
import EditChapter from "./components/portal/superAdmin/classes/forms/EditChapterForm";
import AddSubject from "./components/portal/superAdmin/classes/forms/SubjectForm";
import Exams from "./components/portal/superAdmin/classes/Exams";
import AddExamForm from "./components/portal/superAdmin/classes/forms/AddExamForm";
import ExamSubjectDetails from "./components/portal/superAdmin/classes/ExamSubjectDetails";
import GeneralExam from "./components/portal/superAdmin/general_exam/GeneralExam";
import GenExamDetails from "./components/portal/superAdmin/general_exam/GenExamDetails";
import AddGeneralExamsForm from "./components/portal/superAdmin/general_exam/forms/AddGeneralExamsForm";
import AddQuestion from "./components/portal/superAdmin/general_exam/forms/AddQuestion";
import Books from "./components/portal/superAdmin/books/Books";
import BooksForm from "./components/portal/superAdmin/books/forms/BooksForm";
import Video from "./components/portal/superAdmin/videos/Video";
import VideoForm from "./components/portal/superAdmin/videos/forms/VideoForm";
import Test from "./components/portal/superAdmin/test/Test";
import Students from "./components/portal/superAdmin/students/Students";
import StudentDetails from "./components/portal/superAdmin/students/StudentDetails";
import Setting from "./components/portal/superAdmin/setting/Setting";
import Posts from "./components/portal/superAdmin/posts/Posts";
import AddPost from "./components/portal/superAdmin/posts/forms/AddPost";
import Subscription from "./components/portal/superAdmin/subscription/Subscription";
import AddExamQuestion from "./components/portal/superAdmin/classes/forms/AddExamQuestion";
import OtherUser from "./components/portal/superAdmin/otherusers/OtherUser";
import PushNotifications from "./components/portal/superAdmin/pushNotification/PushNotifications";

// school admin
import SchoolAdminDashboard from "./components/portal/schoolAdmin/dashboard/SchoolAdminDashboard";
import Teachers from "./components/portal/schoolAdmin/teachers/Teachers";
import SchoolStudents from "./components/portal/schoolAdmin/school_students/SchoolStudents";
import Notification from "./components/portal/schoolAdmin/notification/Notification";
import Report from "./components/portal/schoolAdmin/report/Report";
import StudentReport from "./components/portal/schoolAdmin/report/forms/StudentReport";
import Notice from "./components/portal/schoolAdmin/notice/Notice";
import AddNotice from "./components/portal/schoolAdmin/notice/form/AddNotice";
import TeacherDetails from "./components/portal/schoolAdmin/teachers/TeacherDetails";
import AddTeacher from "./components/portal/schoolAdmin/teachers/form/AddTeacher";
import SchoolStudentDetails from "./components/portal/schoolAdmin/school_students/forms/SchoolStudentDetails";

import AddSchoolStudent from "./components/portal/schoolAdmin/school_students/forms/AddSchoolStudent";
import PaymentHistory from "./components/portal/schoolAdmin/payment history/PaymentHistory";
import AirtelPayment from "./components/portal/schoolAdmin/payment history/form/AirtelPayment";
import AddReportCard from "./components/portal/schoolAdmin/report/forms/AddReportCard";
import SubscriptionStudentDetails from "./components/portal/superAdmin/subscription/SubscriptionStudentDetails";

import "react-toastify/dist/ReactToastify.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import Spinner from "./components/Utils/widgets/Spinner";

function App() {
  const { showLogin, setShowLogin, spinner } = useContext(AppContext);

  useEffect(() => {
    if (Auth.user()) {
      setShowLogin(Auth.user());
    }
  }, [setShowLogin]);

  return (
    <>
      <ToastContainer theme="dark" />
      {spinner && <Spinner />}

      <BrowserRouter>
        <Suspense fallback="loading">
          <Routes>
            {/* <Route path="" element={<SplashScreen />} /> */}
            <Route exact path="" element={<OnbordingLayout />}>
              <Route
                path=""
                element={
                  showLogin.role === ADMIN || Auth.user()?.role === ADMIN ? (
                    <Navigate to="/admin/dashboard" />
                  ) : showLogin.role === SCHOOL ||
                    Auth.user()?.role === SCHOOL ? (
                    <Navigate to="/school/dashboard" />
                  ) : (
                    <Login />
                  )
                }
              />
              <Route path="admin/login" element={<Login />} />
              <Route
                path="admin/forgot-password"
                element={<ForgotPassword />}
              />
              <Route path="admin/verify-email" element={<Varification />} />
              <Route path="admin/reset-password" element={<ResetPassword />} />
              <Route exact path="login" element={<Login />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="verify-email" element={<Varification />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>
            {/* super admin */}
            <Route exact path="admin" element={<PortalLayout />}>
              <Route
                path=""
                element={
                  showLogin.role === ADMIN || Auth.user()?.role === ADMIN ? (
                    <AdminLayout />
                  ) : (
                    <Navigate to={"/admin/login"} />
                  )
                }
              >
                <Route exact path="" element={<SuperAdminDashboard />} />
                <Route
                  exact
                  path="dashboard"
                  element={<SuperAdminDashboard />}
                />
                <Route path="sales" element={<SalesHistory />} />
                <Route
                  path="sales/user-details"
                  element={<SalesHistoryDetails />}
                />
                <Route
                  path="dashboard/user-details"
                  element={<SalesHistoryDetails />}
                />
                <Route
                  path="dashboard/school-details"
                  element={<SchoolDetail />}
                />

                <Route path="school" element={<School />} />
                <Route path="school/add" element={<SchoolForm />} />
                <Route path="school/edit/:id" element={<SchoolForm />} />
                <Route path="school/:id" element={<SchoolDetail />} />
                <Route path="school/:id/add" element={<AddFee />} />
                <Route path="school/:id/edit/:fid" element={<AddFee />} />

                <Route path="classes" element={<Classes />} />
                <Route path="classes/add" element={<ClassForm />} />
                <Route path="classes/edit/:id" element={<ClassForm />} />
                <Route path="classes/:id" element={<ClassDetails />} />
                <Route path="classes/:id/add" element={<AddSubject />} />
                <Route
                  path="classes/:id/edit/:subId"
                  element={<AddSubject />}
                />

                <Route
                  path="classes/:id/subject/:subId/chapter"
                  element={<Chapter />}
                />

                <Route
                  path="classes/:id/subject/:subId/chapter/add"
                  element={<EditChapter />}
                />

                <Route
                  path="classes/:id/subject/:subId/chapter/edit/:chapterId"
                  element={<EditChapter />}
                />

                <Route
                  path="classes/:id/subject/:subId/chapter/:chapterId"
                  element={<EditChapter />}
                />

                <Route
                  path="classes/:id/subject/:subId/chapter/:chptrId/testprep/:testId"
                  element={<TestPrepration />}
                />

                <Route
                  path="classes/:id/subject/:subId/chapter/:chptrId/testprep/:testId/add"
                  element={<AddQuestion />}
                />

                <Route
                  path="classes/:id/chapter/chapter-details/add"
                  element={<TestEdit />}
                />

                <Route
                  path="classes/:id/subject/:subId/chapter/:chptrId/testprep/:testId/edit/:quesId"
                  element={<AddQuestion />}
                />

                <Route path="optional" element={<OptionalQuestions />} />

                <Route
                  path="classes/:id/subject/:subId/exams"
                  element={<Exams />}
                />

                <Route
                  path="classes/:id/subject/:subId/exams/add"
                  element={<AddExamForm />}
                />
                <Route
                  path="classes/:id/subject/:subId/exams/edit/:examId"
                  element={<AddExamForm />}
                />

                <Route
                  path="classes/:id/subject/:subId/exams/:examId"
                  element={<ExamSubjectDetails />}
                />

                <Route
                  path="classes/:id/subject/:subId/exams/:examId/question/add"
                  element={<AddExamQuestion />}
                />

                <Route
                  path="classes/:id/subject/:subId/exams/:examId/question/:quesId/edit"
                  element={<AddExamQuestion />}
                />

                <Route
                  path="classes/:id/subject/:subId/chapter/:chptrId/add"
                  element={<TestEdit />}
                />

                <Route
                  path="classes/:id/subject/:subId/chapter/:chptrId/edit/:testId"
                  element={<TestEdit />}
                />

                <Route path="general-exams" element={<GeneralExam />} />
                <Route path="general-exams/edit-test" element={<TestEdit />} />
                <Route
                  path="general-exams/add"
                  element={<AddGeneralExamsForm />}
                />
                <Route
                  path="general-exams/exam/edit/:examId"
                  element={<AddGeneralExamsForm />}
                />

                <Route
                  path="general-exams/general-exams-details/:genquesId"
                  element={<GenExamDetails />}
                />
                <Route
                  path="general-exams/general-exams-details/:genquesId/add-question"
                  element={<AddQuestion />}
                />
                <Route
                  path="general-exams/general-exams-details/:genquesId/edit/:editQuesId"
                  element={<AddQuestion />}
                />

                {/* <Route
              path="classes/class-details/chapter/edit-test"
              element={<TestEdit />}
            /> */}

                <Route path="books" element={<Books />} />
                <Route path="books/add-book" element={<BooksForm />} />
                <Route path="video" element={<Video />} />
                <Route path="video/add-videos" element={<VideoForm />} />
                <Route path="test" element={<Test />} />
                <Route path="students" element={<Students />} />
                <Route
                  path="students/student-details/:stdId"
                  element={<StudentDetails />}
                />
                <Route path="setting" element={<Setting />} />
                <Route path="post" element={<Posts />} />
                <Route path="post/add-posts" element={<AddPost />} />
                <Route path="subscription" element={<Subscription />} />
                <Route
                  path="subscription/substud-details"
                  element={<SubscriptionStudentDetails />}
                />

                <Route path="notification" element={<Notification />} />

                <Route path="otherusers" element={<OtherUser />} />

                <Route
                  path="pushNotification"
                  element={<PushNotifications />}
                />
              </Route>
            </Route>

            {/* school dashboard  */}
            <Route path="school" element={<PortalLayout />}>
              <Route
                path=""
                element={
                  showLogin.role === SCHOOL || Auth.user()?.role === SCHOOL ? (
                    <AdminLayout />
                  ) : (
                    <Navigate to={"/login"} />
                  )
                }
              >
                <Route exact path="" element={<SchoolAdminDashboard />} />
                <Route path="dashboard" element={<SchoolAdminDashboard />} />
                <Route path="teachers" element={<Teachers />} />
                <Route
                  path="teachers/:teacherId"
                  element={<TeacherDetails />}
                />
                <Route path="teachers/add" element={<AddTeacher />} />
                <Route
                  path="teachers/edit/:teacherId"
                  element={<AddTeacher />}
                />
                <Route path="students" element={<SchoolStudents />} />
                <Route path="students/add" element={<AddSchoolStudent />} />
                <Route
                  path="students/edit/:studentId"
                  element={<AddSchoolStudent />}
                />
                <Route
                  path="students/:studentId"
                  element={<SchoolStudentDetails />}
                />
                <Route
                  path="class/:classId/add-reportcard"
                  element={<AddReportCard />}
                />
                <Route
                  path="students/:studentId/add-reportcard"
                  element={<AddReportCard />}
                />
                <Route
                  path="reports/add-reportcard"
                  element={<AddReportCard />}
                />

                <Route path="reports" element={<Report />} />

                <Route path="student-report" element={<StudentReport />} />

                {/* <Route
              path="reports/add-student-report"
              element={<AddReportCard />}
            /> */}

                <Route path="payment-history" element={<PaymentHistory />} />
                <Route path="setting" element={<Setting />} />
                <Route path="notifications" element={<Notification />} />

                <Route path="notice" element={<Notice />} />
                <Route path="notice/add-notice" element={<AddNotice />} />

                <Route path="payment-history" element={<PaymentHistory />} />
                <Route
                  path="payment-history/airtel-payment"
                  element={<AirtelPayment />}
                />
              </Route>
            </Route>
            <Route path="*" element={<Navigate to={"/"} />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}
export default App;
