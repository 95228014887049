import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import { useForm, patterns } from "@kaydhiman/react-hook-useform";
import DatePicker from "react-multi-date-picker";
import PrefImg from "../../../../../assets/images/prev_ico.png";
import InputBox from "../../../../Utils/forms/InputBox";
import CustomButton from "../../../../Utils/forms/CustomButton";
import { AppContext } from "../../../../context/AppContext";
import { http } from "../../../../../services/http";
import { toast } from "react-toastify";
import TypeAheadContainer from "../../../../Utils/widgets/TypeAheadContainer";
import { Dropdown, DropdownButton } from "react-bootstrap";

function AddFee() {
  const { setSpinner } = useContext(AppContext);
  const navigate = useNavigate();
  const { id, fid } = useParams();
  const classesSelect = useRef(null);
  const monthSelect = useRef(null);
  const [monthList, setmonthList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [dateValue, setValue] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const format = "YYYY-MM-DD";

  const { values, errors, bindField, setInitialValues } = useForm({
    validations: {
      category: {
        required: true,
      },
      amount: {
        required: true,
        pattern: {
          value: patterns.onlyNumber,
          message: "Enter only Number",
        },
      },
    },
  });
  //function of add fee

  const addFee = (e) => {
    e.preventDefault();
    setSpinner(true);
    http(
      {
        method: fid ? "PUT" : "POST",
        url: `school/${id}/fee${fid ? "/" + fid : " "}`,
        isSecure: true,
        body: {
          ...values,
          class: values?.class?.map((item) => item.id),
          // fee_due_date: values?.month?.map(
          //   (item) => item.value + "-" + values?.school_id
          // ),
          fee_due_date: dateValue.map((date) => date.format()),
        },
      },
      (res) => {
        setSpinner(false);
        toast.success(res.message, {
          autoClose: 3000,
        });
        navigate(`/admin/school/${id}`);
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
    setSpinner(false);
  };

  //class list
  useEffect(() => {
    http(
      {
        method: "GET",
        url: `class`,
        isSecure: true,
      },
      (res) => {
        setClassList(res.data);
      }
    );
  }, [setClassList]);

  //api for get single feee details

  const getSingleFee = useCallback(() => {
    fid &&
      http(
        {
          method: "GET",
          url: `school/${id}/fee/${fid}`,
          isSecure: true,
        },
        (res) => {
          setInitialValues({
            ...res.data,
            class: res.data?.fee_classes?.map((item) => item.class),
          });
        }
      );
  }, [setInitialValues, id, fid]);

  useEffect(() => {
    getSingleFee();
  }, [getSingleFee]);

  return (
    <div className="update_user add_skool row">
      <div className="col-md-12">
        <div className="sup_history">
          <div className="scale_data_header">
            <h2 className="class_head">
              <Link to={`/admin/school/${id}`} className="prev_btn">
                <span className="backBtn">
                  <img className="backPg" src={PrefImg} alt="Back button" />
                </span>
              </Link>
              {`${fid ? "Edit" : "Add"}`} Fee
            </h2>
          </div>
          <div className="data_add_form admin-form">
            <form onSubmit={addFee}>
              <div className="row">
                <div className="mb-4 col-lg-3 col-sm-4">
                  <InputBox
                    label="Category"
                    type="text"
                    id="Category"
                    name="Category"
                    placeholder="Enter Category"
                    classname="form-control"
                    {...bindField("fee_category")}
                    error={errors.fee_category}
                  />
                </div>
                <div className="mb-4 col-lg-3 col-sm-4">
                  <label htmlFor="class" className="form-label">
                    Class
                  </label>

                  <TypeAheadContainer
                    ref={classesSelect}
                    id="feeClass"
                    labelKey={(item) => item.name}
                    name="class"
                    placeholder="Select Classes"
                    options={classList}
                    selected={values.class || []}
                    onChange={(value) => {
                      setInitialValues((prev) => ({ ...prev, class: value }));
                    }}
                    error={errors.class}
                  />
                </div>

                <div className="mb-4 col-lg-3 col-sm-4">
                  <label htmlFor="class" className="form-label">
                    Due Date
                  </label>
                  <div className="d-flex">
                    <div className="dropdown-box">
                      {/* <DropdownButton
                        className={`dropdown-btn`}
                        title={
                          values?.school_id
                          // schoolList?.filter(
                          //   (item) => item === values.school_id
                          // )[0]?.item || <span>DD</span>
                        }
                      >
                        {schoolList?.map((elem) => {
                          // debugger;
                          return (
                            <div
                              key={elem?.id}
                              className="dropdown_projects"
                              onClick={() =>
                                setInitialValues((prevvalues) => ({
                                  ...prevvalues,
                                  school_id: elem,
                                  school_name: elem.name,
                                }))
                              }
                            >
                              <Dropdown.Item
                                className="dropdown-status"
                                wxcn
                                title={elem}
                              >
                                <span
                                  className={
                                    elem === values?.school_id
                                      ? "class_active"
                                      : ""
                                  }
                                >
                                  {elem}
                                </span>
                              </Dropdown.Item>
                            </div>
                          );
                        })}
                      </DropdownButton> */}
                      <DatePicker
                        className="dropdown-btn"
                        value={dateValue}
                        onChange={setValue}
                        placeholder="Select Date"
                        minDate={new Date()}
                        multiple
                        sort
                        format={format}
                      />
                    </div>

                    {/* <TypeAheadContainer
                      ref={monthSelect}
                      id="feeClass"
                      labelKey={(item) => item?.name}
                      name="month"
                      placeholder="Select Month"
                      options={monthList}
                      selected={values?.month || []}
                      onChange={(value) => {
                        setInitialValues((prev) => ({ ...prev, month: value }));
                      }}
                      error={errors.month}
                    /> */}
                  </div>
                </div>

                <div className="mb-4 col-lg-3 col-sm-4">
                  <InputBox
                    label="Amount"
                    type="text"
                    id="amount"
                    name="amount"
                    placeholder="Enter Amount"
                    classname="form-control"
                    {...bindField("amount")}
                    error={errors.amount}
                  />
                </div>
              </div>
              <div className="addon_btn row">
                <div className="col-md-3">
                  <CustomButton
                    type="submit"
                    text="Save"
                    classname="sub-btn"
                    // disabled={!isValid()}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddFee;
