import React from "react";

function ToggleBtn({ label, id, onchange, checked,value }) {
  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        id={id}
        onChange={onchange}
        checked={checked}
        value={value}
      />
      {label && (
        <lable htmlFor={id} className="form-label">
          {label}
        </lable>
      )}
    </div>
  );
}

export default ToggleBtn;
