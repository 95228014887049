import React, { useState, useEffect, useContext } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { useForm } from "@kaydhiman/react-hook-useform";

import PrefImg from "../../../../../assets/images/prev_ico.png";
import InputBox from "../../../../Utils/forms/InputBox";
import { http } from "../../../../../services/http";
import TextArea from "../../../../Utils/forms/TextArea";
import { AppContext } from "../../../../context/AppContext";
import TypeAheadContainer from "../../../../Utils/widgets/TypeAheadContainer";
import { useCallback } from "react";

function AddGeneralExamsForm() {
  const [catagoryType, setCatagoryType] = useState(1);
  const [classList, setClassList] = useState([]);
  const [shcoolList, setSchoolList] = useState([]);
  const [answers, setAnswers] = useState([
    { id: 1, answer: "", is_correct: false },
  ]);
  const { setSpinner } = useContext(AppContext);
  const { examId } = useParams();
  const navigate = useNavigate();

  const { setInitialValues, values, bindField, errors } = useForm({
    validations: {
      initialValues: { class_data: [], sub_data: [], exam_data: [] },
      textArea: {},
      examName: {},
    },
  });
  const changeCatogeryHandler = (e) => {
    const { value } = e.target;
    setCatagoryType(+value);

    if (+value === 1) {
      setAnswers([{ id: 1, answer: "", is_correct: false }]);
    } else if (+value === 2) {
      setAnswers([
        { id: 1, answer: "Yes", is_correct: false },
        { id: 2, answer: "No", is_correct: false },
      ]);
    }
  };

  function handleChange(e) {
    if (+e.target.value > 4 || +e.target.value === 0) {
      toast.error("You Can Add Maximum 4 Or Minimum 1.");
      return;
    }

    if (+e.target.value < answers.length) {
      setAnswers((prevFields) => {
        return prevFields.slice(
          0,
          answers.length - (answers.length - +e.target.value)
        );
      });
    } else {
      let newItems = [];

      for (let i = 1; i <= +e.target.value - answers.length; i++) {
        newItems.push({
          id:
            i === 1
              ? answers.length > 0
                ? answers[answers.length - 1].id + 1
                : 1
              : newItems[newItems.length - 1].id + 1,
          value: null,
        });
      }

      setAnswers((prevFields) => [...prevFields, ...newItems]);
    }
  }

  function handleAdd() {
    if (answers.length <= 3) {
      setAnswers((prevFields) => [
        ...prevFields,
        {
          id: answers.length > 0 ? answers[answers.length - 1].id + 1 : 1,
          answer: "",
          is_correct: false,
          validate: false,
        },
      ]);
    }
  }

  //remove answer count
  function handleRemove() {
    const values = [...answers];
    if (answers.length > 1) {
      values.pop();
      setAnswers(values);
    }
  }

  function fieldChange(id, value, type) {
    setAnswers((prevFields) =>
      prevFields.map((field) => {
        if (field.id === id) {
          switch (type) {
            case "answer":
              field.answer = value;
              break;
            case "isCorrect":
              field.is_correct = value;
              break;
            default:
              break;
          }
        }
        return field;
      })
    );
  }
  const handleAddForm = (e) => {
    e.preventDefault();
    if (answers?.some((item) => item.answer === "") && !examId) {
      toast.error("Please enter all answer fields", {
        autoClose: 3000,
      });
      return;
    }
    if (!answers?.some((item) => item.is_correct) && !examId) {
      toast.success("Please select atleast one correct answer", {
        autoClose: 3000,
      });
      return;
    }
    setSpinner(true);
    http(
      {
        method: examId ? "PUT" : "POST",
        url: `generalExam${examId ? "/" + examId : ""}`,
        isSecure: true,
        body: {
          ...(values.examName && { name: values.examName }),
          class: values?.class?.map((item) => item.id),
          school: values?.school?.map((item) => item.id),
          ...(!examId && {
            answers,
            ...(values.textArea && { question: values.textArea }),
            ...(catagoryType && { exam_question_category_id: catagoryType }),
            ...(catagoryType === 1 ? { answer_count: answers.length } : {}),
          }),
          type: "general",
        },
      },
      (res) => {
        setSpinner(false);
        navigate(`/admin/general-exams`);
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        setSpinner(false);
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  //get school listing
  useEffect(() => {
    !examId &&
      http(
        {
          method: "GET",
          url: "school",
          isSecure: true,
        },
        (res) => {
          setSchoolList(res);
        }
      );
  }, [setSchoolList, examId]);

  //get class listing

  useEffect(() => {
    !examId &&
      http(
        {
          method: "GET",
          url: `class`,
          isSecure: true,
        },
        (res) => {
          setClassList(res.data);
        }
      );
  }, [setClassList, examId]);

  const getSingleGeneralExam = useCallback(() => {
    setSpinner(true);
    examId &&
      http(
        {
          method: "GET",
          url: `generalExam/${examId}`,
          isSecure: true,
        },
        (res) => {
          setInitialValues({
            examName: res.data.name
          });
        }
      );
      setSpinner(false);
  }, [setInitialValues, examId,setSpinner]);

  

  useEffect(() => {
    examId && getSingleGeneralExam();
  }, [getSingleGeneralExam, examId]);

  return (
    <div className="user_details edit_test_dash  px-0">
      <div className="sup_history update_user ">
        <div className="scale_data_header">
          <div className="scale_data_heading">
            <h2 className="class_head">
              <Link to="/admin/general-exams" className="prev_btn">
                <span className="backBtn">
                  <img className="backPg" src={PrefImg} alt="Back button" />
                </span>
              </Link>
              <Link to="/admin/general-exams" className="text-grey">
                General Exams
              </Link>
              &nbsp; / {examId ? "Edit" : "Add"} Exam
            </h2>
          </div>
        </div>
        <div className="data_add_form admin-form">
          <form onSubmit={handleAddForm}>
            <div className="row">
              {/* class dropdown section start */}
              {!examId && (
                <>
                  <div className="mb-4 col-lg-3">
                    <label htmlFor="class" className="form-label">
                      Class
                    </label>

                    <TypeAheadContainer
                      id="genexamClass"
                      labelKey={(item) => item.name}
                      name="class"
                      placeholder="Select Classes"
                      options={classList}
                      selected={values.class || []}
                      onChange={(value) => {
                        setInitialValues((prev) => ({ ...prev, class: value }));
                      }}
                      error={errors.class}
                    />
                  </div>
                  <div className="mb-4 col-lg-3">
                    <label htmlFor="sName" className="form-label">
                      School Name
                    </label>
                    <TypeAheadContainer
                      id="school"
                      labelKey={(item) => item.name}
                      name="school"
                      placeholder="Select School"
                      options={shcoolList?.data}
                      selected={values.school || []}
                      onChange={(value) => {
                        setInitialValues((prev) => ({
                          ...prev,
                          school: value,
                        }));
                      }}
                      error={errors.school}
                    />
                  </div>
                </>
              )}
              <div className="mb-4 col-lg-3">
                <div className="switch-box">
                  <label htmlFor="sName" className="form-label">
                    Exam Name
                  </label>
                  <InputBox
                    type="text"
                    placeholder="Enter exam name"
                    classlable="mb-0"
                    classname="w-100"
                    name="examName"
                    {...bindField("examName")}
                  />
                </div>

                {/* Exam catagory dropdown section end */}
              </div>
            </div>
            {!examId && (
              <>
                <div className="row">
                  <div className="mb-4 col-lg-6 ">
                    <label htmlFor="sName" className="form-label">
                      Question
                    </label>
                    <textarea
                      type="text"
                      className="form-control qa_input input-textarea "
                      rows="3"
                      cols="60"
                      id="qa_input"
                      placeholder="Enter question"
                      name="textarea"
                      {...bindField("textArea")}
                    ></textarea>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-4 col-lg-6 col-xl-3">
                    <label htmlFor="sName" className="form-label">
                      Category
                    </label>
                    {/* Catagory dropdown section star */}
                    <div className="dropdown">
                      <select onChange={changeCatogeryHandler}>
                        <option value="1">Multiple choice</option>
                        <option value="2">Yes/ No</option>
                      </select>
                    </div>
                    {/* Catagory dropdown section end */}
                  </div>
                  {catagoryType === 1 && (
                    <>
                      <div className="mb-4 col-lg-6 col-xl-3">
                        <label htmlFor="sName" className="form-label">
                          Answer count
                        </label>
                        <div className="qaAdd">
                          <input
                            type="number"
                            className="form-control qa_count text-input theme-color answerCount"
                            id="answer_count"
                            name="answer_count"
                            onChange={handleChange}
                            value={answers.length}
                          />

                          {/* Answer Count Button section start */}
                          <div className="ques-btns">
                            <button
                              type="button"
                              className="qa_add add_qa"
                              onClick={() => handleAdd()}
                            ></button>
                            <button
                              type="button"
                              className="qa_add dec_qa"
                              onClick={() => handleRemove()}
                            ></button>
                          </div>
                          {/* Answer Count Button section end */}
                        </div>
                      </div>

                      <div className="row">
                        {answers.map((field, idx) => {
                          return (
                            <div
                              className="mb-4 col-lg-6 col-xl-6"
                              key={idx + 1}
                              id={idx + 1}
                            >
                              <TextArea
                                label={`Answer ${idx + 1}`}
                                id={`aw_count${idx + 1}`}
                                placeholder="Enter Answer"
                                value={field.answer}
                                onChange={(e) =>
                                  fieldChange(
                                    field.id,
                                    e.target.value,
                                    "answer"
                                  )
                                }
                              />

                              <div className="form-check confirmBox">
                                <input
                                  className="form-check-input confirm_input"
                                  type="radio"
                                  name="awn"
                                  checked={field.is_correct}
                                  onChange={(e) =>
                                    fieldChange(
                                      field.id,
                                      e.target.checked,
                                      "isCorrect"
                                    )
                                  }
                                />
                                <label className="form-label confirmlabel">
                                  Right Answer
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>

                {catagoryType === 2 && (
                  <div className="row">
                    <div className="mb-4 col-lg-2 col-6">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Yes
                      </label>
                      <div className="form-check confirmBox">
                        <input
                          className="form-check-input confirm_input"
                          type="radio"
                          name="answer"
                          id="flexCheckDefault"
                          checked={answers[0].is_correct}
                          onChange={(e) =>
                            fieldChange(
                              answers[0].id,
                              e.target.checked,
                              "isCorrect"
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Right Answer
                        </label>
                      </div>
                    </div>
                    <div className="mb-4 col-lg-2 col-6 ">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        No
                      </label>
                      <div className="form-check confirmBox">
                        <input
                          className="form-check-input confirm_input"
                          type="radio"
                          name="answer"
                          id="flexCheckDefault"
                          checked={answers[1].is_correct}
                          onChange={(e) =>
                            fieldChange(
                              answers[1].id,
                              e.target.checked,
                              "isCorrect"
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Right Answer
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            {/* Yes or No section end */}
            <div className="addon_btn row">
              <div className="col-xl-3 col-lg-6">
                <button type="submit" className="btn sub-btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <OptionalQuestions /> */}
      <ToastContainer theme="dark" />
    </div>
  );
}
export default AddGeneralExamsForm;
