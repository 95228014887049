import { useState, forwardRef } from "react";

import { Typeahead } from "react-bootstrap-typeahead";

import arrowImg from "../../../assets/images/drop.svg";

const TypeAheadContainer = forwardRef(
  (
    {
      selected,
      onChange,
      labelKey,
      options,
      placeholder,
      id,
      className,
      name,
      label,
      star,
    },
    ref
  ) => {
    const [selectOpen, setSelectOpen] = useState(false);

    const menuToggleHandler = () => {
      setSelectOpen(selectOpen);
    };

    return (
      <>
        <label htmlFor={id} className="form-label">
          {label} {star && <span className="required">*</span>}
        </label>
        <div className="dropdown-box w-100">
          <Typeahead
            className={`form-control text-input ${className ? className : ""}`}
            id={id}
            labelKey={labelKey}
            multiple
            name={name}
            options={options || []}
            placeholder={placeholder}
            selected={selected}
            onChange={onChange}
            onFocus={() => setSelectOpen(true)}
            onBlur={() => setSelectOpen(false)}
            open={selectOpen}
            ref={ref}
          />
          <span
            className={`arrowimg ${selectOpen ? "rotateArrow" : ""}`}
            onClick={menuToggleHandler}
          >
            <img src={arrowImg} alt="" />
          </span>
        </div>
      </>
    );
  }
);

export default TypeAheadContainer;
