import React, { useState, useCallback, useContext, useEffect } from "react";

import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

// import SchoolAdminData from "../../../../admindata/SchoolAdminData";

// import LikeImg from "../../../../assets/images/like01.svg";
import LikeImg2 from "../../../../assets/images/like02.svg";
import CommentImg from "../../../../assets/images/message.svg";
import CrossIcon from "../../../../assets/images/white_cross.svg";
import { AppContext } from "../../../context/AppContext";
import { http } from "../../../../services/http";
import { toast } from "react-toastify";

function Notice() {
  // const [id, setId] = useState("");
  const [noticeList, SetnoticeList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    id: "",
  });
  // const navigate = useNavigate();
  const { setSpinner } = useContext(AppContext);
  // const [commentInc, setCommentInc] = useState(false);
  // const [allData, setAllData] = useState(SchoolAdminData);
  // const [isChecked,setIsChecked] =useState(false)
  // Post delete functi on start
  // const classDataDelete = (id) => {
  //   setIsModalVisible(true);
  //   setId(id);
  // };

  // const handleOk = () => {
  //   const data = allData?.filter((item) => item?.id !== id);
  //   setAllData(data);
  //   setIsModalVisible(false);
  // };
  // Post dalete function end

  // like unlike function start
  // const handleLike = (id, isChecked) => {
  //   setAllData((pre) => {
  //     return pre.map((likes) => {
  //       if (likes.id === id) {
  //         likes.isChecked = !isChecked;
  //         setCommentInc(!commentInc);
  //       }
  //       return likes;
  //     });
  //   });
  // };
  // like unlike functionend

  //delete notice
  const deleteNotice = (id) => {
    http(
      {
        method: "DELETE",
        url: `notice/${id}`,
        isSecure: true,
      },
      (res) => {
        SetnoticeList((prevData) => {
          return {
            ...prevData,
            data: prevData.data?.filter((items) => items.id !== id),
          };
        });
        setIsModalVisible({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  //notice listing
  const getNotice = useCallback(() => {
    setSpinner(true);
    http(
      {
        method: "GET",
        url: `notice`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        SetnoticeList(res);
      }
    );
  }, [setSpinner]);

  useEffect(() => {
    getNotice();
  }, [getNotice, setSpinner]);

  return (
    <div className="user_details notice-dash">
      <div className="sup_history">
        <div className="scale_data_header border-bottom">
          <div className="scale_data_heading">
            <h2 className="class_head">Notice</h2>
          </div>
          <div className="search-box sing-btn">
            <div className="data_add">
              <Link
                to="add-notice"
                type="submit"
                className="btn sub-btn btn-primary"
              >
                Add Notice
              </Link>
            </div>
          </div>
        </div>
        <div className="">
          {/* First post ssection start */}
          <div className="row">
            {noticeList?.data?.map((card, index) => {
              return (
                <div key={card.id} className="col-md-4">
                  <div className="user_card ">
                    <div className="card-header">
                      <div className="class_head notice_card_header_text">
                        {card.title}
                      </div>
                      <div className="remove_icon">
                        <img
                          src={CrossIcon}
                          alt="Remove post icon"
                          className="img-fluid cross_ico pointer"
                          onClick={() => {
                            setIsModalVisible({
                              isShow: true,
                              id: card.id,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="card-cont">
                        <p>{card.description}</p>
                        <a
                          rel="noreferrer"
                          href={card.link}
                          target="_blank"
                          className="card_link"
                        >
                          {card.link}
                        </a>
                      </div>
                      <div className="card-footer">
                        <span className="total-likes">
                          <img
                            src={LikeImg2}
                            alt="Like img"
                            className="red-liked"
                          />
                          {/* <img
                            src={isChecked ? LikeImg2 : LikeImg}
                            alt="Like img"
                            className="red-liked"
                            onClick={() => handleLike(id, isChecked)}
                          /> */}
                          <span className="user_score">
                            {/* {`${isChecked ? scoplshiplike + 1 : scoplshiplike}`} */}
                            {`${card.likes_count} `}
                            {card.likes_count > 1 ? "Likes" : "Like"}
                          </span>
                        </span>
                        <span className="total-comments">
                          <img
                            src={CommentImg}
                            alt="Like img"
                            className="red-liked"
                          />
                          <span className="user_score">
                            {card.comments_count} Comments
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* Delete confirm modal section start */}
      {isModalVisible.isShow && (
        <Modal
          show={isModalVisible}
          onHide={() => setIsModalVisible(false)}
          className="delete-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Do you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsModalVisible(false)}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => deleteNotice(isModalVisible.id)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Delete confirm modal section end */}
    </div>
  );
}
export default Notice;
