import React from "react";
import classnames from "classnames";
import PrevIcon from "../../../assets/images/prev_ico.png";
import { usePagination, DOTS } from "./usePagination";

// import './pagination.scss';
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange?.length - 1];

  return (
    <div className="dash_pagination float-right">
      <ul
        className={classnames("pagination justify-content-end", {
          [className]: className,
        })}
      >
        <li
          className={classnames("page-item", {
            disabled: currentPage === 1,
          })}
          key="prev"
        >
          {/* <div className="arrow left" /> */}
          <button
            className="page-link navi_btn"
            aria-label="Previous"
            disabled={currentPage === 1}
            onClick={onPrevious}
          >
            <span className="sr-only">
              <img src={PrevIcon} className="prev-icon" alt="PrevIcon" />
            </span>
          </button>
        </li>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <li className="page-item dots" key={index}>
                <span className="page-link bg-transparent">&#8230;</span>
              </li>
            );
          }

          return (
            <li
              className={classnames("page-item ", {
                active: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber)}
              key={index}
            >
              <button className="page-link">{pageNumber}</button>
            </li>
          );
        })}
        <li
          className={classnames("pagination-item", {
            disabled: currentPage === lastPage,
          })}
          key="next"
        >
          <button
            className="page-link navi_btn m-0"
            aria-label="Previous"
            disabled={currentPage === lastPage}
            onClick={onNext}
          >
            <span className="sr-only">
              <img src={PrevIcon} className="next-icon" alt="PrevIcon" />
            </span>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
