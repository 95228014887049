import React, { useEffect, useState, useCallback, useContext } from "react";

import { Link, NavLink } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

import { toast } from "react-toastify";
import { http } from "../../../../services/http";
import { AppContext } from "../../../context/AppContext";
import { ACTIVE, IN_ACTIVE } from "../../../../utilities/constants";
import "react-toastify/dist/ReactToastify.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import SearchForm from "../../../Utils/forms/SearchFrorm";
import debounce from "../../../../utilities/debounce";
import ToggleBtn from "../../../Utils/forms/ToggleBtn";
import PagePagination from "../../../Utils/pagination/PagePagination";
import binImg from "../../../../assets//images/trash.png";
import RefreshImg from "../../../../assets/images/ref.png";
import penImg from "../../../../assets//images/edit_action.png";

function School() {
  const [schoolList, setSchoolList] = useState({});
  const [page, setPage] = useState(1);
  const [getSearch, setSearch] = useState("");
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    id: "",
  });

  const { setSpinner } = useContext(AppContext);

  // Password reset function start

  const resetPassword = (id) => {
    setSpinner(true);
    http(
      {
        method: "PATCH",
        url: `school/${id}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        toast.success(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  // Password reset function end

  // Active or inactive School Api

  const statusOfSchool = (id, isChecked) => {
    setSpinner(true);
    http(
      {
        method: "PATCH",
        url: `school/${id}/activeInactive`,
        isSecure: true,
        body: {
          action: `${isChecked ? ACTIVE : IN_ACTIVE}`,
        },
      },
      (res) => {
        setSpinner(false);

        setSchoolList((prevState) => ({
          ...prevState,
          data: prevState.data.map((item) => {
            if (item.id === id) {
              item.is_active = isChecked ? 1 : 0;
            }
            return item;
          }),
        }));
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  const handleSchoolList = () => {
    setSpinner(true);
    const params = {
      page: page,
      size: 10,
    };
    if (getSearch) {
      params.search = getSearch;
    }

    http(
      {
        method: "GET",
        url: `school?${new URLSearchParams(params)}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setSchoolList(res.data);
      }
    );
  };

  // eslint-disable-next-line
  const debouncedChangeHandler = useCallback(debounce(resetPassword, 300), []);

  // eslint-disable-next-line
  const debouncedChangeHandlers = useCallback(
    debounce((event) => {
      setSearch(event?.target?.value);
      setPage(1);
    }, 500)
  );

  useEffect(() => {
    handleSchoolList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSearch, page]);
  // School list item dalete function

  const deleteSchool = (id) => {
    http(
      {
        method: "DELETE",
        url: `school/${id}`,
        isSecure: true,
      },
      (res) => {
        setSchoolList((prevData) => {
          return {
            ...prevData,
            data: prevData?.data?.filter((items) => items.id !== id),
          };
        });
        setIsModalVisible({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };
  return (
    <div className="school_history">
      <div className="sup_history">
        <div className="scale_data_header">
          <h2 className="class_head">Schools</h2>
          <div className="search-box">
            <SearchForm
              name="search"
              // value={getSearch}
              onChange={debouncedChangeHandlers}
            />
            <div className="data_add">
              <Link to="add" type="submit" className="btn sub-btn btn-primary">
                Add School
              </Link>
            </div>
          </div>
        </div>
        <div className="kina_tables table-responsive">
          <table className="dash_tables">
            <thead>
              <tr>
                <th className="SnumData" width="5%">
                  S.No.
                </th>
                <th className="data01" width="13%">
                  School Name
                </th>
                <th className="data02" width="12%">
                  Location
                </th>
                <th className="data03" width="13%">
                  Email
                </th>
                <th className="data04" width="10%">
                  Phone Number
                </th>
                <th className="data05" width="10%">
                  Registration Date
                </th>

                <th className="data07" width="11%">
                  Reset Password
                </th>
                <th className="data08" width="10%">
                  Active / Inactive
                </th>
                <th className="pricingTh">Action</th>
              </tr>
            </thead>
            <tbody>
              {schoolList?.data?.length ? (
                schoolList?.data?.map((item, index) => (
                  <tr key={item.id}>
                    <td>{schoolList.from + index}</td>
                    <td>
                      <NavLink to={`/admin/school/${item.id}`}>
                        {item.name}
                      </NavLink>
                    </td>
                    <td>{item.location}</td>
                    <td>{item.email}</td>
                    <td>{item.phone_number}</td>
                    <td>{item.registration_date}</td>
                    <td>
                      <ul className="d-flex align-items-center">
                        <li className="p-0">
                          <img
                            src={RefreshImg}
                            alt="Refresh"
                            onClick={() => debouncedChangeHandler(item.id)}
                          />
                        </li>
                        <li className="p-0">
                          <p className="random_password mb-0">
                            {item.password}
                          </p>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ToggleBtn
                        onchange={(e) =>
                          statusOfSchool(item.id, e.target.checked)
                        }
                        checked={item.is_active === 1}
                      />
                    </td>
                    <td>
                      <ul>
                        <li>
                          <NavLink to={`edit/${item.id}`}>
                            <img src={penImg} alt="Edit" />
                          </NavLink>
                        </li>
                        <li>
                          <img
                            src={binImg}
                            alt="Delete"
                            onClick={() => {
                              setIsModalVisible({
                                isShow: true,
                                id: item.id,
                              });
                            }}
                          />
                        </li>
                      </ul>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" className="error01">
                    Data Not Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {schoolList?.total > 0 && schoolList?.total > schoolList?.per_page && (
        <PagePagination
          className="pagination-bar"
          currentPage={schoolList?.current_page}
          totalCount={
            schoolList?.last_page !== undefined
              ? schoolList?.total
              : schoolList?.length
          }
          pageSize={10}
          onPageChange={(page) => setPage(page)}
        />
      )}

      {/* Delete confirm modal section start */}
      {isModalVisible.isShow ? (
        <Modal
          show={isModalVisible}
          onHide={() => setIsModalVisible(false)}
          className="delete-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Do you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsModalVisible(false)}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => deleteSchool(isModalVisible.id)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
      {/* Delete confirm modal section end */}
    </div>
  );
}
export default School;
