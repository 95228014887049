import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";

import BreadCrumb from "../../../../form-widgets/BreadCrumb";
import { AppContext } from "../../../context/AppContext";
import { http } from "../../../../services/http";
import { toast } from "react-toastify";

import binImg from "../../../../assets//images/trash.png";
import penImg from "../../../../assets//images/edit_action.png";
import Accordian from "../../../Utils/widgets/Accordian";

function GenExamDetails() {
  const location = useLocation();
  const [show, setShow] = useState({});
  const [questions, setQuestions] = useState({});
  const [label, setLabel] = useState();
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    id: "",
  });
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { setSpinner } = useContext(AppContext);
  const { genquesId } = useParams();

  const questionList = useCallback(
    ({ page, size }) => {
      setSpinner(true);
      http(
        {
          method: "GET",
          url: `generalExam/${genquesId}/question?page=${
            page ? page : 1
          }&size=${size ? size : 10}`,
          isSecure: true,
        },
        (res) => {
          setQuestions(res.data);
          setSpinner(false);
          setLabel(res.data.data[0].exam.name)
        }
      );
    },
    [genquesId, setSpinner]
  );

  // Delete handle function section start
  const handleDeleteQuestion = (qid) => {
    http(
      {
        method: "DELETE",
        url: `generalExam/${genquesId}/question/${qid}`,
        isSecure: true,
      },
      (res) => {
        setQuestions((prevData) => {
          return {
            ...prevData,
            data: prevData.data.filter((items) => items.id !== qid),
          };
        });
        setIsModalVisible({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });
      }
    );
  };


  // Delete handle function section end

  // Custom Accordian handle function start
  const toggleElement = (id) => {
    setShow((prevValue) => ({
      [id]: !prevValue[id],
    }));
  };

  const closeOption = () => {
    setTimeout(() => {
      setShow(false);
    }, 500);
  };

  useEffect(() => {
    questionList({ page, perPage });
  }, [questionList, page, perPage]);

  // Custom Accordian handle function end

  return (
    <>
      <div className=" user_details prepration_dash px-0">
        <div className="sup_history">
          <div className="scale_data_header">
            <BreadCrumb
              links={[
                {
                  label: "General Exam",
                  url: "/admin/general-exams",
                },
                {
                  label: label ? label : "",
                  style: { color: "black", cursor: "not-allowed" },
                },
              ]}
            />

            <div className="search-box">
              <div className="data_add">
                <Link
                  to="add-question"
                  className="btn sub-btn btn-primary"
                  state={location.state}
                >
                  Add Questions
                </Link>
              </div>
            </div>
          </div>
          <div className="kina_tables table-responsive examDetails">
            <table className="dash_tables w-100">
              <thead>
                <tr>
                  <th className="SnumData">S.No.</th>
                  <th className="questionTh">Questions</th>
                  <th className="pricingTh">Action</th>
                </tr>
              </thead>
              <tbody>
                {questions?.data?.length ? (
                  questions?.data?.map((historyData, index) => {
                    return (
                      <tr key={index}>
                        <td>{questions.from + index}</td>
                        <td>
                           
                            <Accordian question={historyData.question} id={historyData.id} Data={historyData?.answers}/>
                          
                        </td>
                    
                        
                       
                        <td>
                          <ul>
                            <Link to={`edit/${historyData.id}`}>
                              <img src={penImg} alt="penImg" />
                            </Link>
                            <li>
                              <img
                                src={binImg}
                                alt="binImg"
                                onClick={() =>
                                  setIsModalVisible({
                                    isShow: true,
                                    id: historyData.id,
                                  })
                                }
                              />
                            </li>
                          </ul>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="8" className="error01">
                      Data Not Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* Delete confirm modal section start */}
        {isModalVisible.isShow ? (
          <Modal
            show={isModalVisible.isShow}
            onHide={() => setIsModalVisible(false)}
            className="delete-modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>Do you want to delete?</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setIsModalVisible(false)}
              >
                No
              </Button>
              <Button
                variant="primary"
                onClick={() => handleDeleteQuestion(isModalVisible.id)}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ""
        )}
        {/* Delete confirm modal section end */}
      </div>
    </>
  );
}
export default GenExamDetails;
