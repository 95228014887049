import React, { useContext, useEffect, useState, useCallback } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";

import BreadCrumb from "../../../../form-widgets/BreadCrumb";
import { http } from "../../../../services/http";
import { AppContext } from "../../../context/AppContext";
// import InputField from "../../../Utils/forms/InputField";
import debounce from "../../../../utilities/debounce";
import SearchForm from "../../../Utils/forms/SearchFrorm";
import binImg from "../../../../assets/images/trash.png";
import PDFicon from "../../../../assets/images/pdf-file.svg";
import BlueEye from "../../../../assets/images/blue-eye.svg";
import penImg from "../../../../assets/images/edit_action.png";
import editIcon from "../../../../assets/images/white-edit.svg";
import AddIcon from "../../../../assets/images/plus.png";
import PagePagination from "../../../Utils/pagination/PagePagination";

function Chapter() {
  const [show, setShow] = useState({ modal: false, id: "" });
  const [page, setPage] = useState();
  const [getSearch, setSearch] = useState("");
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    ModalId: "",
  });
  const navigate = useNavigate();
  const [chapters, setChapters] = useState([]);

  const { id, subId } = useParams();
  const { setSpinner, size } = useContext(AppContext);
  const breadCrumbs = useLocation();

  const chapterList = () => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: size,
    };
    if (getSearch) {
      params.search = getSearch;
    }

    http(
      {
        method: "GET",
        url: `class/${id}/subject/${subId}/chapter?${new URLSearchParams(
          params
        )}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setChapters(res.data);
      }
    );
  };
  // useCallback(
  //   ({ search, page, size }) => {
  //     setSpinner(true);
  //     http(
  //       {
  //         method: "GET",
  //         url: `class/${id}/subject/${subId}/chapter?page=${
  //           page ? page : 1
  //         }&size=${size ? size : 10}&search=${search ? search : ""}`,
  //         isSecure: true,
  //       },
  //       (res) => {
  //         setSpinner(false);
  //         setChapters(res.data);
  //       }
  //     );
  //   },
  //   [id, setSpinner, subId]
  // );

  // eslint-disable-next-line
  const debouncedChangeHandler = useCallback(
    debounce((event) => {
      setSearch(event?.target?.value);
      setPage(1);
    }, 500)
  );

  // Delete chapter function start

  const deleteChapter = (ModalId) => {
    setSpinner(true);
    http(
      {
        method: "DELETE",
        url: `class/${id}/subject/${subId}/chapter/${ModalId}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setChapters((prevData) => {
          return {
            ...prevData,
            data: prevData?.data?.filter((items) => items.id !== ModalId),
          };
        });
        setIsModalVisible({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  const showModalTitle = (id) => {
    let res;
    chapters?.data?.forEach((item) => {
      if (item.id === id) {
        res = item.files[0].name;
      }
    });
    return res;
  };

  const showPdfHandle = (id) => {
    let res;
    chapters?.data?.forEach((item) => {
      if (item.id === id) {
        res = item?.files[0]?.file;
      }
    });
    return res;
  };

  // Delete Chapter function end

  //get chapter Listing

  useEffect(() => {
    chapterList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSearch, page]);

  return (
    <>
      <div className="user_details subDetails_dash px-0">
        <div className="sup_history">
          <div className="scale_data_header">
            <BreadCrumb
              links={[...(breadCrumbs?.state ? breadCrumbs?.state : "")]}
            />

            <div className="search-box">
              {/* <InputField
                type="text"
                className="form-control searchBar"
                placeholder="Search..."
                onChange={(e) =>
                  debouncedChangeHandler({
                    search: e.target.value,
                    page: 1,
                  })
                }
              /> */}
              <SearchForm name="search" onChange={debouncedChangeHandler} />
              <div className="data_add">
                <span
                  onClick={() => {
                    navigate(`add`, {
                      state: [
                        ...breadCrumbs.state,
                        {
                          url: window.location.pathname,
                        },
                      ],
                    });
                  }}
                  className="btn sub-btn btn-primary"
                >
                  Add Chapter
                </span>
              </div>
            </div>
          </div>
          <div className="kina_tables table-responsive">
            <table className="dash_tables">
              <thead>
                <tr>
                  <th className="SnumData">S.No.</th>
                  <th>Chapter</th>
                  <th>Doc file</th>
                  <th>Tests Prep </th>
                  <th className="pricingTh">Action</th>
                </tr>
              </thead>
              <tbody>
                {chapters?.data?.length ? (
                  chapters?.data?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{chapters.from + index}</td>
                        <td>
                          <span
                            onClick={() => {
                              navigate(`${item.id}`, {
                                state: [
                                  ...breadCrumbs.state,
                                  {
                                    label: item.name,
                                    url: window.location.pathname,
                                  },
                                ],
                              });
                            }}
                            style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                          >
                            {item.name}
                          </span>
                        </td>
                        <td>
                          <ul>
                            <li>
                              <img
                                src={PDFicon}
                                alt={`${item?.files[0]?.file}`}
                                title={`${item?.files[0]?.file}`}
                              />
                            </li>
                            <li>
                              <img
                                src={BlueEye}
                                onClick={() =>
                                  setShow({ modal: true, id: item.id })
                                }
                                alt="Show Icon"
                              />
                            </li>
                          </ul>
                        </td>
                        <td>
                          <ul className="d-flex align-items-baseline">
                            <li>
                              {item.tests.length > 0 && id && subId ? (
                                <span
                                  onClick={() => {
                                    navigate(
                                      `/admin/classes/${id}/subject/${subId}/chapter/${item.id}/testprep/${item.tests[0].id}`,
                                      {
                                        state: [
                                          ...breadCrumbs.state,
                                          {
                                            label: item.name,
                                            url: window.location.pathname,
                                          },
                                        ],
                                      }
                                    );
                                  }}
                                  className="hand text-nowrap"
                                >
                                  <span className="text-blue test_prep_text underline">
                                    {`${
                                      item?.tests[0]?.name?.length > 0
                                        ? `${item?.tests[0]?.name}`
                                        : "____________"
                                    }`}
                                  </span>
                                </span>
                              ) : (
                                <span className="text-blue text-nowrap underline">
                                  {`${
                                    item?.tests[0]?.name?.length > 0
                                      ? `${item?.tests[0]?.name}`
                                      : "____________"
                                  }`}
                                </span>
                              )}
                            </li>
                            <li>
                              {item?.tests[0]?.name?.length > 0 ? (
                                <p
                                  onClick={() => {
                                    navigate(
                                      `${item.id}/edit/${item?.tests[0].id}`,
                                      {
                                        state: [
                                          ...breadCrumbs.state,
                                          {
                                            label: item.name,
                                            url: window.location.pathname,
                                          },
                                        ],
                                      }
                                    );
                                  }}
                                  className="active mb-0 add_chapter_btn"
                                >
                                  <img src={editIcon} alt="Edit" />
                                </p>
                              ) : (
                                <p
                                  onClick={() => {
                                    navigate(`${item.id}/add`, {
                                      state: [
                                        ...breadCrumbs.state,
                                        {
                                          label: item.name,
                                          url: window.location.pathname,
                                        },
                                      ],
                                    });
                                  }}
                                  className="active mb-0 add_chapter_btn"
                                >
                                  <img src={AddIcon} alt="add" />
                                </p>
                              )}
                            </li>
                          </ul>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <label
                              onClick={() => {
                                navigate(`edit/${item.id}`, {
                                  state: [
                                    ...breadCrumbs.state,
                                    {
                                      label: item.name,
                                      url: window.location.pathname,
                                    },
                                  ],
                                });
                              }}
                              className="hand"
                            >
                              <img src={penImg} alt="Edit" />
                            </label>
                            <label className="trash_img hand">
                              <img
                                src={binImg}
                                alt="binImg"
                                onClick={() => {
                                  setIsModalVisible({
                                    isShow: true,
                                    ModalId: item.id,
                                  });
                                }}
                              />
                            </label>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="10" className="error01">
                      Data Not Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Delete confirm modal section start */}
        {isModalVisible.isShow ? (
          <Modal
            show={isModalVisible}
            onHide={() => setIsModalVisible(false)}
            className="delete-modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>Do you want to delete?</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setIsModalVisible(false)}
              >
                No
              </Button>
              <Button
                variant="primary"
                onClick={() => deleteChapter(isModalVisible.ModalId)}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ""
        )}

        {/* Delete confirm modal section end */}

        {chapters?.total > 0 && chapters?.total > chapters?.per_page && (
          <PagePagination
            className="pagination-bar"
            currentPage={chapters?.current_page}
            totalCount={
              chapters?.last_page !== undefined
                ? chapters?.total
                : chapters?.length
            }
            pageSize={size}
            onPageChange={(page) => setPage(page)}
          />
        )}
      </div>

      <Modal
        show={show.modal}
        onHide={() => setShow(false)}
        className="doc-view"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{showModalTitle(show.id)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            title="Doc Preview"
            src={`${process.env.REACT_APP_PDF_URL}${showPdfHandle(show.id)}`}
            frameBorder="0"
            width="100%"
            height="450"
          ></iframe>
        </Modal.Body>
      </Modal>

      {/* Modal Section start */}

      {/* Modal section end */}
    </>
  );
}
export default Chapter;
