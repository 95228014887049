import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";

import { AppContext } from "../../../context/AppContext";
import { http } from "../../../../services/http";

import User01 from "../../../../assets/images/user1.png";
import User02 from "../../../../assets/images/user2.png";
import User04 from "../../../../assets/images/user3.png";
import SentIcon from "../../../../assets/images/sent.svg";
import LikeImg from "../../../../assets/images/like01.svg";
import DisLike from "../../../../assets/images/dislike.svg";
import DisLike2 from "../../../../assets/images/dislike2.svg";
import LikeImg2 from "../../../../assets/images/like02.svg";
import CommentImg from "../../../../assets/images/message.svg";
import CrossIcon from "../../../../assets/images/crossgray.png";
import imageIcon from "../../../../assets/images/profile-bg.png";
import { useForm } from "@kaydhiman/react-hook-useform";

function Posts() {
  const [postId, setPostId] = useState("");
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    id: "",
  });
  const [comment, setcomment] = useState("");
  const [commentType, setCommentType] = useState("");
  const [renderData, setData] = useState({});
  const [mainLike, setMainLike] = useState(false);
  const [renderText, setRenderText] = useState("");
  const [readComment, setReadComment] = useState("");

  const [post, setPost] = useState([]);
  const [singlePost, setSinglePost] = useState({});
  const { setSpinner } = useContext(AppContext);
  // validate comment
  const { values, errors, bindField, isValid } = useForm({
    validations: {
      comment: {
        required: true,
      },
    },
  });
  // All respondse get and show data sidebar section start
  const renderImage = (post) => {
    setData(post);
  };

  const placeholderImgHandler = (e) => {
    e.target.src = '/assets/images/place_holder.jpg'
  }

  const renderTitle = (title) => {
    setRenderText(title);
  };

  // All respondse get and show data sidebar end

  //delet posts api

  //delete the class from api

  const deletPost = (id) => {
    http(
      {
        method: "DELETE",
        url: `post/${id}`,
        isSecure: true,
      },
      (res) => {
        let nextPostID;

        post?.forEach((item, index) => {
          if (item?.id === id) {
            nextPostID = post[index + 1].id;
          }
        });

        setPostId(nextPostID);
        setPost(post?.filter((item) => item.id !== id));
        setSinglePost(res.message);
        setIsModalVisible({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });

        // setSpinner(false);
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  //single post Listing

  // post lisiting
  useEffect(() => {
    setSpinner(true);
    http(
      {
        method: "GET",
        url: `post/${postId}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setSinglePost(res.data);
      }
    );
    http(
      {
        method: "GET",
        url: `post/${postId}/comment`,
        isSecure: true,
      },
      (res) => {
        setReadComment(res.data);
      }
    );
  }, [setSpinner, setSinglePost, postId]);

  // post lisiting
  useEffect(() => {
    setSpinner(true);
    http(
      {
        method: "GET",
        url: "post",
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setPost(res.data);
        if (res.data.length > 0) {
          setPostId(res.data[0].id);
        }
      }
    );
  }, [setSpinner]);

  const getall = () => {
    setSpinner(true);
    http(
      {
        method: "GET",
        url: "post",
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setPost(res.data);
      }
    );

    setSpinner(true);
    http(
      {
        method: "GET",
        url: `post/${postId}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setSinglePost(res.data);
      }
    );

    http(
      {
        method: "GET",
        url: `post/${postId}/comment`,
        isSecure: true,
      },
      (res) => {
        setReadComment(res.data);
      }
    );
  };

  // add comment
  const addComment = (e) => {
    e.preventDefault();

    // debugger;
    http(
      {
        method: "POST",
        url: `post/${singlePost.id}/comment`,
        isSecure: true,
        body: { post_id: singlePost.id, ...values },
      },
      (res) => {
        getall();
        setcomment("");
        // console.log(">>>>>>>>>>>>>", res.message);
        toast.success("Post added successfully", {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  const handleLikeApi = () => {
    http(
      {
        method: "POST",
        url: `post/${postId}/like`,
        isSecure: true,
        // body: { post_id: id, comment: comment },
      },
      (res) => {
        getall();
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  const commentLikeApi = (commentID) => {
    http(
      {
        method: "POST",
        url: `post/${postId}/comment/${commentID}/commentVote`,
        isSecure: true,
        body: { type: "up" },
      },
      (res) => {
        // console.log("get type", res);
        getall();

        setCommentType(res);
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  const commentDisLikeApi = (commentID) => {
    http(
      {
        method: "POST",
        url: `post/${postId}/comment/${commentID}/commentVote`,
        isSecure: true,
        body: { type: "down" },
      },
      (res) => {
        getall();
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  return (
    <div className="post_dash">
      <div className="sup_history">
        <div className="scale_data_header border-bottom">
          <h2 className="class_head">Posts</h2>
          <div className="search-box  sing-btn">
            <div className="data_add">
              <Link
                to="add-posts"
                type="submit"
                className="btn sub-btn btn-primary"
              >
                Add Post
              </Link>
            </div>
          </div>
        </div>
        {post.length > 0 ? (
          <div className="row">
            <div className="col-xl-3 col-md-4 small-posts">
              {post.length ? (
                post.map((item, index) => {
                  return (
                    <div className="user_card px-0 post01" key={index}>
                      <div className="remove_icon">
                        <img
                          src={CrossIcon}
                          alt="Remove post icon"
                          className="img-fluid cross_ico pointer"
                          onClick={() => {
                            setIsModalVisible({
                              isShow: true,
                              id: item.id,
                            });
                          }}
                        />
                      </div>
                      <div
                        className="call_post d-flex flex-column"
                        onClick={() => {
                          setPostId(item.id);
                          renderTitle(item.title);
                        }}
                      >
                        <div
                          className="card_header d-flex justify-content-between float-start w-100"
                        // onClick={() => {
                        //   setPostId(item.id);
                        //   renderTitle(item.title);
                        // }}
                        >
                          <div className="user_heading d-flex flex-column">
                            <span className="user_name">{item.user.name}</span>
                            <span className="user_score">{item.title}</span>
                          </div>
                        </div>

                        {/* Main Image/Left-Side section start*/}
                        {item.image ? (
                          <div
                            className="card-body p-0"
                            onClick={() => {
                              renderImage(item.image);
                              setPostId(item.id);
                            }}
                          >
                            <img
                              src={`${item.image
                                ? `${process.env.REACT_APP_MEDIA_URL}/${item.image}`
                                : imageIcon
                                } `}
                              onError={placeholderImgHandler}
                              alt="Post img"
                              className="post-image"
                            />
                          </div>
                        ) : (
                          <div className="card-body p-0"></div>
                        )}
                        {/* Main Image/Left-Side section end*/}

                        <div className="card-footer d-flex">
                          <span className="total-likes">
                            <img
                              src={item.is_liked === 1 ? LikeImg2 : LikeImg}
                              alt="Like img"
                              className="red-liked"
                            // onClick={() => {
                            //   setMainLike(!mainLike);
                            //   handleLikeApi();
                            // }}
                            />
                            {/* <img
                            onClick={() => {
                              setMainLike(!mainLike);
                              handleLikeApi();
                            }}
                            src={
                              singlePost?.is_liked === 1 ? LikeImg2 : LikeImg
                            }
                            alt="Like img"
                            className="red-liked"
                          /> */}
                            <span className="user_score">
                              {`${item.likes_count
                                ? item.likes_count
                                : item.likes_count
                                } Likes`}
                            </span>
                          </span>
                          <span className="total-comments">
                            <img
                              src={CommentImg}
                              alt="Like img"
                              className="red-liked"
                            />
                            <span className="user_score">
                              {item.comments_count} Comments
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h1 className="greyAlert"> No post available !</h1>
              )}
            </div>

            <div className="col-xl-9 col-md-8 full-post d-none d-sm-block">
              <div className="user_card px-0 post03">
                <div className="card_header d-flex justify-content-between float-start">
                  <div className="user_heading d-flex flex-column">
                    <span className="user_name">{singlePost?.user?.name}</span>
                    <span className="user_score">{singlePost.title}</span>
                  </div>
                </div>
                <div className="card-body p-0">
                  {singlePost.image && (
                    <img
                      src={`${process.env.REACT_APP_MEDIA_URL}/${singlePost?.image}`}
                      alt="Post img"
                      className="big_post_image"
                      onError={placeholderImgHandler}
                    />
                  )}
                </div>
                {singlePost?.link && (
                  <div className="link_section mb-4">
                    <a
                      rel="noreferrer"
                      href={singlePost?.link}
                      target="_blank"
                      className="card_link"
                    >
                      {singlePost?.link}
                    </a>
                  </div>
                )}

                <div className="card-footer">
                  <span className="total-likes">
                    <img
                      onClick={() => {
                        setMainLike(!mainLike);
                        handleLikeApi();
                      }}
                      src={singlePost?.is_liked === 1 ? LikeImg2 : LikeImg}
                      alt="Like img"
                      className="red-liked"
                    />
                    <span className="user_score">
                      {`${singlePost?.likes_count ? singlePost?.likes_count : 0
                        } Like`}
                    </span>
                  </span>
                  <span className="total-comments">
                    <img
                      src={CommentImg}
                      alt="Like img"
                      className="red-liked"
                    />
                    <span className="user_score">
                      {`${singlePost?.comments_count
                        ? singlePost?.comments_count
                        : 0
                        } Comment`}
                    </span>
                  </span>
                </div>

                <div className="comment-box d-flex ">
                  {/* <img
                    src={User04}
                    alt="User img"
                    className="postUser img-fluid  me-3"
                  /> */}
                  <img
                    src={`${singlePost?.user?.file
                      ? `${process.env.REACT_APP_MEDIA_URL}/${singlePost?.user?.file}`
                      : User01
                      } `}
                    alt="Post img"
                    className="postUser img-fluid  me-3"
                    onError={placeholderImgHandler}
                  />
                  <form style={{ width: "100%" }} onSubmit={addComment}>
                    <div className="admin-form">
                      <input
                        type="text"
                        className="form-control text-input comment-input"
                        id="sName"
                        value={comment}
                        name="comment"
                        placeholder="Add a comment.... "
                        autoComplete="off"
                        onChange={(e) => setcomment(e.target.value)}
                        {...bindField("comment")}
                      />

                      <button
                        type="submit"
                        className="btn sub-btn btn-primary"
                        disabled={!isValid()}
                      >
                        <img
                          src={SentIcon}
                          alt="User img"
                          className="img-fluid "
                        />
                      </button>
                    </div>
                    {errors && <span className="error"> {errors.comment}</span>}
                  </form>
                </div>

                <div className="comment-section">
                  {readComment?.length > 0
                    ? readComment.map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="comment-box border-0 d-flex ">
                            {/* <img
                                src={User01}
                                alt="User img"
                                className="postUser img-fluid  me-3"
                              /> */}
                            <img
                              src={`${data?.user?.file
                                ? `${process.env.REACT_APP_MEDIA_URL}/${data?.user?.file}`
                                : User01
                                } `}
                              alt="Post img"
                              className="postUser img-fluid  me-3"
                              onError={placeholderImgHandler}
                            />
                            <div className="past-comment">
                              <div className="user_heading d-flex flex-row">
                                <span className="user_name">
                                  {data?.user.name}
                                </span>
                                &nbsp; &nbsp;
                                <span className="user_score">
                                  {data?.comment}
                                </span>
                              </div>

                              <div className="card-footer border-0 w-100">
                                <span className="total-likes border-0">
                                  <img
                                    src={
                                      data?.comment_votes?.type === "up"
                                        ? LikeImg2
                                        : LikeImg
                                    }
                                    alt="Like img"
                                    className="red-liked"
                                    onClick={() => commentLikeApi(data?.id)}
                                  />
                                  <span className="user_score">
                                    {data?.commentUpCount}
                                  </span>
                                </span>
                                <span className="total-comments">
                                  <img
                                    src={
                                      data?.comment_votes?.type === "down"
                                        ? DisLike2
                                        : DisLike
                                    }
                                    alt="Like img"
                                    className="red-liked"
                                    onClick={() =>
                                      commentDisLikeApi(data?.id)
                                    }
                                  />
                                  {data?.commentDownCount}
                                </span>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })
                    : ""}

                  {/* <div className="comment-box border-0 d-flex ">
                    <img
                      src={User02}
                      alt="User img"
                      className="postUser img-fluid  me-3"
                    />
                    <div className="past-comment">
                      <div className="user_heading d-flex flex-row">
                        <span className="user_name">Harry Ngoie</span> &nbsp;
                        &nbsp;
                        <span className="user_score">hey, wassup</span>
                      </div>

                      <div className="card-footer border-0 w-100">
                        <span className="total-likes border-0">
                          <img
                            src={LikeImg2}
                            alt="Like img"
                            className="red-liked"
                          />
                          <span className="user_score">8</span>
                        </span>
                        <span className="total-comments">
                          <img
                            src={DisLike}
                            alt="Like img"
                            className="red-liked"
                          />
                        </span>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h1 className="text-center">No Data Available</h1>
        )}
      </div>

      {isModalVisible.isShow ? (
        <Modal
          show={isModalVisible}
          onHide={() => setIsModalVisible(false)}
          className="delete-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Do you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsModalVisible(false)}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => deletPost(isModalVisible.id)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
}
export default Posts;
