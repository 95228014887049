import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { DropdownButton, Dropdown } from "react-bootstrap";
import { useForm, patterns } from "@kaydhiman/react-hook-useform";
import { toast } from "react-toastify";

import InputBox from "../../../../Utils/forms/InputBox";
import { http } from "../../../../../services/http";
import CustomButton from "../../../../Utils/forms/CustomButton";
import { AppContext } from "../../../../context/AppContext";

import PrefImg from "../../../../../assets/images/prev_ico.png";
import UploadProfile from "../../../../Utils/widgets/UploadProfile";

function AddSchoolStudent() {
  const { setSpinner } = useContext(AppContext);
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState();
  const [classList, setClassList] = useState();
  const { studentId } = useParams();

  const { values, errors, bindField, setInitialValues } = useForm({
    validations: {
      email: {
        pattern: {
          value: patterns.email,
          message: "Invalid email address.",
        },
        required: true,
      },
      phone_number: {},
      name: {
        required: true,
      },
      roll_number: {},
    },
  });

  const handleUploadImg = (e) => {
    setProfileImage({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  const handleImage = async (e) => {
    e.preventDefault();
    if (!profileImage.file) {
      addStudent();
    } else {
      const formData = new FormData();
      formData.append("type", "photo");
      formData.append("file", profileImage.file);
      setSpinner(true);
      await http(
        {
          method: "POST",
          url: `upload-image`,
          isSecure: true,
          contentType: "multipart/form-data",
          body: formData,
        },
        (res) => {
          setSpinner(false);
          addStudent(res.content.imageName);
        },
        (res) => {
          setSpinner(false);
          toast.error(res.message, {
            autoClose: 3000,
          });
        }
      );
    }
  };

  // add student  api

  const addStudent = (img) => {
    setSpinner(true);
    http(
      {
        method: studentId ? "PUT" : "POST",
        url: `student/${studentId ? studentId : " "}`,
        isSecure: true,
        body: {
          ...values,
          class: values?.class_id,
          ...(img ? { file: img } : ""),
        },
      },
      (res) => {
        setSpinner(false);
        toast.success(res.message, {
          autoClose: 3000,
        });
        navigate("/school/students");
      },
      (res) => {
        setSpinner(false);
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  //get single teacher

  const getStudent = useCallback(() => {
    http(
      {
        method: "GET",
        url: `student/${studentId}`,
        isSecure: true,
      },
      (res) => {
        setInitialValues({
          ...res.data,
          phone_number: res.data.user.phone_number,
          email: res.data.user.email,
          name: res.data.user.name,
          class_data: res.data.class.name,
        });
        setProfileImage({
          url: process.env.REACT_APP_MEDIA_URL + res.data.file,
        });
      }
    );
  }, [setInitialValues, studentId]);

  useEffect(() => {
    studentId && getStudent();
  }, [getStudent, studentId]);

  useEffect(() => {
    http(
      {
        method: "GET",
        url: `class`,
        isSecure: true,
      },
      (res) => {
        setClassList(res);
      }
    );
  }, []);

  return (
    <div className="update_user add_teacher">
      <div className="sup_history">
        <div className="scale_data_header">
          <h2 className="class_head">
            <Link to="/school/students" className="prev_btn">
              <span className="backBtn">
                <img className="backPg" src={PrefImg} alt="Back button" />
              </span>
            </Link>
            {studentId ? "Edit" : "Add"} Student
          </h2>
        </div>
        <div className="data_add_form admin-form">
          <form onSubmit={handleImage}>
            <div className="row">
              <div className="col-lg-3">
                <UploadProfile
                  picture={profileImage}
                  label="Upload Profile Photo"
                  required="true"
                  {...bindField("file")}
                  error={errors.file}
                  onChange={handleUploadImg}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="mb-2 col-lg-3">
                <InputBox
                  label="Student Name"
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter student name"
                  classname="form-control"
                  {...bindField("name")}
                  error={errors.name}
                  required="true"
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-2 col-lg-3">
                <label htmlFor="class" className="form-label">
                  Class<span className="required">*</span>
                </label>
                <div className="dropdown-box">
                  <DropdownButton
                    className={`dropdown-btn`}
                    title={
                      values?.class_data ? (
                        values?.class_data
                      ) : (
                        <span>Select Class</span>
                      )
                    }
                  >
                    {classList?.data?.map((elem) => {
                      return (
                        <div
                          key={elem?.id}
                          className="dropdown_projects"
                          onClick={() => {
                            setInitialValues((prev) => ({
                              ...prev,
                              class_data: elem.name,
                              class_id: elem.id,
                            }));
                          }}
                        >
                          <Dropdown.Item
                            className="dropdown-status"
                            title={elem?.class}
                          >
                            <span
                              className={
                                elem?.name === values?.class_data
                                  ? "class_active"
                                  : " "
                              }
                            >
                              {elem?.name}
                            </span>
                          </Dropdown.Item>
                        </div>
                      );
                    })}
                  </DropdownButton>
                  {/* {!values.class_id ? <span className="text-danger">Please Select Class</span> : ""} */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="mb-2 col-lg-3">
                <InputBox
                  label="Roll No"
                  type="text"
                  id="roll_number"
                  name="roll_number"
                  placeholder="Enter roll no "
                  classname="form-control"
                  {...bindField("roll_number")}
                  error={errors.roll_number}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-2 col-lg-3">
                <InputBox
                  label="Phone No"
                  type="text"
                  id="phone_number"
                  name="phone_number"
                  placeholder="Enter phone no"
                  classname="form-control"
                  {...bindField("phone_number")}
                  error={errors.phone_number}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-2 col-lg-3">
                <InputBox
                  label="Email"
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter email"
                  classname="form-control"
                  {...bindField("email")}
                  error={errors.email}
                  required="true"
                />
              </div>
            </div>

            <div className="addon_btn row">
              <div className="col-md-3">
                <CustomButton
                  text="Submit"
                  type="submit"
                  classname="sub-btn"
                  disabled={
                    !values.name ||
                    !values.email ||
                    !values.class_id ||
                    !profileImage?.url
                  }
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default AddSchoolStudent;
