import React, { useState } from "react";
import { Link } from "react-router-dom";

import AddMoney from "../../../../../assets/images/add_money.png";
import SendMoney from "../../../../../assets/images/send-money.png";
import PrefImg from "../../../../../assets/images/prev_ico.png";

function AirtelPayment() {
  const [sendPayment, setSendPayment] = useState(true);

  return (
    <div className="airtel-money payment-dashboard skoolDash">
      <div className="sup_history">
        <div className="scale_data_header border-bottom">
          <div className="scale_data_heading">
            <h2 className="class_head">
              <Link to="/school/payment-history" className="prev_btn">
                <span className="backBtn">
                  <img className="backPg" src={PrefImg} alt="Back button" />
                </span>
              </Link>
              Airtel Payment
            </h2>
          </div>
        </div>
        <div className="data_add_form admin-form">
          <div className="row payment_cont air_pay">
            <div className="col-xxl-3 col-md-6 col-sm-12">
              <div className="paymentOpt">
                {/* <Link to=""> */}
                <button onClick={() => setSendPayment(false)} className="btn">
                  <img
                    className={sendPayment ? "" : "activePayment"}
                    src={AddMoney}
                    alt="Add Money"
                  />
                  <h3>Add Payments</h3>
                </button>
                {/* </Link> */}
                {/* <Link to=""> */}
                <button onClick={() => setSendPayment(true)} className="btn">
                  <img
                    className={sendPayment ? "activePayment" : ""}
                    src={SendMoney}
                    alt="Send Money"
                  />
                  <h3>Send Payments</h3>
                </button>
                {/* </Link> */}
              </div>
              <div className="radio_form admin-form">
                <h2 className="class_head w-100">Send Money </h2>
                <div className=" radio_inputs row">
                  <div className=" col-xl-6 col-md-6 ">
                    <input
                      className="form-radio-input confirm_input"
                      type="radio"
                      value=""
                      name="payment"
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label confirmBox"
                      htmlFor="flexCheckDefault"
                    >
                      Mobile
                    </label>
                  </div>
                  <div className=" col-xl-6 col-md-6">
                    <input
                      className="form-radio-input  confirm_input"
                      type="radio"
                      value=""
                      name="payment"
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label confirmBox"
                      htmlFor="flexCheckDefault"
                    >
                      Account Number
                    </label>
                  </div>
                </div>
                <div className="mb-4 form-group">
                  <label htmlFor="T_Name" className="form-label">
                    Mobile Number <span className="required">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control text-input"
                    id="teach_Name"
                    placeholder="Enter Mobile  number"
                  />
                </div>
                <div className="mb-4 form-group">
                  <label htmlFor="T_Name" className="form-label">
                    Amount <span className="required">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control text-input"
                    id="teach_Name"
                    placeholder="Enter amont"
                  />
                </div>
                <div className="mb-4 form-group">
                  <label htmlFor="T_Name" className="form-label">
                    Description<span> (Optional) </span>
                  </label>
                  <input
                    type="text"
                    className="form-control text-input"
                    id="teach_Name"
                    placeholder="Enter description"
                  />
                </div>
                <div className="addon_btn row">
                  <div className=" col-lg-12 mt-3 ">
                    <Link
                      to="/payment-history"
                      // type="submit"
                      className="btn sub-btn btn-primary"
                    >
                      Proceed
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AirtelPayment;
