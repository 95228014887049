import React from 'react';

import TestListing from './TestListing';
import SearchForm from '../../../Utils/forms/SearchFrorm';
import Pagination from '../../../Utils/pagination/Pagination';

function Test() {
    return (
        <div className="school_history test_dash">
            <div className="sup_history">
                <div className="scale_data_header">
                    <div className="scale_data_heading">
                        <h2 className="class_head">Tests</h2>
                        <div className="data_desc tabs_desc ">
                            {/* <NavLink className="sub-btn active active01">Class - V</NavLink>
                            <NavLink className="sub-btn">Class - VI</NavLink>
                            <NavLink type="submit" className="sub-btn">Class - VII</NavLink>
                            <NavLink type="submit" className="sub-btn">Class - VIII</NavLink>
                            <NavLink type="submit" className="sub-btn">Class - IX</NavLink>
                            <NavLink type="submit" className="sub-btn">Class - X</NavLink>
                            <NavLink type="submit" className="sub-btn">Class - XI</NavLink>
                            <NavLink type="submit" className="sub-btn">Class - XII</NavLink> */}
                        </div>
                    </div>
                    <div className="search-box">
                        <SearchForm />
                        <div className="data_add">
                            <a href="add_test.html" type="submit" className="btn sub-btn btn-primary">Add Test</a>
                        </div>
                    </div>
                </div>
                <TestListing />
            </div>
            <Pagination />
        </div >
    )
}
export default Test;