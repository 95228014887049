import React, { createContext, useState } from "react";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [showLogin, setShowLogin] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [size] = useState(10);
  const [toast, setToast] = useState({
    isShow: false,
    message: "",
    type: "success",
  });
  const [userLang, setuserLang] = useState("en");

  return (
    <AppContext.Provider
      value={{
        showLogin,
        setShowLogin,
        spinner,
        setSpinner,
        toast,
        setToast,
        userLang,
        setuserLang,
        size,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
