import React from "react";
import { toast } from "react-toastify";
import TextArea from "../components/Utils/forms/TextArea";

function QuestionMaker({
  onChange,
  value,
  catagoryType,
  setCatagoryType,
  answers,
  setAnswers,
}) {
  const changeCatogeryHandler = (e) => {
    const { value } = e.target;
    setCatagoryType(+value);

    if (+value === 1) {
      setAnswers([{ id: 1, answer: "", is_correct: false }]);
    } else if (+value === 2) {
      setAnswers([
        { id: 1, answer: "Yes", is_correct: false },
        { id: 2, answer: "No", is_correct: false },
      ]);
    }
  };

  function handleChange(e) {
    if (+e.target.value > 4 || +e.target.value === 0) {
      toast.error("You Can Add Maximum 4 Or Minimum 1.");
      return;
    }

    if (+e.target.value < answers.length) {
      setAnswers((prevFields) => {
        return prevFields.slice(
          0,
          answers.length - (answers.length - +e.target.value)
        );
      });
    } else {
      let newItems = [];

      for (let i = 1; i <= +e.target.value - answers.length; i++) {
        newItems.push({
          id:
            i === 1
              ? answers.length > 0
                ? answers[answers.length - 1].id + 1
                : 1
              : newItems[newItems.length - 1].id + 1,
          value: null,
        });
      }

      setAnswers((prevFields) => [...prevFields, ...newItems]);
    }
  }

  function fieldChange(id, value) {
    setAnswers((prevAnsers) =>
      prevAnsers.map((answ) => {
        if (answ.id === id) {
          answ.answer = value;
        }
        return answ;
      })
    );
  }

  function correctAnswerHandler(id, value) {
    setAnswers((prevAnsers) =>
      prevAnsers.map((answ) => {
        if (answ.id === id) {
          answ.is_correct = value;
        } else {
          answ.is_correct = false;
        }
        return answ;
      })
    );
  }

  //add answer count
  function handleAdd() {
    if (answers.length <= 3) {
      setAnswers((prevFields) => [
        ...prevFields,
        {
          id: answers.length > 0 ? answers[answers.length - 1].id + 1 : 1,
          answer: "",
          is_correct: false,
        },
      ]);
    }
  }

  //remove answer count
  function handleRemove() {
    const values = [...answers];
    if (answers.length > 1) {
      values.pop();
      setAnswers(values);
    }
  }

  return (
    <>
      <div className="row">
        <div className="mb-4 col-lg-6 ">
          <label htmlFor="sName" className="form-label">
            Question
          </label>
          <textarea
            type="text"
            className="form-control qa_input input-textarea "
            rows="3"
            cols="60"
            id="qa_input"
            placeholder="Enter question"
            name="textarea"
            onChange={onChange}
            value={value}
          ></textarea>
        </div>
      </div>

      <div className="row">
        <div className="mb-4 col-lg-6 col-xl-3">
          <label htmlFor="sName" className="form-label">
            Category
          </label>

          <div className="dropdown">
            <select onChange={changeCatogeryHandler} value={catagoryType}>
              <option value={1}>Multiple choice</option>
              <option value={2}>Yes/ No</option>
            </select>
          </div>
        </div>
        {catagoryType === 1 && (
          <>
            <div className="mb-4 col-lg-6 col-xl-3">
              <label htmlFor="sName" className="form-label">
                Answer count
              </label>
              <div className="qaAdd">
                <input
                  type="number"
                  className="form-control qa_count text-input theme-color answerCount"
                  id="answer_count"
                  name="answer_count"
                  onChange={handleChange}
                  value={answers.length}
                />

                {/* Answer Count Button section start */}
                <div className="ques-btns">
                  <button
                    type="button"
                    className="qa_add add_qa"
                    onClick={handleAdd}
                  ></button>
                  <button
                    type="button"
                    className="qa_add dec_qa"
                    onClick={handleRemove}
                  ></button>
                </div>
                {/* Answer Count Button section end */}
              </div>
            </div>

            <div className="row">
              {answers.map((ans, idx) => {
                return (
                  <div
                    className="mb-4 col-lg-6 col-xl-6"
                    key={idx + 1}
                    id={idx + 1}
                  >
                    <TextArea
                      label={`Answer ${idx + 1}`}
                      id={`aw_count${idx + 1}`}
                      placeholder="Enter Answer"
                      value={ans.answer}
                      onChange={(e) => fieldChange(ans.id, e.target.value)}
                    />

                    <div className="form-check confirmBox">
                      <input
                        className="form-check-input confirm_input"
                        type="radio"
                        name="awn"
                        id={"answer-" + ans.id}
                        checked={ans.is_correct}
                        onChange={(e) =>
                          correctAnswerHandler(ans.id, e.target.checked)
                        }
                      />
                      <label
                        className="form-label confirmlabel"
                        htmlFor={"answer-" + ans.id}
                      >
                        Right Answer
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>

      {catagoryType === 2 && (
        <div className="row">
          <div className="mb-4 col-lg-2 col-6">
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Yes
            </label>
            <div className="form-check confirmBox">
              <input
                className="form-check-input confirm_input"
                type="radio"
                name="answer"
                id="flexCheckDefault"
                checked={answers[0].is_correct}
                onChange={(e) =>
                  correctAnswerHandler(answers[0].id, e.target.checked)
                }
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Right Answer
              </label>
            </div>
          </div>
          <div className="mb-4 col-lg-2 col-6 ">
            <label className="form-check-label" htmlFor="flexCheckDefault">
              No
            </label>
            <div className="form-check confirmBox">
              <input
                className="form-check-input confirm_input"
                type="radio"
                name="answer"
                id="flexCheckDefault"
                checked={answers[1]?.is_correct}
                onChange={(e) =>
                  correctAnswerHandler(answers[1].id, e.target.checked)
                }
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Right Answer
              </label>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default QuestionMaker;
