const StudentAdminData = [
  {
    id: 0,
    student_name: "Ryan Ellis",
    roll_no: 1,
    sno: "1.",
    users: "Steve James",
    class: "V",
    school: "Start Smart",
    book: "Psd Mockup",
    subject: "Digital Electronics",
    dateandtime: "22 Mar 2022, 10:30 am",
    email: "ryanellis@gmail.com",
    payment_date: "22 Mar 2022",
    location: "Toronto, Canada",
    phone_no: "(+1) 723154566",
    question:
      "Identify what is wrong with this scenario : aeoeu as teller, i would like the account screen to be simple so that i do not scroll up and down?",
    qu1: "I would like the account screen to be simple so that i do not have to scrool up and down ?",
    qu2: "It does not say who exactly needs it.",
    qu3: "I would like the account screen to be simple so that i do not have to scrool up and down ?",
    qu4: "It does not say who exactly needs it.",
    registrationDate: "22 Jan 2022",
    chapter: "chapter 01",
    isChecked: false,
    password: "@ZG3Xfsa",
    exam_catagory: "Other",
    doc: "Doc 1",
    scolarship: "10%",
    like: 5,
    comment: 2,
    user_image: "assets/images/user01.svg",
    amount: "$1000",
    price: "$10",
    image: "assets/images/tutor01.png",
    digitalelectronics: 85,
    appliedmathematicII: 62,
    physics: 75,
    computersciences: 95,
    controlanalysis: 60,
    englishcommunication: 45,
    humanvalues: 56,
  },
  {
    id: 1,
    student_name: "Alan Bradley",
    roll_no: 2,
    sno: "2.",
    users: "Timothy Ross",
    class: "VI",
    school: "Passion Education",
    book: "Magazine Mockup",
    subject: "Applied Mathematic II",
    dateandtime: "23 Apr 2022, 11:00 am",
    email: "alanbradley@gmail.com",
    payment_date: "23 Mar 2022",
    location: "Toronto, Canada",
    phone_no: "(+1) 723154566",
    question:
      "Identify what is wrong with this scenario : aeoeu as teller, i would like the account screen to be simple so that i do not scroll up and down?",
    qu1: "I would like the account screen to be simple so that i do not have to scrool up and down ?",
    qu2: "It does not say who exactly needs it.",
    qu3: "I would like the account screen to be simple so that i do not have to scrool up and down ?",
    qu4: "It does not say who exactly needs it.",
    registrationDate: "22 Jan 2022",
    chapter: "chapter 02",
    isChecked: false,
    password: "@ZG3Xdsd",
    exam_catagory: "Final",
    doc: "Doc 2",
    scolarship: "20%",
    like: 2,
    comment: 9,
    user_image: "assets/images/user02.svg",
    amount: "$1500",
    price: "$15.99",
    image: "assets/images/tutor02.png",

    digitalelectronics: 82,
    appliedmathematicII: 55,
    physics: 62,
    computersciences: 73,
    controlanalysis: 99,
    englishcommunication: 81,
    humanvalues: 45,
  },
  {
    id: 2,
    student_name: "Alex Miller",
    roll_no: 3,
    sno: "3.",
    users: "Chris Fox",
    class: "VII",
    school: "Little Feats",
    book: "Psd Mockup",
    subject: "Physics",
    dateandtime: "24 May 2022, 11:30 am",
    email: "alexmiller@gmail.com",
    payment_date: "24 Mar 2022",
    location: "Toronto, Canada",
    phone_no: "(+1) 723154566",
    question:
      "Identify what is wrong with this scenario : aeoeu as teller, i would like the account screen to be simple so that i do not scroll up and down?",
    qu1: "I would like the account screen to be simple so that i do not have to scrool up and down ?",
    qu2: "It does not say who exactly needs it.",
    qu3: "I would like the account screen to be simple so that i do not have to scrool up and down ?",
    qu4: "It does not say who exactly needs it.",
    registrationDate: "22 Jan 2022",
    chapter: "chapter 03",
    isChecked: false,
    password: "@ZG3Xder",
    exam_catagory: "MST 1",
    doc: "Doc 3",
    scolarship: "30%",
    like: 4,
    comment: 1,
    user_image: "assets/images/user03.svg",
    amount: "$2000",
    price: "$20",
    image: "assets/images/postimg.png",
    digitalelectronics: 72,
    appliedmathematicII: 62,
    physics: 55,
    computersciences: 42,
    controlanalysis: 62,
    englishcommunication: 42,
    humanvalues: 30,
  },
  {
    id: 3,
    student_name: "Mary Ann Garrett",
    roll_no: 4,
    sno: "4.",
    users: "Beatrice Jackson",
    class: "VIII",
    school: "Green Sprout",
    book: "Magazine Mockup",
    subject: "Computer Sciences",
    dateandtime: "25 Jun 2022, 12:00 pm",
    email: "maryanngarrett@gmail.com",
    payment_date: "25 Mar 2022",
    location: "Toronto, Canada",
    phone_no: "(+1) 723154566",
    question:
      "Identify what is wrong with this scenario : aeoeu as teller, i would like the account screen to be simple so that i do not scroll up and down?",
    qu1: "I would like the account screen to be simple so that i do not have to scrool up and down ?",
    qu2: "It does not say who exactly needs it.",
    qu3: "I would like the account screen to be simple so that i do not have to scrool up and down ?",
    qu4: "It does not say who exactly needs it.",
    registrationDate: "22 Jan 2022",
    chapter: "chapter 04",
    isChecked: false,
    password: "@ZG3X555",
    exam_catagory: "MST 2",
    doc: "Doc 4",
    scolarship: "40%",
    like: 5,
    comment: 6,
    user_image: "assets/images/user04.svg",
    amount: "$2500",
    price: "$25.99",
    image: "assets/images/tutor01.png",
    digitalelectronics: 85,
    appliedmathematicII: 62,
    physics: 75,
    computersciences: 95,
    controlanalysis: 60,
    englishcommunication: 45,
    humanvalues: 56,
  },
  {
    id: 4,
    student_name: "Louisa Bailey",
    roll_no: 5,
    sno: "5.",
    users: "Natasha Hill",
    class: "IX",
    school: "Kinderhous",
    book: "Psd Mockup",
    subject: "Control Analysis",
    dateandtime: "26 Jul 2022, 12:30 pm",
    email: "louisabailey@gmail.com",
    payment_date: "26 Mar 2022",
    location: "Toronto, Canada",
    phone_no: "(+1) 723154566",
    question:
      "Identify what is wrong with this scenario : aeoeu as teller, i would like the account screen to be simple so that i do not scroll up and down?",
    qu1: "I would like the account screen to be simple so that i do not have to scrool up and down ?",
    qu2: "It does not say who exactly needs it.",
    qu3: "I would like the account screen to be simple so that i do not have to scrool up and down ?",
    qu4: "It does not say who exactly needs it.",
    registrationDate: "22 Jan 2022",
    chapter: "chapter 05",
    isChecked: false,
    password: "@ZG3Xsd5",
    exam_catagory: "MST 3",
    doc: "Doc 5",
    scolarship: "50%",
    like: 9,
    comment: 2,
    user_image: "assets/images/user01.svg",
    amount: "$3000",
    price: "$30",
    image: "assets/images/tutor02.png",
    digitalelectronics: 37,
    appliedmathematicII: 55,
    physics: 62,
    computersciences: 73,
    controlanalysis: 99,
    englishcommunication: 81,
    humanvalues: 45,
  },
  {
    id: 5,
    student_name: "Natasha Hill",
    roll_no: 6,
    sno: "6.",
    users: "Mary Ann Garrett",
    class: "X",
    school: "A Step Ahead",
    book: "Magazine Mockup",
    subject: "English Communication",
    dateandtime: "27 Aug 2022, 1:00 pm",
    email: "natashahill@gmail.com",
    payment_date: "27 Mar 2022",
    location: "Toronto, Canada",
    phone_no: "(+1) 723154566",
    question:
      "Identify what is wrong with this scenario : aeoeu as teller, i would like the account screen to be simple so that i do not scroll up and down?",
    qu1: "I would like the account screen to be simple so that i do not have to scrool up and down ?",
    qu2: "It does not say who exactly needs it.",
    qu3: "I would like the account screen to be simple so that i do not have to scrool up and down ?",
    qu4: "It does not say who exactly needs it.",
    registrationDate: "22 Jan 2022",
    chapter: "chapter 06",
    isChecked: false,
    password: "@ZG3X4s4",
    exam_catagory: "Sem 1",
    doc: "Doc 6",
    scolarship: "60%",
    like: 6,
    comment: 3,
    user_image: "assets/images/user02.svg",
    amount: "$3500",
    price: "$35.99",
    image: "assets/images/postimg.png",
    digitalelectronics: 33,
    appliedmathematicII: 62,
    physics: 55,
    computersciences: 42,
    controlanalysis: 62,
    englishcommunication: 42,
    humanvalues: 30,
  },
  {
    id: 6,
    student_name: "Beatrice Jackson",
    roll_no: 7,
    sno: "7.",
    users: "Louisa Bailey",
    class: "XI",
    school: "Learning Tree",
    book: "Psd Mockup",
    subject: "Human Values",
    dateandtime: "28 Aug 2022, 1:00 pm",
    email: "beatricejackson@gmail.com",
    payment_date: "28 Mar 2022",
    location: "Toronto, Canada",
    phone_no: "(+1) 723154566",
    question:
      "Identify what is wrong with this scenario : aeoeu as teller, i would like the account screen to be simple so that i do not scroll up and down?",
    qu1: "I would like the account screen to be simple so that i do not have to scrool up and down ?",
    qu2: "It does not say who exactly needs it.",
    qu3: "I would like the account screen to be simple so that i do not have to scrool up and down ?",
    qu4: "It does not say who exactly needs it.",
    registrationDate: "22 Jan 2022",
    chapter: "chapter 07",
    isChecked: false,
    password: "@ZG3X8s8",
    exam_catagory: "Sem 2",
    doc: "Doc 7",
    scolarship: "70%",
    like: 1,
    comment: 5,
    user_image: "assets/images/user03.svg",
    amount: "$4000",
    price: "$40",
    image: "assets/images/tutor01.png",
    digitalelectronics: 85,
    appliedmathematicII: 62,
    physics: 75,
    computersciences: 95,
    controlanalysis: 60,
    englishcommunication: 45,
    humanvalues: 56,
  },
  {
    id: 7,
    student_name: "Chirs Fox",
    roll_no: 8,
    sno: "8.",
    users: "Mary Ann Garrett",
    class: "XII",
    school: "Little Green Tree House",
    book: "Magazine Mockup",
    subject: "English Communication",
    dateandtime: "29 Aug 2022, 1:00 pm",
    email: "chirsfox@gmail.com",
    payment_date: "29 Mar 2022",
    location: "Toronto, Canada",
    phone_no: "(+1) 723154566",
    question:
      "Identify what is wrong with this scenario : aeoeu as teller, i would like the account screen to be simple so that i do not scroll up and down?",
    qu1: "I would like the account screen to be simple so that i do not have to scrool up and down ?",
    qu2: "It does not say who exactly needs it.",
    qu3: "I would like the account screen to be simple so that i do not have to scrool up and down ?",
    qu4: "It does not say who exactly needs it.",
    registrationDate: "22 Jan 2022",
    chapter: "chapter 08",
    isChecked: false,
    password: "@ZG3Xf7s7",
    exam_catagory: "Other",
    doc: "Doc 8",
    scolarship: "80%",
    like: 3,
    comment: 8,
    user_image: "assets/images/user04.svg",
    amount: "$4500",
    price: "$45.99",
    image: "assets/images/tutor02.png",
    digitalelectronics: 82,
    appliedmathematicII: 55,
    physics: 62,
    computersciences: 73,
    controlanalysis: 99,
    englishcommunication: 81,
    humanvalues: 45,
  },
];
export default StudentAdminData;
