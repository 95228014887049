import React, { useState, useCallback, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { Modal, Button, DropdownButton, Dropdown } from "react-bootstrap";
import { useForm } from "@kaydhiman/react-hook-useform";
import { toast } from "react-toastify";

import PagePagination from "../../../Utils/pagination/PagePagination";
import { AppContext } from "../../../context/AppContext";
import { http } from "../../../../services/http";

import binImg from "../../../../assets/images/trash.png";
import BlueEye from "../../../../assets/images/blue-eye.svg";
import ReportCard from "../../../../assets/images/report-card.svg";

function Report() {
  const [showModal, setShowModal] = useState({ isShow: false, id: "" });
  const [page, setPage] = useState();
  const [classList, setClassList] = useState();
  const { setSpinner } = useContext(AppContext);
  const [studentList, setStudentList] = useState({});
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    id: "",
  });
  const { setInitialValues, values } = useForm({
    validations: {},
  });

  const [reportList, setReportList] = useState([]);

  // get Report lisiting
  const reportListing = useCallback(() => {
    setSpinner(true);
    http(
      {
        method: "GET",
        url: `class/${values.classId}/reportcard`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setReportList(res);
      }
    );
  }, [setSpinner, values.classId]);

  // get Student lisiting
  const studentListing = () => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: 4,
    };
    if (values.classId) {
      params.class_id = values.classId;
    }
    if (values.categoryId) {
      params.category_id = values.categoryId ? values.categoryId : "";
    }

    http(
      {
        method: "GET",
        url: `student?${new URLSearchParams(params)}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setStudentList(res.data);
      }
    );
  };
  // useCallback(
  //   ({ page }) => {
  //     setSpinner(true);
  //     http(
  //       {
  //         method: "GET",
  //         url: `student?${
  //           values.classId ? "class_id=" + values.classId : ""
  //         }size=4&page=${page ? page : 1}`,
  //         isSecure: true,
  //       },
  //       (res) => {
  //         setSpinner(false);
  //         setStudentList(res.data);
  //       }
  //     );
  //   },
  //   [setSpinner, values.classId]
  // );

  useEffect(() => {
    http(
      {
        method: "GET",
        url: `class`,
        isSecure: true,
      },
      (res) => {
        setClassList(res);
      }
    );
  }, []);

  //delete report card

  const deleteReportCard = (id) => {
    setSpinner(true);
    http(
      {
        method: "DELETE",
        url: `class/${values.classId ? values.classId : " "}/reportcard/${id}`,
        isSecure: true,
      },
      (res) => {
        setReportList((prevData) => {
          return {
            ...prevData,
            data: prevData?.data?.filter((items) => items.id !== id),
          };
        });
        setIsModalVisible({ isShow: false });
        setSpinner(false);
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        setSpinner(false);
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  const modalTitle = (id) => {
    let viewName;
    reportList.data.forEach((item) => {
      if (item.id === id) {
        viewName = item.name;
      }
    });
    return viewName;
  };

  const showModalDataHandle = (id) => {
    let viewreport;
    reportList?.data.forEach((item) => {
      if (item.id === id) {
        viewreport = item.report;
      }
    });
    return viewreport;
  };

  // get Teacher lisiting
  useEffect(() => {
    values.classId && reportListing();
    studentListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportListing, page, values.classId]);
  // useEffect(() => {
  //   studentListing();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [page]);
  return (
    <div className="report-dash student-details">
      <div className="sup_history">
        <div className="scale_data_header border-bottom">
          <div className="scale_data_heading">
            <h2 className="class_head">Reports</h2>
            <div className="btns_header">
              {/* class dropdown section start */}
              <div className="dropdown-box">
                <DropdownButton
                  className={`dropdown-btn`}
                  title={
                    values?.classNm ? (
                      values?.classNm
                    ) : (
                      <span>Select class</span>
                    )
                  }
                >
                  {classList?.data?.map((elem) => {
                    return (
                      <div
                        key={elem?.id}
                        className="dropdown_projects"
                        onClick={() => {
                          setInitialValues({
                            ...values,
                            classId: elem.id,
                            classNm: elem.name,
                          });
                          setPage(1);
                        }}
                      >
                        <Dropdown.Item
                          className="dropdown-status"
                          title={elem?.name}
                        >
                          <span
                            className={
                              elem?.name === values?.classNm && "class_active"
                            }
                          >
                            {elem?.name}
                          </span>
                        </Dropdown.Item>
                      </div>
                    );
                  })}
                </DropdownButton>
              </div>
              {/* class dropdown section end */}
            </div>
          </div>
          <div className="search-box sing-btn">
            <div className="data_add">
              <Link
                to="add-reportcard"
                type="submit"
                className="btn sub-btn btn-primary"
              >
                Add Report
              </Link>
            </div>
          </div>
        </div>
        <div className="cards-data">
          <div className="row">
            <div className="col-md-12">
              <h2 className="class_head mb-4">General Report Cards</h2>
            </div>
            <div className="col-md-12 col-12  text-center d-flex flex-wrap">
              {reportList?.data?.length ? (
                reportList?.data?.map((reportInfo) => (
                  <div className="user_card" key={reportInfo.id}>
                    <div className="card-img">
                      <img src={ReportCard} alt="Report card" />
                    </div>
                    <div className="action-btns">
                      <ul>
                        <li>
                          <img
                            src={BlueEye}
                            onClick={() =>
                              setShowModal({
                                isShow: true,
                                id: reportInfo.id,
                              })
                            }
                            alt="Show Icon "
                          />
                        </li>

                        <Modal
                          show={showModal.isShow}
                          onHide={() => setShowModal({ isShow: false })}
                          className="doc-view"
                          centered
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>
                              <p className="mb-0 notice_card_header_text">
                                {modalTitle(showModal.id)}
                              </p>
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <iframe
                              title="Doc Preview"
                              src={`${
                                process.env.REACT_APP_REPORT_URL
                              }${showModalDataHandle(showModal.id)}`}
                              frameBorder="0"
                              width="100%"
                              height="450"
                            ></iframe>
                          </Modal.Body>
                        </Modal>
                        <li>
                          <img
                            src={binImg}
                            alt="PDF Icon"
                            onClick={() => {
                              setIsModalVisible({
                                isShow: true,
                                id: reportInfo.id,
                              });
                            }}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center">
                  <h4 className="mb-5">Please select class from reports</h4>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="kina_tables table-responsive">
          <h2 className="class_head mb-4">Personal Report Cards</h2>
          <table className="dash_tables">
            <thead>
              <tr>
                <th className="SnumData">S.No.</th>
                <th>Students Name</th>
                <th className="pricingTh">No. of Reports</th>
              </tr>
            </thead>
            <tbody>
              {studentList?.data?.length ? (
                studentList?.data?.map((studentInfo, index) => {
                  return (
                    <tr key={index}>
                      <td>{studentList.from + index}</td>
                      <td>
                        <Link to={`/school/students/${studentInfo.student_id}`}>
                          {studentInfo?.student?.user?.name}
                        </Link>
                      </td>
                      <td>{studentInfo?.student?.report_cards_count}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="8" className="error01">
                    Data Not Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {studentList?.total > 0 &&
          studentList?.total > studentList?.per_page && (
            <PagePagination
              className="pagination-bar"
              currentPage={studentList?.current_page}
              totalCount={
                studentList?.last_page !== undefined
                  ? studentList?.total
                  : studentList?.length
              }
              pageSize={4}
              onPageChange={(page) => setPage(page)}
            />
          )}
      </div>
      {/* Delete confirm modal section start */}
      {isModalVisible.isShow && (
        <Modal
          show={isModalVisible}
          onHide={() => setIsModalVisible(false)}
          className="delete-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Do you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsModalVisible(false)}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => deleteReportCard(isModalVisible.id)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Delete confirm modal section end */}

      {/* Modal Section start */}

      {/* Modal section end */}
    </div>
  );
}
export default Report;
