import Auth from "../Auth/Auth";
import { toast } from "react-toastify";
const getHeaders = ({
  method,
  contentType = "application/json",
  body,
  isSecure,
}) => ({
  method: method,
  headers: {
    Accept: "application/json",
    ...(contentType === "application/json" && { "Content-Type": contentType }),
    ...(isSecure && { Authorization: "Bearer " + Auth.token() }),
  },
  ...(body && {
    body: contentType === "application/json" ? JSON.stringify(body) : body,
  }),
});

export const http = async (
  { method, url, body, isSecure, contentType },
  onSuccess = () => "",
  onError = () => ""
) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/api/${url}`,
    getHeaders({ method, contentType, body, isSecure })
  );

  // REQ SUCCESS WITH RESPONSE
  if (response.status === 200) {
    const res = await response.json();
    onSuccess(res);
  }
  // REQ SUCCESS WITHOUT RESPONSE
  else if (response.status > 200 && response.status <= 299) {
    onSuccess(response);
  }
  // UNAUTHENTICATED ERROR
  else if (response.status === 403) {
    // alert("Your session was expired")
    Auth.logout();
    const res = await response.json();
    onError({ status: 403, message: res.message_description });
  }
  else if (response.status === 401) {
    toast.error("Session was expired", {
      autoClose: 3000,
    });
    // setTimeout(() => { window.location.replace(".") }, 3500);
    Auth.logout();
  }
  // VALIDATION ERRORS
  else if (
    response.status === 400 ||
    response.status === 401 ||
    response.status === 422 ||
    response.status === 413
  ) {
    const res = await response.json();
    onError({ status: 400, message: res.message });
  }
};
