import React, { useEffect, useCallback, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
// import Pagination from "../../../Utils/pagination/Pagination";
// import StudentAdminData from "../../../../admindata/StudentAdminData";
import PagePagination from "../../../Utils/pagination/PagePagination";
import { http } from "../../../../services/http";
import PrefImg from "../../../../assets/images/prev_ico.png";
import UserDp from "../../../../assets/images/user_detail.png";
import { useState } from "react";
import moment from "moment/moment";
import UserDetails from "../../../Utils/widgets/UserDetails";

function SalesHistoryDetails() {
  const [page, setPage] = useState();
  const [parchasedBookList, setParchasedBookList] = useState();
  const [userDetail, setUserDetail] = useState();
  const location = useLocation();
  const userId = location.state.user_id;
  const { setSpinner, size } = useContext(AppContext);
  const handleSalesHistoryList = () => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: size,
      user_id: userId,
    };

    http(
      {
        method: "GET",
        url: `book/sales-history?${new URLSearchParams(params)}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setParchasedBookList(res.data);
      }
    );
  };
  // useCallback(
  //   ({ page }) => {
  //     setSpinner(true);
  //     http(
  //       {
  //         method: "GET",
  //         url: `book/sales-history?page=${
  //           page ? page : 1
  //         }&size=10&user_id=${userId}`,
  //         isSecure: true,
  //       },
  //       (res) => {
  //         setSpinner(false);
  //         setParchasedBookList(res.data);
  //       }
  //     );
  //   },
  //   [setSpinner, userId]
  // );
  useEffect(() => {
    handleSalesHistoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  // get user info
  useEffect(() => {
    http(
      {
        method: "GET",
        url: `user/${userId}`,
        isSecure: true,
      },
      (res) => {
        setUserDetail(res.data);
      }
    );
  }, [userId]);
  return (
    <div className="user_details">
      <div className="col-md-12 ">
        <div className="sup_history">
          <div className="scale_data_header">
            <h2 className="class_head">
              <Link to="/admin/sales" className="prev_btn">
                <span className="backBtn">
                  <img className="backPg" src={PrefImg} alt="Back button" />
                </span>
              </Link>
              User Details
            </h2>
          </div>
          <UserDetails
            img={
              process.env.REACT_APP_MEDIA_URL +
              location?.state?.user?.student?.file
            }
            userName={location.state?.user?.name}
            className={location.state?.user?.student?.class?.name}
            schoolName={
              location.state?.user?.student?.school?.school_detail?.name
            }
            email={userDetail?.email}
            phoneNo={userDetail?.phone_number}
          />
          <div className="purcg_table">
            <div className="scale_data_header">
              <h2 className="class_head">Purchased Books</h2>
            </div>
            <div className="kina_tables">
              <table className="dash_tables">
                <thead>
                  <tr>
                    <th className="SnumData">S.No.</th>
                    <th>Book</th>
                    <th>Subject</th>
                    <th>Date and Time of Purchase</th>
                    <th className="pricingTh">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {parchasedBookList?.data?.length ? (
                    parchasedBookList?.data?.map((item, index) => {
                      const subjectName = item.book.subject.map(
                        (item) => item.name
                      );
                      const DateOfPurchase = moment
                        .utc(item.created_at)
                        .format("DD MMM YYYY");
                      const TimeOfPurchase = moment
                        .utc(item.created_at)
                        .format("hh:mm A");
                      return (
                        <tr key={item.id}>
                          <td>{parchasedBookList.from + index}</td>
                          <td>{item.book.name}</td>
                          <td>{subjectName[0]}</td>
                          <td>{`${DateOfPurchase}, ${TimeOfPurchase}`}</td>
                          <td>${item.book.price}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" className="error01">
                        Data Not Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {parchasedBookList?.total > 0 &&
        parchasedBookList?.total > parchasedBookList?.per_page && (
          <PagePagination
            className="pagination-bar"
            currentPage={parchasedBookList?.current_page}
            totalCount={
              parchasedBookList?.last_page !== undefined
                ? parchasedBookList?.total
                : parchasedBookList?.length
            }
            pageSize={size}
            onPageChange={(page) => setPage(page)}
          />
        )}
    </div>
  );
}
export default SalesHistoryDetails;
