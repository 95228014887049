import React, { useContext, useEffect } from "react";
import { Link, useSearchParams, useNavigate, useParams } from "react-router-dom";

import { useForm } from "@kaydhiman/react-hook-useform";
import { toast } from "react-toastify";

import InputBox from "../../../../Utils/forms/InputBox";
import CustomButton from "../../../../Utils/forms/CustomButton";
import { http } from "../../../../../services/http";
import { AppContext } from "../../../../context/AppContext";

import PrefImg from "../../../../../assets/images/prev_ico.png";
import { useState } from "react";

function ClassForm() {

  //state and navigate here

  const { setSpinner } = useContext(AppContext);
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { id } = useParams();

  //validation of form
  const { values, errors, bindField, isValid, setInitialValues } = useForm({
    validations: {
      name: {
        required: true,
      },
    },
  });

  //function to add or update class

  const addClass = (e) => {
    e.preventDefault();
    setSpinner(true)
    http(
      {
        method: id ? "PATCH" : "POST",
        url: `class/${id ? id : ""}`,
        isSecure: true,
        body: {
          ...values
        }
      },
      (res) => {
        setSpinner(false);
        navigate("/admin/classes")
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        setSpinner(false)
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    )
  }

  useEffect(() => {
    if (queryParams.get("name")) {
      setInitialValues({ name: queryParams.get("name") });
    }
  }, [setInitialValues, queryParams])

  return (
    <div className="update_user add_class">
      <div className="sup_history">
        <div className="scale_data_header">
          <h2 className="class_head">
            <Link to="/admin/classes" className="prev_btn">
              <span className="backBtn">
                <img className="backPg" src={PrefImg} alt="Back button" />
              </span>
            </Link>
            {`${id ? "Edit" : "Add"}`} Class
          </h2>
        </div>
        <div className="data_add_form admin-form">
          <form onSubmit={addClass}>
            <div className="row">
              <div className="mb-4 col-lg-3">
                <InputBox
                  label="Class Name"
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter class name"
                  classname="form-control"
                  {...bindField("name")}
                  error={errors.name}
                />
              </div>
            </div>

            <div className="addon_btn row">
              <div className="col-md-3">
                <CustomButton
                  text="Submit"
                  type="submit"
                  classname="sub-btn"
                  disabled={!isValid()}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default ClassForm;
