import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";

import { AppContext } from "../../../../context/AppContext";
import { http } from "../../../../../services/http";
import { toast } from "react-toastify";

import binImg from "../../../../../assets/images/trash.png";
import BlueEye from "../../../../../assets/images/blue-eye.svg";
import PrefImg from "../../../../../assets/images/prev_ico.png";

import UserDp from "../../../../../assets/images/student_detail.png";
import ReportCard from "../../../../../assets/images/report-card.svg";

function SchoolStudentDetails() {
  const [showModal, setShowModal] = useState({ isShow: false, id: "" });
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    id: "",
  });

  // pdf Upload handle section end

  const { setSpinner } = useContext(AppContext);
  const [studentInfo, setStudentInfo] = useState({});

  const { studentId } = useParams();

  // get Teacher lisiting
  useEffect(() => {
    setSpinner(true);
    http(
      {
        method: "GET",
        url: `student/${studentId}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setStudentInfo(res.data);
      }
    );
  }, [setStudentInfo, setSpinner, studentId]);

  //delete report card

  const deleteReportCard = (id) => {
    setSpinner(true);
    http(
      {
        method: "DELETE",
        url: `student/${studentId}/reportcard/${id}`,
        isSecure: true,
      },
      (res) => {
        setStudentInfo((prevData) => {
          return {
            ...prevData,
            report_cards: prevData?.report_cards?.filter(
              (items) => items.id !== id
            ),
          };
        });
        setIsModalVisible({ isShow: false });
        setSpinner(false);
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        setSpinner(false);
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  const modalTitle = (id) => {
    let name;
    studentInfo.report_cards.forEach((item) => {
      if (item.id === id) {
        name = item.name;
      }
    });
    return name;
  };

  const showModalDataHandle = (id) => {
    let res;
    studentInfo?.report_cards.forEach((item) => {
      if (item.id === id) {
        res = item.report;
      }
    });
    return res;
  };

  return (
    <div className="user_details student-details">
      <div className="col-md-12 ">
        <div className="sup_history">
          <div className="scale_data_header">
            <h2 className="class_head">
              <Link to="/school/students" className="prev_btn">
                <span className="backBtn">
                  <img className="backPg" src={PrefImg} alt="Back button" />
                </span>
              </Link>
              Student Details
            </h2>
          </div>
          <div className="user_card">
            <div className="">
              <img
                src={
                  studentInfo.file
                    ? process.env.REACT_APP_MEDIA_URL + studentInfo.file
                    : UserDp
                }
                alt="User"
                className="user_profile_sub"
              />
            </div>
            <ul className="user_lists skool_list">
              <li>
                <div className="detail_sec">
                  <p className="user_name_th">Name</p>
                  <h3 className="user_name_td">{studentInfo?.user?.name}</h3>
                </div>
              </li>
              <li>
                <div className="detail_sec">
                  <p className="user_name_th">Class</p>
                  <h3 className="user_name_td">{studentInfo?.class?.name}</h3>
                </div>
              </li>
              <li>
                <div className="detail_sec">
                  <p className="user_name_th">Email Id</p>
                  <h3 className="user_name_td ">{studentInfo?.user?.email}</h3>
                </div>
              </li>
              <li>
                <div className="detail_sec">
                  <p className="user_name_th text-nowrap">Phone Number</p>
                  <h3 className="user_name_td">
                    {studentInfo?.user?.phone_number}
                  </h3>
                </div>
              </li>
              <li>
                <div className="detail_sec">
                  <p className="user_name_th">Roll No</p>
                  <h3 className="user_name_td">{studentInfo?.roll_number}</h3>
                </div>
              </li>
            </ul>
          </div>
          <div className="cards-data">
            <div className="row">
              <div className="col-md-12">
                <h2 className="class_head mb-4">Report Cards</h2>
              </div>
              <div className="col-lg-12 col-md-12 col-12  text-center d-flex flex-wrap">
                {studentInfo?.report_cards?.length ? (
                  studentInfo.report_cards.map((reportInfo) => {
                    return (
                      <div className="user_card" key={reportInfo.id}>
                        <div className="card-img">
                          <img src={ReportCard} alt="Report card" />
                        </div>
                        <div className="action-btns">
                          <ul>
                            <li>
                              <img
                                src={BlueEye}
                                onClick={() =>
                                  setShowModal({
                                    isShow: true,
                                    id: reportInfo.id,
                                  })
                                }
                                alt="Show Icon"
                              />
                            </li>
                            <Modal
                              show={showModal.isShow}
                              onHide={() => setShowModal({ isShow: false })}
                              centered
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>
                                  <p className="mb-0 notice_card_header_text">
                                    {modalTitle(showModal.id)}
                                  </p>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <iframe
                                  title="Doc Preview"
                                  src={`${
                                    process.env.REACT_APP_REPORT_URL
                                  }${showModalDataHandle(showModal.id)}`}
                                  frameBorder="0"
                                  width="100%"
                                  height="450"
                                ></iframe>
                              </Modal.Body>
                            </Modal>
                            <li>
                              <img
                                src={binImg}
                                alt="PDF Icon"
                                onClick={() => {
                                  setIsModalVisible({
                                    isShow: true,
                                    id: reportInfo.id,
                                  });
                                }}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center w-100">
                    <h1 className="text-center">Data Not Found</h1>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div className="data_add_form admin-form">
            <form>
              <div className="row">
                <div className="mb-4 ">
                  <label htmlFor="file" className="form-label">
                    Upload New Report
                  </label>
                  <div className="item-content file_up">
                    <div className="pdf-upload">
                      <input
                        type="file"
                        className="btn sub-btn  upload_btn"
                        accept=".pdf"
                     
                        onChange={(e) => uploadPdfFile(e)}
                      />
                {doc.url && <div className="file_icon">
                        <img
                          className="file"
                          src={PDFicon}
                          alt="Upload book as pdf"
                        />
                        <img
                          className="cut_pdf"
                          src={CrossIcon}
                          alt="Remove selected file"
                          onClick={() => setDoc({})}
                        />
                      </div>}
                   
                     
                    </div>
                  </div>
                </div>
                <div className="addon_btn row">
                  <div className="col-xl-3 col-lg-6 col-sm-5 ">
                    <Link
                      to="/students"
                      type="submit"
                      className="btn sub-btn btn-primary"
                    >
                      Send Report
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div> */}
        </div>
      </div>
      {/* Delete confirm modal section start */}
      {isModalVisible.isShow && (
        <Modal
          show={isModalVisible}
          onHide={() => setIsModalVisible(false)}
          className="delete-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Do you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsModalVisible(false)}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => deleteReportCard(isModalVisible.id)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Delete confirm modal section end */}

      {/*Report card Modal Section start */}

      {/* Report card Modal section end */}
    </div>
  );
}
export default SchoolStudentDetails;
