import React, { useContext, useEffect, useState, useRef } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";

// import item from "react-bootstrap-typeahead/lib/behaviors/item";
import Auth from "../../Auth/Auth";
import { AppContext } from "../context/AppContext";
import { ADMIN } from "../../Auth/userTypes";

import Logo from "../../assets/images/ena-logo.svg";
import MenuIcon from "../../assets/images/menu.png";
import CrossIcon from "../../assets/images/cross-menuIcon.png";
import Dropdown from "../../assets/images/profile-drop.svg";
import AdminImage from "../../assets/images/user1.png";
import LogoutIcon from "../../assets/images/logout_icon.svg";
import ProfileIcon from "../../assets/images/profile_icon.svg";
import { http } from "../../services/http";

// import {useTranslation} from "react-i18next";

// Super Admin and School Admin JSON Navbar section start

const adminMenu = [
  {
    id: 1,
    url: "/admin/dashboard",
    label: "Dashboard",
    classname: "nav_link  dashLink",
  },
  {
    id: 2,
    url: "/admin/sales",
    label: "Sales History",
    classname: "nav_link saleLink",
  },
  {
    id: 3,
    url: "/admin/school",
    label: "Schools",
    classname: "nav_link schoolLink",
  },
  {
    id: 4,
    url: "/admin/classes",
    label: "Classes",
    classname: "nav_link classLink",
  },
  {
    id: 5,
    url: "/admin/general-exams",
    label: "General Exam",
    classname: "nav_link examLink",
  },
  {
    id: 6,
    url: "/admin/books",
    label: "Books",
    classname: "nav_link bookLink",
  },
  {
    id: 7,
    url: "/admin/video",
    label: "Videos",
    classname: "nav_link vidLink",
  },
  {
    id: 8,
    url: "/admin/students",
    label: "Students",
    classname: "nav_link studLink",
  },
  {
    id: 9,
    url: "/admin/setting",
    label: "Settings",
    classname: "nav_link setting",
  },
  {
    id: 10,
    url: "/admin/post",
    label: "Posts",
    classname: "nav_link postLink",
  },
  {
    id: 11,
    url: "/admin/subscription",
    label: "Subscriptions",
    classname: "nav_link subLink",
  },
  {
    id: 12,
    url: "/admin/otherusers",
    label: "Other Users",
    classname: "nav_link userLink",
  },
  {
    id: 13,
    url: "/admin/pushNotification",
    label: "Push Notification",
    classname: "nav_link notiLink",
  },
];

const schoolMenu = [
  {
    id: 1,
    url: "/school/dashboard",
    label: "Dashboard",
    classname: "nav_link dashLink",
  },
  {
    id: 2,
    url: "/school/teachers",
    label: "Teachers",
    classname: "nav_link techLink",
  },
  {
    id: 3,
    url: "/school/students",
    label: "Students",
    classname: "nav_link studLink",
  },
  {
    id: 4,
    url: "/school/payment-history",
    label: "Payment History",
    classname: "nav_link payLink",
  },
  {
    id: 5,
    url: "/school/setting",
    label: "Settings",
    classname: "nav_link setting",
  },
  {
    id: 6,
    url: "/school/notifications",
    label: "Notifications",
    classname: "nav_link notiLink",
  },
  {
    id: 7,
    url: "/school/reports",
    label: "Reports",
    classname: "nav_link reportLink",
  },
  {
    id: 8,
    url: "/school/notice",
    label: "Notice",
    classname: "nav_link noticeLink",
  },
];

// Super Admin and School Admin JSON Navbar section start

function PortalLayout() {
  const { setShowLogin, setuserLang } = useContext(AppContext);

  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [show, setShow] = useState(true);
  const [bodyshow, setbodyshow] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [menus, setMenus] = useState([]);
  const getUserData = Auth.isUserAuthenticated()
    ? JSON.parse(sessionStorage.akili_user)
    : "";
  const handlestates = () => {
    setShow((prev) => !prev);
    setbodyshow(!bodyshow);
  };

  useEffect(() => {
    http(
      {
        method: "GET",
        url: `setting`,
        isSecure: true,
      },
      (res) => {
        // setSelectedLanguage(res.data.language_id);
        setuserLang(
          res.data.language_id === 1
            ? (document.cookie = "googtrans=/en")
            : (document.cookie = "googtrans=/en/fr")
        );

        // setSelectedCurrency(res.data.currency_id);
        // setNotificationChecked(res.data.notification === 1);
      }
    );
  }, [setuserLang]);

  useEffect(() => {
    if (Auth.user().role === ADMIN) {
      setMenus(adminMenu);
    } else {
      setMenus(schoolMenu);
    }
  }, []);

  const userLogout = () => {
    Auth.logout();
    setShowLogin({});
    navigate("/");
  };

  return (
    <div
      className={`dashbord-body ${bodyshow ? "bodyshow" : ""}`}
      onClick={() => setbodyshow(true)}
    >
      <header
        className={`dash_header body-pd ${!show ? "full_wid" : ""}`}
        id="dash_header"
      >
        <div className="header_toggle" onClick={handlestates}>
          <img src={MenuIcon} id="header-toggle" alt="menu" className="h-20" />
        </div>
        <div className="header_profile_sec">
          <div
            className="header-content pointer mx-2"
            onClick={() => setDropDown(!dropDown)}
          >
            <p className="welcome-text m-0">
              Welcome {getUserData?.userInfo?.name}
            </p>
            <img
              className="admin-image"
              src={
                getUserData?.userInfo?.file
                  ? process.env.REACT_APP_MEDIA_URL + getUserData.userInfo.file
                  : AdminImage
              }
              alt="user img"
            />
            <img className="dropdown-icon" src={Dropdown} alt="dropdown" />
          </div>

          {dropDown ? (
            <div className="dropdown-menu d-block">
              {/* <div className="profile dropdown-item">Profile</div> */}
              {/* <div className="settings dropdown-item">Settings</div> */}
              <div className="dropdown-item-p  border-bottom">
                <img src={ProfileIcon} alt="logout icon" />
                Profile
              </div>
              <div
                className="dropdown-item-p"
                onClick={() =>
                  setIsModalVisible((isModalVisible) => !isModalVisible)
                }
              >
                <img src={LogoutIcon} alt="logout icon" />
                Logout
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <button
            type="button"
            className="logout_btn"
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            Logout
          </button> */}
        </div>
      </header>
      {/* <div className="dash-navbar show" onClick={() => setShow(true)} id="nav-bar"> */}

      <div className={`dash-navbar ${show ? "show" : ""}`} id="nav-bar">
        <nav className="nav">
          <div>
            <span className="nav_logo position-relative">
              <img src={Logo} alt="Logo img" />
              <button
                type="button"
                className="menu_btn"
                onClick={() => setShow(!show)}
              >
                <img src={CrossIcon} alt="cross-img" className="cross_img" />
              </button>
            </span>
            <div className="left-sidebar">
              <div className="nav_list">
                {menus?.map((linkData, index) => {
                  const { url, classname, label } = linkData;
                  return (
                    <NavLink key={index} to={url} className={classname}>
                      <span className="nav_name">{label}</span>
                    </NavLink>
                  );
                })}
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div
        className={`height-100 dash_body ${!show ? "full_wid" : ""}`}
        id="supAdmin"
      >
        <div className="container-fluid dash-container">
          <Outlet />
        </div>
      </div>

      {/* logout modal */}

      <Modal
        show={isModalVisible}
        onHide={() => setIsModalVisible(false)}
        className="delete-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Do you want to Logout?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModalVisible(false)}>
            No
          </Button>
          <Button variant="primary" onClick={() => userLogout()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default PortalLayout;
