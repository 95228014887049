import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useForm } from "@kaydhiman/react-hook-useform";
import { toast } from "react-toastify";

import InputBox from "../Utils/forms/InputBox";
import { AppContext } from "../context/AppContext";
import CustomButton from "../Utils/forms/CustomButton";
import { ADMIN, SCHOOL } from "../../Auth/userTypes";

import showIcon from "../../assets/images/eye.png";
import hideIcon from "../../assets/images/pass-eye.png";

function ResetPassword() {
  const [showPassword, setshowPassword] = useState({
    password: false,
    password_confirmation: false,
  });
  const { setSpinner , setShowLogin} = useContext(AppContext);
  const [role, setRole] = useState("");

  const { pathname, state } = useLocation();
  const navigate = useNavigate();


  const { values, errors, bindField, isValid } = useForm({
    validations: {
      password: {
       
        required: true,
      },
      password_confirmation: {
       
        required: true,
      },
    },
  });

  const formSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/auth/passwordReset`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            ...values,
            ...state,
            ...(role && { role })
          }),
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setShowLogin({ role: data.userInfo?.role_id === 1 ? ADMIN : SCHOOL });
        if(role === ADMIN) {
          navigate("/admin/login")
        }
        if(role === SCHOOL) {
          navigate("/login")
        }
        toast.success(data.message, {
          autoClose: 3000,
        });
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        toast.error(data.message, {
          autoClose: 3000,
        });
      }
      setSpinner(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setRole(pathname.includes("/admin") ? ADMIN : SCHOOL);
  }, [pathname]);

  return (
    <div className="reset_form forgotPg">
      <div className="onboarding_heading">
        <h2>Reset Password </h2>
      </div>

      <div className="data_add_form admin-form">
        <form onSubmit={formSubmit}>
          <div className="form-inputs">
            <div className="mb-4 position-relative">
              <InputBox
                classname="w-100 mb-0"
                label="New Password"
                type={showPassword.password ? "text" : "password"}
                name="password"
                icon="true"
                placeholder="Enter new password"
                {...bindField("password")}
                error={errors.password}
                appEnd={
                  <img
                    src={`${showPassword.password ? showIcon : hideIcon}`}
                    alt="eye"
                    className="img_eye_icon cursor"
                    onClick={() =>
                      setshowPassword((preValues)=>({ ...preValues, password: !showPassword.password}))
                    }
                  />
                }
              />
            </div>
            <div className="mb-4 position-relative">
              <InputBox
                classname="w-100 mb-0"
                label="Confirm Password"
                type={showPassword.password_confirmation ? "text" : "password"}
                name="password_confirmation"
                icon="true"
                placeholder="Enter confirm password"
                {...bindField("password_confirmation")}
                error={errors.password_confirmation}
                appEnd={
                  <img
                    src={`${
                      showPassword.password_confirmation ? showIcon : hideIcon
                    }`}
                    alt="eye"
                    className="img_eye_icon cursor"
                    onClick={() =>
                      setshowPassword((preValues)=>({ ...preValues,
                        password_confirmation:
                          !showPassword.password_confirmation,
                      }))
                    }
                  />
                }
              />
            </div>
          </div>

          <CustomButton
            type="submit"
            text="Submit"
            classname="sub-btn"
            disabled={!isValid()}
          />
        </form>
      </div>
    </div>
  );
}
export default ResetPassword;
