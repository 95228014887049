import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useForm, patterns } from "@kaydhiman/react-hook-useform";
import { toast } from "react-toastify";

import Auth from "../../Auth/Auth";
import { AppContext } from "../context/AppContext";
import InputBox from "../Utils/forms/InputBox";
import { ADMIN, SCHOOL } from "../../Auth/userTypes";
import CustomButton from "../Utils/forms/CustomButton";

import showIcon from "../../assets/images/eye.png";
import hideIcon from "../../assets/images/pass-eye.png";

function Login() {
  const [showPassword, setshowPassword] = useState(false);
  const { setSpinner, setShowLogin } = useContext(AppContext);
  const [role, setRole] = useState("");

  const { pathname } = useLocation();
  let navigate = useNavigate();

  const { values, errors, bindField, isValid } = useForm({
    validations: {
      email: {
        pattern: {
          value: patterns.email,
          message: "Invalid email address.",
        },
        required: true,
      },
      password: {
        minLength: {
          value: 6,
          message: "Password should be minimum 8 characters long.",
        },
        required: true,
      },
    },
  });

  const formSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            ...values,
            ...(role && { role }),
            device_type: "web",
          }),
        }
      );

      if (response.status === 200) {
        const { data, message } = await response.json();
        Auth.login(data);

        setShowLogin({ role: data.userInfo?.role_id === 1 ? ADMIN : SCHOOL });

        toast.success(message, {
          autoClose: 3000,
        });

        if (data.userInfo?.role_id === 1) {
          navigate("/admin/dashboard");
        } else {
          navigate("/dashboard");
        }
      }

      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        if (response.status >= 422) {
          toast.error("Please enter correct email or password", {
            autoClose: 3000,
          });
        } else {
          toast.error(data.message, {
            autoClose: 3000,
          });
        }
      }
      setSpinner(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setRole(pathname.includes("/admin") ? ADMIN : SCHOOL);
  }, [pathname]);

  return (
    <div className="SignIN_form">
      <div className="onboarding_heading">
        <h2>
          {/* {role === "SchoolAdmin" && "Admin"}  */}
          Sign In
        </h2>
      </div>
      <div className="data_add_form admin-form">
        <form onSubmit={formSubmit}>
          <InputBox
            label="Email"
            type="email"
            name="email"
            placeholder="Enter your email"
            classname="w-100"
            {...bindField("email")}
            error={errors.email}
          />

          <InputBox
            classname="w-100 mb-0"
            label="Password"
            type={showPassword ? "text" : "password"}
            name="password"
            icon="true"
            placeholder="Enter your password"
            {...bindField("password")}
            error={errors.password}
            appEnd={
              <img
                src={`${showPassword ? showIcon : hideIcon}`}
                alt="eye"
                className="img_eye_icon cursor"
                onClick={() => setshowPassword(!showPassword)}
              />
            }
          />

          <div className="mb-3 form-forgot text-end">
            <Link
              to={
                pathname.includes("/admin")
                  ? "/admin/forgot-password"
                  : "/forgot-password"
              }
            >
              Forgot Password?{" "}
            </Link>
          </div>

          <CustomButton
            type="submit"
            text="Sign In"
            classname="sub-btn"
            disabled={!isValid()}
          />
        </form>
      </div>
    </div>
  );
}
export default Login;
