import React, {
  useState,
  useRef,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { Modal, Button, DropdownButton, Dropdown } from "react-bootstrap";
import { useForm } from "@kaydhiman/react-hook-useform";
import { http } from "../../../../services/http";
import Pagination from "../../../Utils/pagination/Pagination";
import StudentAdminData from "../../../../admindata/StudentAdminData";
import TypeAheadContainer from "../../../Utils/widgets/TypeAheadContainer";
import { AppContext } from "../../../context/AppContext";
import ShortIcon from "../../../../assets/images/sorting.png";
import PagePagination from "../../../Utils/pagination/PagePagination";
function PaymentHistory() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [allData] = useState(StudentAdminData);
  const [classList, setClassList] = useState();
  const [catagoryList, setCategoryList] = useState();
  const paymentSelect = useRef(null);
  const { setSpinner, size } = useContext(AppContext);
  const [paymentList, setPaymentList] = useState();
  const [page, setPage] = useState();
  const [isAscending, setIsAscending] = useState(false);
  const [pointer, setPointer] = useState("none");
  const getUserData = JSON.parse(sessionStorage.akili_user);
  const getSchoolId = getUserData.schoolInfo.school_id;
  const { setInitialValues, values } = useForm({
    validations: {},
    // initialValues: { class_data: [] },
  });
  const sortPaymentData = () => {
    setPaymentList((prevData) => ({
      ...prevData,
      data: prevData.data?.sort((item1, item2) => {
        if (item1.user.name < item2.user.name) {
          return isAscending ? -1 : 1;
        }
        if (item1.user.name > item2.user.name) {
          return isAscending ? 1 : -1;
        }

        // names must be equal
        return 0;
      }),
    }));

    setIsAscending((prev) => !prev);
  };
  const handleOk = () => {
    // const data = allData?.filter((item) => item?.id !== id);
    // setAllData(data);
    setIsModalVisible(false);
  };

  const downlodModal = () => {
    setIsModalVisible(true);
  };

  // listing payment history
  const getPaymentHistory = useCallback(() => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: size,
    };
    if (values.classId) {
      params.class_id = values.classId ? values.classId : "";
    }
    if (values.categoryId) {
      params.category_id = values.categoryId ? values.categoryId : "";
    }

    http(
      {
        method: "GET",
        url: `payment/payment-history?${new URLSearchParams(params)}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setPaymentList(res.data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSpinner, values.classId, values.categoryId]);
  // useCallback(
  //   ({ page, size }) => {
  //     setSpinner(true);
  //     http(
  //       {
  //         method: "GET",
  //         url: `payment/payment-history?class_id=${
  //           values.classId ? values.classId : ""
  //         }&category_id=${values.categoryId ? values.categoryId : ""}&page=${
  //           page ? page : 1
  //         }&size=${size ? size : 10}`,
  //         isSecure: true,
  //       },
  //       (res) => {
  //         setSpinner(false);
  //         setPaymentList(res.data);
  //       }
  //     );
  //   },
  //   [setSpinner, values.classId, values.categoryId]
  // );

  // useEffect(() => {
  //   getPaymentHistory({ ...pagination });
  // }, [getPaymentHistory, pagination]);

  useEffect(() => {
    getPaymentHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPaymentHistory, page]);
  // class list
  useEffect(() => {
    http(
      {
        method: "GET",
        url: `class`,
        isSecure: true,
      },
      (res) => {
        setClassList(res);
      }
    );
  }, []);
  // fee category list
  const getCategoryList = (classId) => {
    http(
      {
        method: "GET",
        url: `school/${getSchoolId}/fee?class_id=${classId ? classId : ""}`,
        isSecure: true,
      },
      (res) => {
        setCategoryList(res);
      }
    );
  };
  // console.log(catagoryList);
  return (
    <div className="sup_student_dash payment-dashboard">
      <div className="sup_history">
        <div className="scale_data_header">
          <div className="scale_data_heading">
            <h2 className="class_head">Payment History</h2>
            <div className="btns_header">
              {/* class dropdown section start */}
              <div className="dropdown-box">
                <DropdownButton
                  className={`dropdown-btn`}
                  title={
                    values?.class_data ? (
                      values?.class_data
                    ) : (
                      <span>Select class</span>
                    )
                  }
                >
                  {classList?.data?.map((elem) => {
                    return (
                      <div
                        key={elem?.id}
                        className="dropdown_projects"
                        onClick={() => {
                          setInitialValues((prev) => ({
                            ...prev,
                            class_data: elem.name,
                            classId: elem.id,
                            categoryId: "",
                            category_data: "Select category",
                          }));
                          getCategoryList(elem.id);
                          setPointer("auto");
                        }}
                      >
                        <Dropdown.Item
                          className="dropdown-status"
                          title={elem?.class}
                        >
                          <span
                            className={
                              elem?.name === values?.class_data
                                ? "class_active"
                                : ""
                            }
                          >
                            {elem?.name}
                          </span>
                        </Dropdown.Item>
                      </div>
                    );
                  })}
                </DropdownButton>
              </div>
              {/* class dropdown section end */}
              {/* Exam catagory dropdown section start */}
              <div className="dropdown-box" style={{ pointerEvents: pointer }}>
                <DropdownButton
                  className={`dropdown-btn`}
                  title={
                    values?.category_data ? (
                      values?.category_data
                    ) : (
                      <span>Select category</span>
                    )
                  }
                >
                  {catagoryList?.data?.map((elem) => {
                    return (
                      <div
                        key={elem?.id}
                        className="dropdown_projects"
                        onClick={() => {
                          setInitialValues((prev) => ({
                            ...prev,
                            category_data: elem.fee_category,
                            categoryId: elem.id,
                          }));
                        }}
                      >
                        <Dropdown.Item
                          className="dropdown-status"
                          title={elem?.id}
                        >
                          <span
                            className={
                              elem?.fee_category === values?.category_data
                                ? "class_active"
                                : ""
                            }
                          >
                            {elem?.fee_category}
                          </span>
                        </Dropdown.Item>
                      </div>
                    );
                  })}
                </DropdownButton>
              </div>
              {/* Exam catagory dropdown section end */}
            </div>
          </div>
          <div className="search-box sing-btn">
            <div className="data_add">
              <button
                // to="/addschoolstudent"
                type="button"
                className="btn sub-btn btn-primary m-0"
                onClick={() => downlodModal()}
              >
                Download
              </button>
            </div>
          </div>
        </div>
        <div className="kina_tables table-responsive">
          <table className="dash_tables">
            <thead>
              <tr>
                <th className="SnumData">S.No.</th>
                <th>
                  Students Name{" "}
                  <img
                    src={ShortIcon}
                    alt="sorting icon"
                    className="cursor"
                    onClick={sortPaymentData}
                  />
                </th>
                <th>Category</th>
                <th>Due Date</th>
                <th className="pricingTh">Amount</th>
              </tr>
            </thead>
            <tbody>
              {paymentList?.data?.length ? (
                paymentList?.data?.map((historyData, index) => {
                  const dueDate = moment(
                    historyData.student_school_fee.fees_due.due_date
                  ).format("DD MMM YYYY");
                  return (
                    <tr key={index}>
                      <td>
                        {paymentList.from + index
                          ? paymentList.from + index
                          : "-"}
                      </td>
                      <td>
                        {/* <Link to="airtel-payment">{student_name}</Link> */}
                        {historyData.user.name ? historyData.user.name : "-"}
                      </td>
                      <td>
                        {historyData.student_school_fee.fees.fee_category
                          ? historyData.student_school_fee.fees.fee_category
                          : "-"}
                      </td>
                      <td>{dueDate ? dueDate : "-"}</td>
                      <td>
                        $
                        {historyData.student_school_fee.fees.amount
                          ? historyData.student_school_fee.fees.amount
                          : "-"}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="8" className="error01">
                    Data Not Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {paymentList?.total > 0 && paymentList?.total > paymentList?.per_page && (
        <PagePagination
          className="pagination-bar"
          currentPage={paymentList?.current_page}
          totalCount={
            paymentList?.last_page !== undefined
              ? paymentList?.total
              : paymentList?.length
          }
          pageSize={size}
          onPageChange={(page) => setPage(page)}
        />
      )}
      {/* Delete confirm modal section start */}
      <Modal
        show={isModalVisible}
        onHide={() => setIsModalVisible(false)}
        className="delete-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Do you want to download this file?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModalVisible(false)}>
            No
          </Button>
          <Button variant="primary" onClick={() => handleOk()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Delete confirm modal section end */}
      {/* <Pagination /> */}
    </div>
  );
}
export default PaymentHistory;
