import React from "react";
import { useNavigate } from "react-router-dom";

import PrefImg from "../assets/images/prev_ico.png";

function BreadCrumb({ links }) {
  const navigate = useNavigate();

  function extractLinks(label) {
    let result = [];
    for (let i = 0; i <= links.length; i++) {
      if (links[i].label === label) {
        break;
      }

      result.push(links[i]);
    }

    return result;
  }

  return (
    <div className="scale_data_heading">
      <div className="class_head">
        <span className="backBtn" onClick={() => navigate(-1)}>
          <img className="backPg" src={PrefImg} alt="Back button" />
        </span>
        {links?.map((link, index) => {
          if (link?.url) {
            return (
              <span
                key={index}
                // to={link?.url}
                className="text-grey"
                style={link?.style}
                onClick={() => {
                  navigate(link?.url, { state: extractLinks(link.label) });
                }}
              >
                {(index ? " / " : " ") + link?.label}
              </span>
            );
          } else {
            return (index ? " / " : " ") + link?.label;
          }
        })}
      </div>
    </div>
  );
}

export default BreadCrumb;
