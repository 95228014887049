import React from "react";
import gallery from "../../../assets/images/gallery.png";

function UploadProfile({ label, required, error, onChange, picture }) {
  return (
    <div className="position-relative">
      <label htmlFor="" className="form-label">
        {label} {required && <span className="required">*</span>}
      </label>
      <div className="profile_upload_section position-relative">
        <input
          type="file"
          className="docsss_upload"
          id="img_upload"
          accept="image/*"
          onChange={onChange}
        />
        <label htmlFor="img_upload"  className={`${picture?.url ? "" : "profile_img_lable"}`}>
          <img
            src={picture?.url ? picture?.url : gallery}
            alt="profile"
            className={`${picture?.url ? "profile_photo" : " "}`}
          />
        </label>
      </div>
      {error && <span className="error"> {error}</span>}
    </div>
  );
}

export default UploadProfile;
