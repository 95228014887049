import React from 'react'
import PrevIcon from '../../../assets/images/prev_ico.png'
import { Link } from 'react-router-dom'
function Pagination() {
    return (
        <div className="dash_pagination">
            <nav aria-label="Page navigation example">
                <ul className="pagination">
                    <li className="page-item">
                        <Link className="page-link navi_btn" to=" " aria-label="Previous">
                            <span className="sr-only">
                                <img src={PrevIcon} className="prev-icon" alt="PrevIcon" />
                            </span>
                        </Link>
                    </li>
                    <li className="page-item">
                        <Link className="page-link active" to=" ">1</Link>
                    </li>
                    <li className="page-item">
                        <Link className="page-link" to=" ">2</Link>
                    </li>
                    <li className="page-item">
                        <Link className="page-link" to=" ">3</Link>
                    </li>
                    <li className="page-item">
                        <Link className="page-link" to=" ">...</Link>
                    </li>
                    <li className="page-item">
                        <Link className="page-link" to=" ">20</Link>
                    </li>
                    <li className="page-item">
                        <Link className="page-link navi_btn" to=" " aria-label="Next">
                            <span className="sr-only">
                                <img src={PrevIcon} className="next-icon" alt="PrevIcon" />
                            </span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}
export default Pagination;