import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { useForm } from "@kaydhiman/react-hook-useform";

import InputBox from "../../../../Utils/forms/InputBox";
import TextArea from "../../../../Utils/forms/TextArea";
import BreadCrumb from "../../../../../form-widgets/BreadCrumb";
import { http } from "../../../../../services/http";
import { AppContext } from "../../../../context/AppContext";

function TestEdit() {
  const [catagoryType, setCatagoryType] = useState(1);
  const [answers, setAnswers] = useState([
    { id: 1, answer: "", is_correct: false },
  ]);
  const { id, subId, chptrId, testId } = useParams();
  const { setSpinner } = useContext(AppContext);
  const navigate = useNavigate();
  // handleexam catagory section start
  const { state } = useLocation();

  //
  const { bindField, values, setInitialValues } = useForm({
    validations: {
      initialValues: { class_data: [], sub_data: [], exam_data: [] },
      question: {},
      testName: {},
    },
  });

  const changeCatogeryHandler = (e) => {
    const { value } = e.target;
    setCatagoryType(+value);

    if (+value === 1) {
      setAnswers([{ id: 1, answer: "", is_correct: false }]);
    } else if (+value === 2) {
      setAnswers([
        { id: 1, answer: "Yes", is_correct: false },
        { id: 2, answer: "No", is_correct: false },
      ]);
    }
  };

  function fieldChange(id, value, type) {
    setAnswers((prevFields) =>
      prevFields.map((field) => {
        if (field.id === id) {
          switch (type) {
            case "answer":
              field.answer = value;
              break;
            case "isCorrect":
              field.is_correct = value;
              break;
            default:
              break;
          }
        }
        return field;
      })
    );
  }

  const handleAddForm = (e) => {
    e.preventDefault();
    if (answers?.some((item) => item.answer === "") && !testId) {
      toast.success("Please enter all answers fields", {
        autoClose: 3000,
      });
      return;
    }
    if (!answers?.some((item) => item.is_correct) && !testId) {
      toast.success("Please select atleast one correct answer", {
        autoClose: 3000,
      });
      return;
    }
    setSpinner(true);
    http(
      {
        method: testId ? "PUT" : "POST",
        url: `class/${id}/subject/${subId}/chapter/${chptrId}/test${
          testId ? "/" + testId : ""
        }`,
        isSecure: true,
        body: {
          ...(values.testName && { name: values.testName }),
          ...(!testId && {
            answers: answers.map((ans) => {
              if (ans.is_correct) {
                ans.is_correct = 1;
              } else {
                ans.is_correct = 0;
              }

              return ans;
            }),
            ...(values.classId && { class_id: values.classId }),
            ...(values.subjectId && { subject_id: values.subjectId }),
            ...(values.question && { question: values.question }),
            ...(catagoryType && { test_question_category_id: catagoryType }),
            ...(catagoryType === 1 ? { answer_count: answers.length } : {}),
          }),
        },
      },
      (res) => {
        setSpinner(false);
        navigate(state[state.length - 1].url, {state: state.splice(0,2)})
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
        setSpinner(false);
      }
    );
  };

  function handleChange(e) {
    if (+e.target.value > 4 || +e.target.value === 0) {
      toast.error("You Can Add Maximum 4 Or Minimum 1.");
      return;
    }

    if (+e.target.value < answers.length) {
      setAnswers((prevFields) => {
        return prevFields.slice(
          0,
          answers.length - (answers.length - +e.target.value)
        );
      });
    } else {
      let newItems = [];

      for (let i = 1; i <= +e.target.value - answers.length; i++) {
        newItems.push({
          id:
            i === 1
              ? answers.length > 0
                ? answers[answers.length - 1].id + 1
                : 1
              : newItems[newItems.length - 1].id + 1,
          value: null,
        });
      }

      setAnswers((prevFields) => [...prevFields, ...newItems]);
    }
  }

  //add answer count
  function handleAdd() {
    if (answers.length <= 3) {
      setAnswers((prevFields) => [
        ...prevFields,
        {
          id: answers.length > 0 ? answers[answers.length - 1].id + 1 : 1,
          value: null,
        },
      ]);
    }
  }

  function handleRemove() {
    const values = [...answers];
    if (answers.length > 1) {
      values.pop();
      setAnswers(values);
    }
  }

  useEffect(() => {
    testId &&
      http(
        {
          method: "GET",
          url: `class/${id}/subject/${subId}/chapter/${chptrId}/test/${testId}`,
          isSecure: true,
        },
        (res) => {
          setInitialValues({ testName: res.data.name });
        }
      );
  }, [id, subId, chptrId, testId, setInitialValues]);

  return (
    <div className=" user_details edit_test_dash px-0">
      <div className="sup_history update_user ">
        <div className="scale_data_header d-flex justify-content-between align-items-center">
          <BreadCrumb
            links={[
              
              {
                label: `${testId ? "Edit" : "Add"} Test`,
                style: { color: "black" },
                url: "add",
              },
            ]}
          />
        </div>
        <div className="data_add_form admin-form">
          <form onSubmit={handleAddForm}>
            <div className="row">
              <div className="mb-4 col-lg-3">
                <InputBox
                  label="Test name"
                  type="text"
                  id="testName"
                  classname="form-control"
                  name="testName"
                  {...bindField("testName")}
                />
              </div>
            </div>

            {testId ? (
              ""
            ) : (
              <>
                <div className="row">
                  <div className="mb-4 col-lg-6 ">
                    <label htmlFor="sName" className="form-label">
                      Question
                    </label>
                    <textarea
                      type="text"
                      className="form-control qa_input input-textarea "
                      rows="3"
                      cols="60"
                      id="question"
                      placeholder="Enter question"
                      name="question"
                      {...bindField("question")}
                    ></textarea>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-4 col-lg-6 col-xl-3">
                    <label htmlFor="sName" className="form-label">
                      Category
                    </label>
                    {/* Catagory dropdown section star */}
                    <div className="dropdown">
                      <select onChange={changeCatogeryHandler}>
                        <option value="1">Multiple choice</option>
                        <option value="2">Yes/ No</option>
                      </select>
                    </div>
                    {/* Catagory dropdown section end */}
                  </div>
                  {catagoryType === 1 && (
                    <>
                      <div className="mb-4 col-lg-6 col-xl-3">
                        <label htmlFor="sName" className="form-label">
                          Answer count
                        </label>
                        <div className="qaAdd">
                          <input
                            type="number"
                            className="form-control qa_count text-input theme-color answerCount"
                            id="answer_count"
                            name="answer_count"
                            onChange={handleChange}
                            value={answers.length}
                          />

                          {/* Answer Count Button section start */}
                          <div className="ques-btns">
                            <button
                              type="button"
                              className="qa_add add_qa"
                              onClick={() => handleAdd()}
                            ></button>
                            <button
                              type="button"
                              className="qa_add dec_qa"
                              onClick={() => handleRemove()}
                            ></button>
                          </div>
                          {/* Answer Count Button section end */}
                        </div>
                      </div>

                      <div className="row">
                        {answers.map((field, idx) => {
                          return (
                            <div
                              className="mb-4 col-lg-6 col-xl-6"
                              key={idx + 1}
                              id={idx + 1}
                            >
                              <TextArea
                                label={`Answer ${idx + 1}`}
                                id={`aw_count${idx + 1}`}
                                placeholder="Enter Answer"
                                value={field.answer}
                                onChange={(e) =>
                                  fieldChange(
                                    field.id,
                                    e.target.value,
                                    "answer"
                                  )
                                }
                              />

                              <div className="form-check confirmBox">
                                <input
                                  className="form-check-input confirm_input"
                                  type="radio"
                                  name="awn"
                                  checked={field.is_correct}
                                  onChange={(e) =>
                                    fieldChange(
                                      field.id,
                                      e.target.checked,
                                      "isCorrect"
                                    )
                                  }
                                />
                                <label className="form-label confirmlabel">
                                  Right Answer
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>

                {catagoryType === 2 && (
                  <div className="row">
                    <div className="mb-4 col-lg-2 col-6">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Yes
                      </label>
                      <div className="form-check confirmBox">
                        <input
                          className="form-check-input confirm_input"
                          type="radio"
                          name="answer"
                          id="flexCheckDefault"
                          checked={answers[0].is_correct}
                          onChange={(e) =>
                            fieldChange(
                              answers[0].id,
                              e.target.checked,
                              "isCorrect"
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Right Answer
                        </label>
                      </div>
                    </div>
                    <div className="mb-4 col-lg-2 col-6 ">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        No
                      </label>
                      <div className="form-check confirmBox">
                        <input
                          className="form-check-input confirm_input"
                          type="radio"
                          name="answer"
                          id="flexCheckDefault"
                          checked={answers[1].is_correct}
                          onChange={(e) =>
                            fieldChange(
                              answers[1].id,
                              e.target.checked,
                              "isCorrect"
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Right Answer
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            <div className="addon_btn row">
              <div className="col-xl-3 col-lg-6 ">
                <button type="submit" className="btn sub-btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer theme="dark" />
    </div>
  );
}
export default TestEdit;
