import React, { useEffect, useState, useContext, useCallback } from "react";
import { Link } from "react-router-dom";

import { DropdownButton, Dropdown } from "react-bootstrap"; 

import { http } from "../../../../services/http";
import { AppContext } from "../../../context/AppContext";
import debounce from "../../../../utilities/debounce";
import InputField from "../../../Utils/forms/InputField";

import SearchIcon from "../../../../assets/images/search-bar.png";
import {
  manupulateBookNVideoData
} from "../../../../utilities/bookNvideo";
import Slider from "../../../Utils/widgets/Slider";

function Books() {
  const [bookList, setBookList] = useState([]);
  const [classList, setClassList] = useState();

  const [classID, setClassID] = useState({});
  const { setSpinner } = useContext(AppContext);

  const getBooksHandle = useCallback(
    ({ search, id }) => {
      // setSpinner(true);
      http(
        {
          method: "GET",
          url: `book?${search ? "search=" + search : ""}${
            id ? (id && !search ? "" : "&") + "class_id=" + id : ""
          }`,
          isSecure: true,
        },
        (res) => {
          setSpinner(false);
         
          setBookList(manupulateBookNVideoData(res.data));
        }
      );
    },
    [setSpinner]
  );

  // eslint-disable-next-line
  const debouncedChangeHandler = useCallback(debounce(getBooksHandle, 300), []);

  useEffect(() => {
    getBooksHandle({ id: classID.id });
  }, [classID.id, getBooksHandle]);

  useEffect(() => {
    http(
      {
        method: "GET",
        url: `class`,
        isSecure: true,
      },
      (res) => {
        setClassList(res);
      }
    );
  }, []);

  return (
    <div
      className={`data_cards books_dash ${
        bookList.length === 0 ? "custom_height" : " "
      }`}
    >
      <div className="col-md-12">
        <div className="sup_history">
          <div className="scale_data_header border-bottom">
            <h2 className="class_head">Books</h2>
            <div className="search-box">
              <div className="input-group flex-nowrap">
                <InputField
                  type="text"
                  className="form-control searchBar"
                  placeholder="Search..."
                  onChange={(e) =>
                    debouncedChangeHandler({
                      search: e.target.value,
                      page: 1,
                      id: classID.id,
                    })
                  }
                  name="search"
                />

                <span
                  className="input-group-text search-icon"
                  id="addon-wrapping"
                >
                  <img src={SearchIcon} alt="SearchIcon" />
                </span>
              </div>
              <div className="data_add">
                <Link
                  to="add-book"
                  type="submit"
                  className="btn sub-btn btn-primary"
                >
                  Add Book
                </Link>
              </div>
            </div>
          </div>
          <div className="dash_body01">
            {/* class dropdown section start */}
            <div className="dropdown col-xl-3 mt-4">
              <div className="dropdown-box">
                <DropdownButton
                  className={`dropdown-btn`}
                  title={
                    classID?.name ? classID?.name : <span>Select Class</span>
                  }
                >
                  {classList?.data?.map((elem) => {
                    return (
                      <div
                        key={elem?.id}
                        className="dropdown_projects px-3"
                        onClick={() => {
                          setClassID({
                            name: elem.name,
                            id: elem.id,
                          });
                        }}
                      >
                        <Dropdown.Item
                          className="dropdown-status"
                          title={elem?.name}
                        >
                          <span className={`${elem.name} ? "class_active" : ""`}>
                            {elem?.name}
                          </span>
                        </Dropdown.Item>
                      </div>
                    );
                  })}
                </DropdownButton>
              </div>
            </div>
            {/* class dropdown section end */}
          </div>

          {bookList.map((book, ind) => (
         
              <Slider
                key={ind}
                length={book.data.length}
                id={ind}
                title={book.title}
                data={book.data}
                options={{
                  margin: 30,
                  responsiveClass: true,
                  nav: true,
                  dots: false,
                  autoplay: false,
                  navText: ["", ""],
                  smartSpeed: 1000,
                  responsive: {
                    0: {
                      items: 1,
                    },
                    400: {
                      items: 1,
                    },
                    600: {
                      items: 2,
                    },
                    // 700: {
                    //   items: 4,
                    // },
                    800: {
                      items: 4,
                    },
                    1000: {
                      items: 4,
                    },
                    1200: {
                      items: 5,
                    },
                    1600: {
                      items: 8,
                    },

                  },
                }}
              />
         
          ))}

          {Object.keys(bookList).length === 0 && (
            <h1 className="text-center">No data found</h1>
          )}
        </div>
      </div>
    </div>
  );
}
export default Books;
