import React from "react";

import { Link } from "react-router-dom";

import BreadCrumb from "../../../../../form-widgets/BreadCrumb";

function OptionalQuestions() {
  return (
    <div className=" user_details edit_test_dash px-0">
      <div className="sup_history update_user ">
        <div className="scale_data_header">
        <BreadCrumb
            links={[
              {
                label: "Chapter 01",
                url: " ",
              },
              {
                label: "Tests Perp 01",
                url: "testPrep",
              },
              {
                label: "Add Test",
                style: { color: "black" },
                url: " ",
              },
            ]}
          />
        </div>
        <div className="data_add_form admin-form">
          <form>
            <div className="row">
              <div className="mb-4 col-lg-3 ">
                <label htmlFor="sName" className="form-label">
                  Test name
                </label>
                <input
                  type="text"
                  className="form-control text-input "
                  id="test_input"
                  value="Test Prep 01"
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-4 col-lg-6 ">
                <label htmlFor="sName" className="form-label">
                  Question
                </label>
                <textarea
                  type="text"
                  className="form-control qa_input input-textarea "
                  rows="3"
                  cols="60"
                  id="qa_input"
                  placeholder="Enter question"
                ></textarea>
              </div>
            </div>
            <div className="row">
              <div className="mb-4 col-lg-6 col-xl-3">
                <label htmlFor="sName" className="form-label">
                  Category
                </label>
                <div className="dropdown">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Yes/ No
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link className="dropdown-item" to="/edittest">
                        Multiple choice
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/optional">
                        Yes/ No
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Yes or No section start */}

            <div className="row">
              <div className="mb-4 col-lg-2 col-6">
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Yes
                </label>
                <div className="form-check confirmBox">
                  <input
                    className="form-check-input confirm_input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  // checked
                  />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Right Answer
                  </label>
                </div>
              </div>
              <div className="mb-4 col-lg-2 col-6 ">
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  No
                </label>
                <div className="form-check confirmBox">
                  <input
                    className="form-check-input confirm_input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Right Answer
                  </label>
                </div>
              </div>
            </div>
            {/* Yes or No section end */}

            <div className="addon_btn row">
              <div className="col-xl-3 col-lg-6 ">
                <Link
                  to="/chapter"
                  type="submit"
                  className="btn sub-btn btn-primary"
                >
                  Submit
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default OptionalQuestions;
