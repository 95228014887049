import React, { useContext, useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Modal, Button, DropdownButton, Dropdown } from "react-bootstrap";
import { useForm } from "@kaydhiman/react-hook-useform";
import { toast } from "react-toastify";

import { AppContext } from "../../../context/AppContext";
import { http } from "../../../../services/http";
import SearchForm from "../../../Utils/forms/SearchFrorm";
import PagePagination from "../../../Utils/pagination/PagePagination";
import debounce from "../../../../utilities/debounce";

import Cloud from "../../../../assets/images/cloud.svg";
import binImg from "../../../../assets//images/trash.png";
import ShortIcon from "../../../../assets/images/sorting.png";
import penImg from "../../../../assets//images/edit_action.png";
import CustomButton from "../../../Utils/forms/CustomButton";

function SchoolStudents() {
  const [page, setPage] = useState(1);
  const [getSearch, setSearch] = useState("");
  const [isModalVisible, setIsModalVisible] = useState({
    isShow: false,
    id: "",
  });
  const [classList, setClassList] = useState();

  const [studentList, setStudentList] = useState({});

  const { setSpinner, size } = useContext(AppContext);
  const [isAscending, setIsAscending] = useState();

  const navigate = useNavigate();

  const { setInitialValues, values } = useForm({
    validations: {},
    // initialValues: { class_data: [] },
  });

  // Student list item delete function

  const deleteStudent = (id) => {
    http(
      {
        method: "DELETE",
        url: `student/${id}`,
        isSecure: true,
      },
      (res) => {
        setStudentList((prevData) => {
          return {
            ...prevData,
            data: prevData?.data?.filter((items) => items.student_id !== id),
          };
        });
        setIsModalVisible({ isShow: false });
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  // get Student lisiting
  const studentListing = useCallback(
    () => {
      setSpinner(true);
      const params = {
        page: page,
        size: 10,
      };
      if (getSearch) {
        params.search = getSearch;
      }
      if (values.classId) {
        params.class_id = values.classId;
      }
      http(
        {
          method: "GET",
          url: `student?${new URLSearchParams(params)}`,
          isSecure: true,
        },
        (res) => {
          setSpinner(false);
          setStudentList(res.data);
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.classId, getSearch]
  );
  // useCallback(
  //   ({ page, search }) => {
  //     setSpinner(true);
  //     http(
  //       {
  //         method: "GET",
  //         url: `student?class_id=${
  //           values.classId ? values.classId : ""
  //         }&size=10&page=${page ? page : 1}${
  //           search ? "&search=" + search : ""
  //         }`,
  //         isSecure: true,
  //       },
  //       (res) => {
  //         setSpinner(false);
  //         setStudentList(res.data);
  //       }
  //     );
  //   },
  //   [setSpinner, values.classId]
  // );

  //for sort studnet
  const sortStudentData = () => {
    setStudentList((prevData) => ({
      ...prevData,
      data: prevData.data?.sort((item1, item2) => {
        if (item1.student.user.name < item2.student.user.name) {
          return isAscending ? -1 : 1;
        }
        if (item1.student.user.name > item2.student.user.name) {
          return isAscending ? 1 : -1;
        }

        // names must be equal
        return 0;
      }),
    }));

    setIsAscending((prev) => !prev);
  };

  // eslint-disable-next-line
  const debouncedChangeHandler = useCallback(
    debounce((event) => {
      setSearch(event?.target?.value);
      setPage(1);
    }, 500)
  );

  // get Teacher lisiting
  useEffect(() => {
    studentListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, getSearch, values.classId]);

  const reportNavigate = () => {
    navigate(`/school/class/${values.classId}/add-reportcard`);
  };

  useEffect(() => {
    http(
      {
        method: "GET",
        url: `class`,
        isSecure: true,
      },
      (res) => {
        setClassList(res);
      }
    );
  }, []);

  return (
    <div className="student_dash sup_student_dash ">
      <div className="sup_history">
        <div className="scale_data_header">
          <div className="scale_data_heading">
            <h2 className="class_head">Students</h2>
            <div className="btns_header">
              {/* class dropdown section start */}
              <div className="dropdown-box">
                <DropdownButton
                  className={`dropdown-btn`}
                  title={
                    values?.classNm ? (
                      values?.classNm
                    ) : (
                      <span>Select class</span>
                    )
                  }
                >
                  {classList?.data?.map((elem) => {
                    return (
                      <div
                        key={elem?.id}
                        className="dropdown_projects"
                        onClick={() => {
                          setInitialValues({
                            ...values,
                            classId: elem.id,
                            classNm: elem.name,
                          });
                          setPage(1);
                        }}
                      >
                        <Dropdown.Item
                          className="dropdown-status"
                          title={elem?.name}
                        >
                          <span
                            className={
                              elem?.name === values?.classNm
                                ? "class_active"
                                : ""
                            }
                          >
                            {elem?.name}
                          </span>
                        </Dropdown.Item>
                      </div>
                    );
                  })}
                </DropdownButton>
              </div>
              {/* class dropdown section end */}
              <div className="data_add">
                <CustomButton
                  text="Send Report Card"
                  onclick={reportNavigate}
                  classname="sub-btn"
                  disabled={!values?.classNm}
                  title="Please Select Class For Enable Button"
                />
              </div>
            </div>
          </div>
          <div className="search-box">
            <SearchForm onChange={debouncedChangeHandler} />
            <div className="data_add">
              <Link to="add" type="submit" className="btn sub-btn btn-primary">
                Add Student
              </Link>
            </div>
          </div>
        </div>
        <div className="schoolStudentTable table-responsive">
          <table className="dash_tables w-100">
            <thead>
              <tr>
                <th className="SnumData">S.No.</th>
                <th>
                  Students Name{" "}
                  <img
                    src={ShortIcon}
                    alt="sorting icon"
                    className="hand"
                    onClick={sortStudentData}
                  />
                </th>
                <th>Classes</th>
                <th>Roll No</th>
                <th>Phone No</th>
                <th> Email Id</th>
                <th className="pricingTh">Action</th>
              </tr>
            </thead>
            <tbody>
              {studentList?.data?.length ? (
                studentList?.data?.map((studentInfo, index) => (
                  <tr key={index}>
                    <td>{studentList.from + index}</td>
                    <td>
                      <Link to={`${studentInfo.student_id}`}>
                        {studentInfo?.student?.user?.name}
                      </Link>
                    </td>
                    <td>{studentInfo?.student?.class?.name}</td>
                    <td>{studentInfo?.student?.roll_number}</td>
                    <td className="text-nowrap">
                      {studentInfo?.student?.user.phone_number}
                    </td>
                    <td className="text-nowrap">
                      {studentInfo?.student?.user.email}
                    </td>
                    <td className="plm-0">
                      <div className="d-flex justify-content-between">
                        <span
                          onClick={() => {
                            navigate(
                              `/school/students/${studentInfo.student_id}/add-reportcard`,
                              {
                                state: [
                                  {
                                    label: studentInfo?.student?.class?.name,
                                    url: window.location.pathname,
                                  },
                                ],
                              }
                            );
                          }}
                        >
                          <img src={Cloud} alt="cloud" className="hand" />
                        </span>
                        <Link
                          to={`/school/students/edit/${studentInfo.student_id}`}
                        >
                          <img src={penImg} alt="Edit" />
                        </Link>
                        <span>
                          <img
                            src={binImg}
                            alt="Delete"
                            className="bin_img hand"
                            onClick={() => {
                              setIsModalVisible({
                                isShow: true,
                                id: studentInfo.student_id,
                              });
                            }}
                          />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="error01">
                    Data Not Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* Delete confirm modal section start */}
      {isModalVisible.isShow && (
        <Modal
          show={isModalVisible}
          onHide={() => setIsModalVisible(false)}
          className="delete-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Do you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsModalVisible(false)}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => deleteStudent(isModalVisible.id)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Delete confirm modal section end */}
      {studentList?.total > 0 && studentList?.total > studentList?.per_page && (
        <PagePagination
          className="pagination-bar"
          currentPage={studentList?.current_page}
          totalCount={
            studentList?.last_page !== undefined
              ? studentList?.total
              : studentList?.length
          }
          pageSize={size}
          onPageChange={(page) => setPage(page)}
        />
      )}
    </div>
  );
}
export default SchoolStudents;
