import React, { useState } from "react";


function RadioForm() {
  const [duration, setDuration] = useState("daily");

  return (
    <form className="graph_data_selector">
      <div className="form-check">
        <input
          name="radio"
          type="radio"
          value="daily"
          className="form-check-input"
          onChange={(e) => setDuration(e.target.value)}
          id="radio-1"
          checked={duration === "daily"}
        />
        <div>
          <label htmlFor="radio-1" className="radio-custom-label">Daily</label>
        </div>

      </div>
      <div className="form-check">
        <input
          name="radio"
          type="radio"
          value="weekly"
          className="form-check-input"
          onChange={(e) => setDuration(e.target.value)}
          id="radio-2"
          checked={duration === "weekly"}
        />
        <label htmlFor="radio-2" className="form-check-label" >
          Weekly
        </label>
      </div>
      <div className="form-check">
        <input
          name="radio"
          type="radio"
          value="monthly"
          className="form-check-input"
          onChange={(e) => setDuration(e.target.value)}
          id="radio-3"
          checked={duration === "monthly"}
        />
        <label htmlFor="radio-3" className="form-check-label">
          Monthly
        </label>
      </div>
      <div className="form-check">
        <input
          name="radio"
          type="radio"
          value="yearly"
          className="form-check-input"
          onChange={(e) => setDuration(e.target.value)}
          id="radio-4"
          checked={duration === "yearly"}
        />
        <label htmlFor="radio-4" className="form-check-label" >
          Yearly
        </label>
      </div>
    </form>
  );
}
export default RadioForm;
