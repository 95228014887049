import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Modal } from "react-bootstrap";
// import { useForm } from "@kaydhiman/react-hook-useform";
import UploadImg from "../../../../../assets/images/upload_image.png";

import videoIcon from "../../../../../assets/images/video-ico.png";
import PrefImg from "../../../../../assets/images/prev_ico.png";
import CrossIcon from "../../../../../assets/images/red_cross.svg";

import TypeAheadContainer from "../../../../Utils/widgets/TypeAheadContainer";
import { http } from "../../../../../services/http";
import { useForm } from "@kaydhiman/react-hook-useform";
import { toast } from "react-toastify";
import { AppContext } from "../../../../context/AppContext";

function VideoForm() {
  const [creatVideo, setCreateVideo] = useState("");
  const [creatIamge, setCreateImage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [classList, setClassList] = useState([]);
  const [checked, setChecked] = useState();
  const [subjectList, setSubjectList] = useState([]);
  const { setSpinner } = useContext(AppContext);
  const navigate = useNavigate();

  const classesSelect = useRef(null);

  const { setInitialValues, values, bindField, errors } = useForm({
    validations: {
      bookName: {},
      Price: {},
    },
    initialValues: { class_data: [], sub_data: [] },
  });
  const handleCheck = () => {
    setChecked(!checked);
  };

  // Video onchange function start
  const videoChange = (e) => {
    let size = e.target.files[0].size;
    // console.log("get size", size);
    if (size > 100000000) {
      toast.error("Video should not be more than 100MB.", {
        autoClose: 3000,
      });
      return;
    }
    setCreateVideo({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
      name: e.target.files[0].name,
    });
  };

  const imageChange = (e) => {
    // const image = URL.createObjectURL(e.target.files[0]);
    // setCreateImage(image);
    // const file = e.target.files[0];
    setCreateImage({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  // video onchange function end
  const handleVideoUpload = async (fileType) => {
    const formData = new FormData();
    formData.append("type", fileType);
    formData.append(
      "file",
      fileType === "photo" ? creatIamge.file : creatVideo.file
    );
    let response;

    await http(
      {
        method: "POST",
        url: `upload-image`,
        isSecure: true,
        contentType: "multipart/form-data",
        body: formData,
      },
      (res) => {
        response = res;
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );

    return response;
  };

  const handleUploadImg = async (e) => {
    e.preventDefault();
    if (!creatVideo.file || !creatIamge.file) {
      toast.error("please enter all fields", {
        autoClose: 3000,
      });
      return;
    }
    setSpinner(true);

    const [image, video] = await Promise.all([
      handleVideoUpload("photo"),
      handleVideoUpload("video"),
    ]);

    await handleVideoAdd(image.content?.imageName, video.content?.imageName);
    setSpinner(false);
  };

  const handleVideoAdd = (img, file) => {
    http(
      {
        method: "POST",
        url: `video`,
        isSecure: true,
        body: {
          ...(values.class?.length > 0
            ? { class: values.class.map((item) => item.id) }
            : {}),
          ...(values.subject?.length > 0
            ? { subject: values.subject.map((item) => item.id) }
            : {}),
          name: values.bookName,
          price: values.Price,
          is_paid: checked ? 1 : 0,
          cover_image: img,
          file: file,
        },
      },
      (res) => {
        toast.success(res.message, {
          autoClose: 3000,
        });
        navigate(-1);
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  useEffect(() => {
    http(
      {
        method: "GET",
        url: `class`,
        isSecure: true,
      },
      (res) => {
        setClassList(res.data);
      }
    );
  }, []);

  useEffect(() => {
    values.class &&
      http(
        {
          method: "POST",
          url: `class/subjects?page=1&size=10`,
          isSecure: true,
          body: {
            class_id: values.class.map((item) => item.id),
          },
        },
        (res) => {
          setSubjectList(res.data?.data);
        }
      );
  }, [classList, values.class]);

  return (
    <div className="update_user">
      <div className="col-md-12">
        <div className="sup_history">
          <div className="scale_data_header">
            <h2 className="class_head">
              <Link to="/admin/video" className="prev_btn">
                <img src={PrefImg} alt="Back button" />
              </Link>
              Add Video
            </h2>
          </div>
          <div className="data_add_form admin-form">
            <form onSubmit={handleUploadImg}>
              <div className="row">
                <div className="col-lg-6 col-xxl-3">
                  {" "}
                  <div className="form-group">
                    <label htmlFor="sName" className="form-label">
                      Class
                    </label>
                    <div className="dropdown-box">
                      <TypeAheadContainer
                        ref={classesSelect}
                        id="videoClas"
                        labelKey={(item) => item.name}
                        name="class"
                        placeholder="Select Classes"
                        options={classList}
                        selected={values.class || []}
                        onChange={(value) => {
                          setInitialValues((prev) => ({
                            ...prev,
                            class: value,
                          }));
                        }}
                        error={errors.class}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xxl-3">
                  <div className="form-group">
                    <label htmlFor="sName" className="form-label">
                      Subject
                    </label>
                    <div className="dropdown-box">
                      <TypeAheadContainer
                        id="subject"
                        labelKey={(item) =>
                          `${item?.name} (${item.class?.name}) (${item.school?.name})`
                        }
                        name="subject"
                        placeholder="Select Subject"
                        options={subjectList || []}
                        selected={values.subject || []}
                        onChange={(value) => {
                          setInitialValues((prev) => ({
                            ...prev,
                            subject: value,
                          }));
                        }}
                        error={errors.subject}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="mb-4 col-lg-6 col-xxl-3">
                  <div className="form-group">
                    <label htmlFor="sName" className="form-label">
                      Video Name
                    </label>
                    <input
                      type="text"
                      className="form-control text-input"
                      id="sName"
                      placeholder="Enter video name"
                      autoComplete="off"
                      name="bookName"
                      {...bindField("bookName")}
                    />
                  </div>
                </div>
                <div className="mb-4 col-lg-6 col-xl-4 col-xxl-3">
                  <label htmlFor="sName" className="form-label">
                    Price
                  </label>
                  <div className="price-data">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input "
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        value={checked}
                        onChange={handleCheck}
                      />
                      <label htmlFor="fee" className="form-label text-nowrap">
                        Free / Paid
                      </label>
                    </div>
                    <div className="input-group pricing_input">
                      <span className="input-group-text input_ico">$</span>
                      <input
                        type="text"
                        className="form-control mb-0  text-input"
                        aria-label="Amount (to the nearest dollar)"
                        placeholder="0"
                        name="Price"
                        disabled={checked === false}
                        {...bindField("Price")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="mb-4 col-lg-6 col-xxl-3">
                  <label htmlFor="sName" className="form-label">
                    Upload Cover Image
                  </label>
                  {/* Upload video section start */}
                  <div className="item-content">
                    <div className="image-upload">
                      <label htmlFor="file_upload" className="form-label">
                        <div className="dplay-tbl-cell">
                          {!creatIamge.url ? (
                            <img
                              src={UploadImg}
                              className="uploaded-image"
                              alt="Uplodyour"
                            />
                          ) : (
                            <img
                              src={creatIamge.url}
                              className="uploaded-image"
                              alt="Uplodyour"
                            />
                          )}
                        </div>

                        <input
                          type="file"
                          accept=".png,.jpg"
                          id="file_upload"
                          className="image-input  text-input"
                          onChange={(e) => imageChange(e)}
                          value=""
                        />
                      </label>
                      {creatIamge && (
                        <img
                          className="video_cross"
                          title="Delete this video"
                          src={CrossIcon}
                          onClick={() => setCreateImage("")}
                          alt="CrossIcon"
                        />
                      )}
                    </div>
                  </div>
                  {/* Upload video section end */}
                </div>
                <div className="mb-4 col-lg-6 col-xxl-3">
                  <label htmlFor="sName" className="form-label">
                    Upload file
                  </label>
                  <div className="item-content file_up">
                    <div className="pdf-upload">
                      <input
                        type="file"
                        className="btn sub-btn  upload_btn"
                        accept="video/*"
                        onChange={(e) => videoChange(e)}
                        onClick={(e) => (e.target.value = "")}
                      />
                    </div>
                    {creatVideo.url ? (
                      <div className="file_icon">
                        <img
                          className="file"
                          src={videoIcon}
                          alt="Upload book as pdf"
                        />
                        <img
                          className="cut_pdf"
                          src={CrossIcon}
                          alt="Remove selected file"
                          onClick={() => setCreateVideo({})}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="addon_btn row">
                <div className="col-xl-3 col-lg-6 ">
                  <button type="submit" className="btn sub-btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/*Report card Modal Section start */}

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="video_title_name">
            {creatVideo.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video
            width="100%"
            height="100%"
            controls
            type="file"
            // onClick={() => setShowModal(true)}
          >
            <source src={creatVideo.url} type="video/mp4" />
          </video>
        </Modal.Body>
      </Modal>

      {/* Report card Modal section end */}
    </div>
  );
}
export default VideoForm;
