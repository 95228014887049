import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";

import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

import { http } from "../../../../services/http";
import ToggleBtn from "../../../Utils/forms/ToggleBtn";
import { AppContext } from "../../../context/AppContext";

import USD from "../../../../assets/images/usd.svg";
import LanguageIcon from "../../../../assets/images/language.svg";
import CurrencyIcon from "../../../../assets/images/currency.svg";
import CrossIcon from "../../../../assets/images/black-cross.png";
import NotificationIcon from "../../../../assets/images/blue-noti.svg";

const updateCookie = (value) =>
  new Promise(() => {
    setTimeout(() => {
      document.cookie = "googtrans=/en=; Path=/; expires=" + new Date() + ";";

      +value === 1
        ? (document.cookie = "googtrans=/en")
        : (document.cookie = "googtrans=/en/fr");
      window.location.reload(true);
    }, 1000);
  });

function Setting() {
  const [language, setLanguage] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [currency, setCurrency] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [langSelector, setIsClick] = useState(false);
  const [currencySelector, selectIsClick] = useState(false);
  const { pathname } = useLocation();
  const [notificationChecked, setNotificationChecked] = useState(false);

  const { setSpinner, setuserLang } = useContext(AppContext);

  const updateSettingsHandle = (type, value) => {
    setSpinner(true);
    http(
      {
        method: "PATCH",
        url: `setting`,
        isSecure: true,
        body: {
          [type]: value,
        },
      },
      (res) => {
        if (type === "language_id") {
          updateCookie(value);
        }
        toast.success(res.message, {
          autoClose: 5000,
        });
      },
      (res) => {
        toast.error(res.message, {
          autoClose: 5000,
        });
      }
    );
    setSpinner(false);
  };

  const handleChangeLang = (e) => {
    setSelectedLanguage(+e.target.value);
    updateSettingsHandle("language_id", +e.target.value);
    setuserLang(+e.target.value === 1 ? "en" : "fr");
    // console.log("lanugae Id", +e.target.value);
  };

  const handleChangeCurrency = (e) => {
    setSelectedCurrency(+e.target.value);
    updateSettingsHandle("currency_id", +e.target.value);
  };

  const updateNotification = (isChecked) => {
    setNotificationChecked(isChecked);
    updateSettingsHandle("notification", isChecked ? 1 : 0);
  };

  useEffect(() => {
    setSpinner(true);
    http(
      {
        method: "GET",
        url: `setting`,
        isSecure: true,
      },
      (res) => {
        setSelectedLanguage(res.data.language_id);
        // updateCookie(res.data.language_id);
        setuserLang(res.data.language_id === 1 ? "en" : "fr");
        setSelectedCurrency(res.data.currency_id);
        setNotificationChecked(res.data.notification === 1);
      }
    );
    setSpinner(false);
  }, [setuserLang, setSpinner]);

  useEffect(() => {
    http(
      {
        method: "GET",
        url: `language`,
        isSecure: true,
      },
      (res) => {
        setLanguage(res);
      }
    );
  }, []);

  useEffect(() => {
    http(
      {
        method: "GET",
        url: `all-currency`,
        isSecure: true,
      },
      (res) => {
        setCurrency(res);
      }
    );
  }, []);

  return (
    <div className="school_history setting_dash">
      <div className="sup_history">
        <div className="scale_data_header border-bottom">
          <h2 className="class_head">Settings</h2>
        </div>
        <div className="setting-body row">
          {pathname.includes("/admin") ? (
            ""
          ) : (
            <div className="col-xxl-2 col-lg-4 col-sm-4 pointer">
              <div className="user_card">
                <img src={NotificationIcon} alt="Notification Icon" />
                <h2 className="class_head">Notification</h2>
                <ToggleBtn
                  onchange={(e) => updateNotification(e.target.checked)}
                  checked={notificationChecked}
                />
              </div>
            </div>
          )}

          <div
            className="col-xxl-2 col-lg-4  col-sm-4  pointer"
            onClick={() => setIsClick(true)}
          >
            <div className="user_card">
              <img src={LanguageIcon} alt="Language Icon" />
              <h2 className="class_head">Language</h2>
            </div>
          </div>
          <div
            className="col-xxl-2 col-lg-4  col-sm-4  pointer"
            onClick={() => selectIsClick(true)}
          >
            <div className="user_card">
              <img src={CurrencyIcon} alt="Currency Icon" />
              <h2 className="class_head">Currency</h2>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={langSelector}
        onHide={() => setIsClick(false)}
        className="setting-modal language_modal"
      >
        <Modal.Header>
          <Modal.Title>Choose language</Modal.Title>
          <img
            src={CrossIcon}
            className="cross-icon float-end"
            onClick={() => setIsClick(false)}
            alt="Modal cross icon"
          />
        </Modal.Header>
        <Modal.Body>
          <div className="data_add_form admin-form">
            {language.data?.length
              ? language.data?.map((item) => (
                  <div className="form-check" key={item.id}>
                    <label
                      className="form-label confirmlabel"
                      htmlFor={item.name + "-" + item.abbreviation}
                    >
                      {item.name + "-" + item.abbreviation}
                    </label>
                    <input
                      id={item.name + "-" + item.abbreviation}
                      className="form-check-input confirm_input"
                      type="radio"
                      name="language"
                      value={item.id}
                      checked={selectedLanguage === item.id}
                      onChange={handleChangeLang}
                    />
                  </div>
                ))
              : ""}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={currencySelector}
        onHide={() => selectIsClick(false)}
        className="setting-modal language_modal"
      >
        <Modal.Header>
          <Modal.Title>Choose Currency</Modal.Title>
          <img
            src={CrossIcon}
            className="cross-icon float-end"
            onClick={() => selectIsClick(false)}
            alt="Modal cross icon"
          />
        </Modal.Header>
        <Modal.Body>
          <div className="data_add_form admin-form">
            {currency?.data?.length > 0
              ? currency?.data?.map((item) => (
                  <div className="form-check" key={item.id}>
                    <img src={USD} alt="USD icon " className="currency-icons" />
                    <label
                      className="form-label confirmlabel"
                      htmlFor={item.name + "-" + item.abbreviation}
                    >
                      {item.name + "-" + item.abbreviation}
                    </label>
                    <input
                      id={item.name + "-" + item.abbreviation}
                      className="form-check-input confirm_input"
                      type="radio"
                      name="currency"
                      value={item.id}
                      onChange={handleChangeCurrency}
                      checked={selectedCurrency === item.id}
                    />
                  </div>
                ))
              : ""}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default Setting;
