import { ADMIN, SCHOOL } from "./userTypes";

class Auth {
    authenticated = false;

    login(user) {
        const isRememberMe = false;
        const storage = isRememberMe ? localStorage : sessionStorage;

        if (user.userInfo?.role_id) {
            user.role = user.userInfo?.role_id === 1 ? ADMIN : SCHOOL;
        }

        storage.setItem(`akili_user`, JSON.stringify(user));

        if (user.token) {
            this.authenticated = true;
        } else {
            this.authenticated = false;
        }
    }

    user() {
        return JSON.parse(localStorage.getItem(`akili_user`) || sessionStorage.getItem(`akili_user`)) || {};
    }

    token() {
        const user = JSON.parse(
            localStorage.getItem(`akili_user`) ||
            sessionStorage.getItem(`akili_user`)
        );
        return user ? user.token : '';
    }

    logout() {
        localStorage.removeItem(`akili_user`);
        sessionStorage.removeItem(`akili_user`);
        this.authenticated = false;
    }

    isUserAuthenticated() {
        if (
            localStorage.getItem(`akili_user`) ||
            sessionStorage.getItem(`akili_user`)
        ) {
            return true;
        } else {
            return false;
        }
    }
}

export default new Auth();