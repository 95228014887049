import React from 'react'

function TextArea({ id, label, value, onChange, placeholder , star}) {
    return (
        <div className='form-group'>
            <label htmlFor={id} className="form-label">
                {label} {star && <span className="required">*</span>}
            </label>
            <textarea
                className="form-control qa_input input-textarea "
                rows="3"
                cols="60"
                id={id}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            ></textarea>
        </div>
    )
}

export default TextArea