import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { http } from "../../../../services/http";
import moment from "moment/moment";
import PagePagination from "../../../Utils/pagination/PagePagination";
import { AppContext } from "../../../context/AppContext";
import PrefImg from "../../../../assets/images/prev_ico.png";
import UserNSchoolDetails from "../../../Utils/widgets/UserNSchoolDetails";

function SubscriptionStudentDetails() {
  const [page, setPage] = useState();
  const [parchasedBookList, setParchasedBookList] = useState();
  const location = useLocation();
  const userId = location.state.id;
  // console.log(location);
  const { setSpinner, size } = useContext(AppContext);
  const handleSalesHistoryList = () => {
    setSpinner(true);
    const params = {
      page: page ? page : 1,
      size: size,
      user_id: userId,
    };
    http(
      {
        method: "GET",
        url: `book/sales-history?${new URLSearchParams(params)}`,
        isSecure: true,
      },
      (res) => {
        setSpinner(false);
        setParchasedBookList(res.data);
      }
    );
  };
  // useCallback(
  //   ({ page }) => {
  //     setSpinner(true);
  //     http(
  //       {
  //         method: "GET",
  //         url: `book/sales-history?page=${
  //           page ? page : 1
  //         }&size=10&user_id=${userId}`,
  //         isSecure: true,
  //       },
  //       (res) => {
  //         setSpinner(false);
  //         setParchasedBookList(res.data);
  //       }
  //     );
  //   },
  //   [setSpinner, userId]
  // );
  useEffect(() => {
    handleSalesHistoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  // console.log("get purchased book data", parchasedBookList);
  return (
    <div className="user_details stud_graph ">
      <div className="sup_history">
        <div className="scale_data_header">
          <h2 className="class_head">
            <Link to="/admin/students" className="prev_btn">
              <span className="backBtn">
                <img className="backPg" src={PrefImg} alt="Back button" />
              </span>
            </Link>
            Students Details
          </h2>
        </div>

        <UserNSchoolDetails
          img={process.env.REACT_APP_MEDIA_URL + location?.state?.student?.file}
          name={location?.state?.name}
          classname={location?.state?.student?.class?.name}
          email={location?.state?.email}
          phoneNo={location?.state?.phone_number}
          rollNo={location?.state?.student?.roll_number}
        />

        <div className="purcg_table">
          <div className="scale_data_header">
            <h2 className="class_head">Purchased Books</h2>
          </div>

          <div className="kina_tables table-responsive">
            <table className="dash_tables">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Book</th>
                  <th>Subject</th>
                  <th>Date and Time of Purchase</th>
                  <th className="pricingTh">Price</th>
                </tr>
              </thead>
              <tbody>
                {parchasedBookList?.data?.length ? (
                  parchasedBookList?.data?.map((item, index) => {
                    const subjectName = item.book.subject.map(
                      (item) => item.name
                    );
                    const DateOfPurchase = moment
                      .utc(item.created_at)
                      .format("DD MMM YYYY");
                    const TimeOfPurchase = moment
                      .utc(item.created_at)
                      .format("hh:mm A");
                    return (
                      <tr key={item.id}>
                        <td>{parchasedBookList.from + index}</td>
                        <td>{item.book.name}</td>
                        <td>{subjectName[0]}</td>
                        <td>{`${DateOfPurchase}, ${TimeOfPurchase}`}</td>
                        <td>${item.book.price}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5">
                      <h2 className="text-center">Data Not Found</h2>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {parchasedBookList?.total > 0 &&
            parchasedBookList?.total > parchasedBookList?.per_page && (
              <PagePagination
                className="pagination-bar"
                currentPage={parchasedBookList?.current_page}
                totalCount={
                  parchasedBookList?.last_page !== undefined
                    ? parchasedBookList?.total
                    : parchasedBookList?.length
                }
                pageSize={size}
                onPageChange={(page) => setPage(page)}
              />
            )}
        </div>

        <div className="mt-4">
          <div className="scale_data_header">
            <h2 className="class_head">Video History</h2>
          </div>
          {/* Digital Electronic section start */}
          <div className="main_slider_box">
            <h3>No Data Available</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SubscriptionStudentDetails;
