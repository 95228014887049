import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useForm } from "@kaydhiman/react-hook-useform";
import { toast } from "react-toastify";

import InputBox from "../../../../Utils/forms/InputBox";
import CustomButton from "../../../../Utils/forms/CustomButton";
import { AppContext } from "../../../../context/AppContext";
import { http } from "../../../../../services/http";
import TypeAheadContainer from "../../../../Utils/widgets/TypeAheadContainer";

import AddImg from "../../../../../assets/images/add-img.png";
import PrefImg from "../../../../../assets/images/prev_ico.png";
import CrossIcon from "../../../../../assets/images/red_cross.svg";
import TextArea from "../../../../Utils/forms/TextArea";

function AddPost() {
  const [creatIamge, setCreateImage] = useState("");
  const [tags, setTags] = useState([]);
  const navigate = useNavigate();
  const tagSelect = useRef(null);
  const { setSpinner } = useContext(AppContext);

  const { values, errors, bindField, setInitialValues, checkErrors } = useForm({
    validations: {
      title: {
        required: true,
      },
    },
  });

  const imageChange = (e) => {
    setCreateImage({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  // IMAGE UPLOAD API
  const uploadImgApi = async (e) => {
    e.preventDefault();
    if (creatIamge.file) {
      const formData = new FormData();
      formData.append("type", "photo");
      formData.append("file", creatIamge.file);

      await http(
        {
          method: "POST",
          url: `upload-image`,
          isSecure: true,
          contentType: "multipart/form-data",
          body: formData,
        },
        (res) => {
          setSpinner(false);
          addPost(res.content.imageName);
        },
        (res) => {
          setSpinner(false);

          toast.error(res.message, {
            autoClose: 3000,
          });
        }
      );
    } else {
      addPost();
    }
  };

  //function to add or update class
  const addPost = (img) => {
    if (!checkErrors()) {
      return;
    }
    
    setSpinner(true);
    http(
      {
        method: "POST",
        url: "post",
        isSecure: true,
        body: {
          title: values.title,
          ...(values.tags && { tags: values.tags.map((tag) => tag.id)}),
          ...(values.link && { link: values.link }),
          ...(img && { image: img }),
        },
      },
      (res) => {
        setSpinner(false);
        navigate("/admin/post");
        toast.success(res.message, {
          autoClose: 3000,
        });
      },
      (res) => {
      toast.error(res.message, {
          autoClose: 3000,
        });
      }
    );
  };

  // user tag lisiting
  useEffect(() => {
    // setSpinner(true);
    http(
      {
        method: "GET",
        url: "user?page=1&size=10&filter=tags",
        isSecure: true,
      },
      (res) => {
        // setSpinner(false);
        setTags(res.data);
      }
    );
  }, [setSpinner, setTags]);

  return (
    <div className="update_user">
      <div className="col-md-12">
        <div className="sup_history">
          <div className="scale_data_header">
            <h2 className="class_head">
              <Link to="/admin/post" className="prev_btn">
                <span className="backBtn">
                  <img className="backPg" src={PrefImg} alt="Back button" />
                </span>
              </Link>
              Add Post
            </h2>
          </div>
          <div className="data_add_form admin-form">
            <form onSubmit={uploadImgApi}>
              <div className="row">
                <div className="mb-4 col-lg-6 col-xl-3">
                  <TextArea
                    label="Add Title"
                    type="text"
                    id="title"
                    name="title"
                    placeholder="Enter title"
                    classname="form-control"
                    required="true"
                    {...bindField("title")}
                    error={errors.title}
                  />
                </div>
              </div>

              <div className="row">
                <div className="mb-4 col-lg-6 col-xl-3">
                  <InputBox
                    label="Link (Optional)"
                    type="text"
                    id="link"
                    name="link"
                    placeholder="Enter title"
                    classname="form-control"
                    {...bindField("link")}
                    error={errors.link}
                  />
                </div>
              </div>

              <div className="row">
                <div className="mb-4 col-lg-6 col-xl-3">
                  <label htmlFor="class" className="form-label">
                    Tags
                  </label>
                  <TypeAheadContainer
                    ref={tagSelect}
                    id="tags"
                    labelKey={(item) => item.name}
                    name="tags"
                    placeholder="Select Tags"
                    options={tags?.data}
                    selected={values.tags || []}
                    onChange={(value) => {
                      setInitialValues((prev) => ({ ...prev, tags: value }));
                    }}
                    error={errors.class}
                  />
                </div>
              </div>

              <div className="row">
                <div className="mb-4 col-lg-6 col-xl-3">
                  <label htmlFor="sName" className="form-label">
                    Add Image<span className="text-grey">(optional)</span>
                  </label>
                  <div className="item-content">
                    <div className="image-upload">
                      <label htmlFor="file_upload" className="form-label">
                        <div className="dplay-tbl-cell">
                          {!creatIamge.url ? (
                            <img
                              src={AddImg}
                              className="uploaded-image"
                              alt="Uplodyour"
                            />
                          ) : (
                            <img
                              src={creatIamge.url}
                              className="uploaded-image"
                              alt="Uplodyour"
                            />
                          )}
                        </div>
                        <input
                          type="file"
                          accept=".png,.jpg"
                          id="file_upload"
                          name="addimage"
                          className="image-input  text-input"
                          data-traget-resolution="image_resolution"
                          onChange={(e) => imageChange(e)}
                          value=""
                        />
                      </label>
                      {creatIamge.url && (
                        <img
                          className="video_cross"
                          title="Delete this image"
                          src={CrossIcon}
                          onClick={() => setCreateImage("")}
                          alt="CrossIcon"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="addon_btn row">
                <div className="col-xl-3 col-lg-6 ">
                  <CustomButton
                    text="Submit"
                    type="submit"
                    classname="sub-btn"
                    // disabled={!isValid()}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddPost;
