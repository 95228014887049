import React from "react";

import { Outlet } from "react-router-dom";

import Logo from "../../assets/images/logo.svg";

function OnbordingLayout() {
  return (
    <div className="ena_onboarding">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 onboardLeft">
            <div className="ena_splash">
              <img src={Logo} className="ena_splash_logo" alt="Logo" />
              <div className="sonar-wrapper">
                <div className="sonar-emitter">
                  <div className="sonar-wave"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 onboardRight">
            <div className="onboarding_form">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OnbordingLayout;
