import React, { useContext, useEffect, useState } from "react";

import { useForm } from "@kaydhiman/react-hook-useform";
import { useLocation, useNavigate } from "react-router-dom";

import OtpInput from "react-otp-input";
import { AppContext } from "../context/AppContext";
import { toast } from "react-toastify";
import { ADMIN, SCHOOL } from "../../Auth/userTypes";
import CustomButton from "../Utils/forms/CustomButton";

function Varification() {
  const { setSpinner, setShowLogin } = useContext(AppContext);
  const [role, setRole] = useState("");

  const { pathname, state } = useLocation();
  const navigate = useNavigate();



  const { values, errors, setInitialValues } = useForm({
    validations: {
      otp: {
        required: true,
        minLength: {
          values: 6,
          message: "Enter 6 digit OTP",
        },
      },
    },
  });

  const formSubmitOtp = async (e) => {
    e.preventDefault();
    setSpinner(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/auth/passwordForgot`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({email: state, ...(role && { role }) }),
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        setShowLogin({ role: data.userInfo?.role_id === 1 ? ADMIN : SCHOOL });
        toast.success(data.message, {
          autoClose: 3000,
        });
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();

        if (data.errors) {
          for (const [key] of Object.entries(data.errors)) {
            toast.error(data.errors[key][0], {
              autoClose: 3000,
            });
          }
        }
      }
      setSpinner(false);
    } catch (err) {
      console.error(err);
    }
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/auth/verifyOtp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ACCEPT: "application/json",
          },
          body: JSON.stringify({
            ...values,
            email: state,
            ...(role && { role }),
          }),
        }
      );

      if (response.status === 200) {
        const data = await response.json();

        setShowLogin({ role: data.userInfo?.role_id === 1 ? ADMIN : SCHOOL });

        if (state.length >= 0 && values.otp >= 0) {
          navigate( pathname.includes("/admin") ? "/admin/reset-password" : "/reset-password", {
            state: { email: state, otp: values.otp },
          });
        } else {
          navigate("/verify-email");
        }

        toast.success(data.message, {
          autoClose: 3000,
        });
      }

      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();

        toast.error(data.message, {
          autoClose: 3000,
        });
      }
      
      setSpinner(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setRole(pathname.includes("/admin") ? ADMIN : SCHOOL);
  }, [pathname]);

  return (
    <div className="otp_form varifyPg">
      <div className="onboarding_heading">
        <h2>Verification Code</h2>
      </div>

      <div className="data_add_form admin-form">
        <form onSubmit={formSubmit}>
          <div className="form-inputs otpbox">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Enter Code
            </label>
            <OtpInput
              value={values.otp}
              onChange={(e) =>
                setInitialValues((pre) => {
                  return { ...pre, otp: e };
                })
              }
              numInputs={6}
              separator={<span> </span>}
              otpType="number"
              disabled={false}
            />
            {errors.otp && <span className="error">{errors.otp}</span>}
          </div>
          <div className="form-footer">
            <CustomButton
              type="submit"
              text="Submit"
              classname="sub-btn"
              // disabled={!isValid()}
            />
            <div className="mb-3 form-signUp text-center">
              <p>
                <button className="borderNone resendOtp" onClick={formSubmitOtp}> Resend OTP Code</button>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default Varification;
